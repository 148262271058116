import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Card } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersCentroControl } from 'services/headers'
import { urls } from 'utils/constant'
import { jwtDecode } from 'utils/JwtUtils'
import { validatorInput } from 'utils/ValidatorForm'

export const NewSubgroup = ({ group, modal, setModal, toggle }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [nick, setNick] = useState('')
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)

    const [validSubGroupName, setValidSubGroupName] = useState(false)
    const verifyToken = jwtDecode(token)

    const addNewSubGroup = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + `subgroup`, {
            method: 'POST',
            redirect: 'follow',
            headers: headersCentroControl(token),
            body: JSON.stringify({ nick: nick, id_group: Number(group?.id_group), id_user: Number(verifyToken) })
        })
            .then((res) => {
                setLoading(false)
                status = res.status
                return res.json()
            })
            .then((data) => {
                toggle(data)
                dispatch(createToast({ status: status, message: data.message, reload: true }))
                setModal(false)
            })
            .catch((err) => console.error('ERROR. API Centro de Control', err))
    }

    const handleChange = (e) => {
        setNick(e)
        setValidSubGroupName(validatorInput(e))
    }

    //Si no ha escrito nada o el nombre ya existe, se desactiva el boton
    const validateName = () => nick && nick.length && !visible && !validSubGroupName

    //Valida si el nombre del proyecto ya existe en la base de datos y avisa al usuario
    const validateSubGroupName = () => {
        if (group) {
            const normalizedNick = nick?.toLowerCase().trim()
            const foundProject = group?.subgroups?.find((prj) => prj.nick.toLowerCase().trim() === normalizedNick)
            return foundProject
        }
        return false
    }

    useEffect(() => {
        setNick('')
        setValidSubGroupName(false)
    }, [modal, setModal])

    return (
        <>
            {' '}
            <Modal
                open={modal}
                setOpen={setModal}
                c
                title='Nuevo subgrupo'
                buttonAction='Crear'
                functSave={addNewSubGroup}
                loading={loading}
                onClick={(e) => e.stopPropagation()}
                disabled={!validateName()}
            >
                <Grid container className='pr-5 pl-5'>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='pb-3'>
                        <div className='pb-1'>
                            <small className='color-black'>Nombre del subgrupo:</small>
                        </div>
                        <InputStyled
                            fullWidth
                            value={nick}
                            maxLength={45}
                            onChange={handleChange}
                            onBlur={() => {
                                setVisible(validateSubGroupName())
                            }}
                            error={validSubGroupName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {visible && (
                            <Card color='danger' style={{ padding: '5px', textAlign: 'center', backgroundColor: '#f75676', color: '#fff' }}>
                                Este nombre ya está asignado
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
