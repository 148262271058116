import React from 'react'
import { faBroom, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@mui/material'
import './styleTerminalMQTT.scss'

export const TerminalMQTT = ({ answer, setAnswer, numserieMaster }) => {
    return (
        <Grid item xs={12} className='terminal-container-grid'>
            <div className='Terminal'>
                <div className='Terminal__Toolbar'>
                    <p className='Toolbar__user'>Comunicación CCMaster: {numserieMaster} </p>
                    <div className='Toolbar__buttons'>
                        <button className='button_clean'>
                            <FontAwesomeIcon
                                name='broom'
                                onClick={() => setAnswer(``)}
                                style={{ fontSize: '10px ' }}
                                icon={faBroom}
                                title='Limpiar'
                            />
                        </button>
                        <button className='button_copy'>
                            <FontAwesomeIcon
                                name='copy'
                                onClick={() => navigator.clipboard.writeText(numserieMaster)}
                                style={{ fontSize: '10px ' }}
                                icon={faCopy}
                                title='Copiar'
                            />
                        </button>
                    </div>
                </div>
                <div className='Terminal__body'>
                    <div className='Terminal__Prompt'>
                        <span className='Prompt__user'> {numserieMaster}@ccMaster:</span>
                        <span className='Prompt__location'>~</span>
                        <span className='Prompt__dollar'>$</span>
                        <span className='Prompt__cursor'></span>
                    </div>
                    {answer &&
                        answer?.map((resp) => {
                            return (
                                <span className={`${resp?.status === 404 ? 'error-answer-termina' : null} Prompt__answer`}>
                                    {resp?.message}
                                </span>
                            )
                        })}
                </div>
            </div>
        </Grid>
    )
}
