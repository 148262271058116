import { useState } from 'react'
import ChartInline from 'components/Charts/ChartInLIne'
import { IconScadaEnerSim } from 'utils/icon-centrocontrol'
import { showFullDate } from 'utils/Utils'
import ScadaPieChart from './ScadaPieChart'
import ScadaBarChart from './ScadaBarChart'

export const ScadaEnerSimGraphs = ({ measuringData }) => {
    const [viewMode, setViewMode] = useState('semanal')
    const [activeButton, setActiveButton] = useState('semanal')

    const lastMonth = measuringData?.measuringMonths[measuringData?.measuringMonths?.length - 1]
    const totalUsageMB = lastMonth?.total_usage_mb
    const totalUploadMB = lastMonth?.total_upload_usage_mb
    const totalDownloadMB = lastMonth?.total_download_usage_mb
    const lastUpdate = lastMonth?.updated_up

    const handleButtonClick = (mode) => {
        setViewMode(mode)
        setActiveButton(mode)
    }

    return (
        <div>
            <header className='flex gap-4'>
                <IconScadaEnerSim height='25' width='25' />
                <div className='graphs-container-title'>
                    <span className='text-lg-black'>Gráficas consumo</span>
                </div>
            </header>
            <div className='upper-graphs-container mt-4'>
                <div className='flex-1'>
                    <span className='text-lg-black'>Total consumo</span>
                    <ChartInline used={measuringData?.percentage ?? '-'} />
                    <div className='resume-container pt-2'>
                        <div className='card-resume-table'>
                            <span>
                                <p>
                                    <b>{totalUploadMB ? totalUploadMB : '--'}</b>
                                </p>
                            </span>
                            <h4>Subida</h4>
                        </div>
                        <div className='card-resume-table'>
                            <span>
                                <p>
                                    <b>{totalDownloadMB ? totalDownloadMB : '--'}</b>
                                </p>
                            </span>
                            <h4>Bajada</h4>
                        </div>
                        <div className='card-resume-table'>
                            <span>
                                <p>
                                    <b>{totalUsageMB ? totalUsageMB : '--'}</b>
                                </p>
                            </span>
                            <h4>Total</h4>
                        </div>
                    </div>
                    <p className='last-update-text pt-2'>Última actualización de datos: {lastUpdate ? showFullDate(lastUpdate) : '-'}</p>
                </div>
                <div className='card-resume-general-graph'>
                    <span className='general-graph-icon'>
                        <span className='pie-chart-total-upload'>
                            <p>{totalUploadMB ? totalUploadMB : '--'}</p>
                            <div className='chart-square-upload'></div>
                        </span>
                        <span>
                            <span className='pie-chart-total-usage'>
                                <p>{totalUsageMB ? totalUsageMB : '--'}</p>
                                <div className='chart-square-usage'></div>
                            </span>
                        </span>
                    </span>
                    <div>
                        <ScadaPieChart
                            className='pie-chart '
                            totalUsageMB={totalUsageMB}
                            totalUploadMB={totalUploadMB}
                            totalDownloadMB={totalDownloadMB}
                        />
                    </div>
                    <div>
                        <span className='general-graph-icon graph-icon-consumption'>
                            <span className='pie-chart-total-down'>
                                <p>{totalDownloadMB ? totalDownloadMB : '--'}</p>
                                <div className='chart-square-down'></div>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className='flex-space-between py-4'>
                <div className='flex'>
                    <button
                        onClick={() => handleButtonClick('semanal')}
                        className={activeButton === 'semanal' ? 'button-general-active' : 'button-general'}
                    >
                        Semanal
                    </button>
                    <button
                        onClick={() => handleButtonClick('mensual')}
                        className={activeButton === 'mensual' ? 'button-general-active' : 'button-general'}
                    >
                        Mensual
                    </button>
                </div>
            </div>
            <div className='scada-bar-chart-container'>
                <ScadaBarChart className='scada-bar-chart' measuringData={measuringData} viewMode={viewMode} />
            </div>
        </div>
    )
}
