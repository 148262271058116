import React, { useEffect, useState } from 'react'
import { DragDropContext } from '@hello-pangea/dnd'
import { Grid } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { apiScada } from 'services/api'
import { getIconComponent, deviceStatus, getUniqueID, initialColumns } from 'utils/Utils'
import DropContainer from 'views/LayOut/DropContainer'

export const DragDropPrincipal = ({ numserie, devices, setActiveTab, activeTab, setActiveTabCuadro, loadingCard, data, currentDevice }) => {
    const [columns, setColumns] = useState(deviceStatus)
    const relayKeys = Object?.keys(columns)?.filter((key) => key?.startsWith('Relay'))

    const handleClick = () => {
        setActiveTab(2)
        setActiveTabCuadro(true)
    }

    const getLayout = async (numserie) => {
        try {
            const res = await apiScada.get(`distributionPanel/${numserie}`)
            if (res?.status === 200) {
                let data = await res?.data
                let configuration
                if (data === 'Panel de distribución sin configuración') {
                    configuration = []
                } else {
                    try {
                        if (data?.confPanel) {
                            configuration = JSON.parse(data?.confPanel)
                        } else {
                            configuration = []
                        }
                    } catch (error) {
                        console.error('Error al parsear el JSON:', error)
                        configuration = []
                    }
                }
                let initial = {
                    Devices: {
                        name: 'Devices',
                        items: devices
                    },
                    Relay1: {
                        name: 'Relay1',
                        columns: initialColumns('Relay1')
                    }
                }
                configuration?.forEach((relay, index) => {
                    const relayId = `Relay${index + 1}`
                    const columns = initialColumns(relayId)
                    relay?.forEach((nestedArray, positionIndex) => {
                        if (nestedArray === null) {
                            columns[positionIndex].items = []
                        } else {
                            columns[positionIndex].items = nestedArray?.map((iconType) => {
                                const { device_type, icon_type, name, numserie, content, isMoved } = getIconComponent(
                                    iconType.icon_type,
                                    iconType.name,
                                    iconType.numserie
                                )
                                return {
                                    id: getUniqueID(),
                                    device_type,
                                    icon_type,
                                    name,
                                    numserie,
                                    content,
                                    isMoved
                                }
                            })
                        }
                    })
                    initial[relayId] = {
                        name: relayId,
                        columns: columns
                    }
                })
                setColumns(initial)
            } else {
                console.error('Error en la solicitud')
            }
        } catch (error) {
            console.error('Error en la solicitud', error)
        }
    }

    useEffect(() => {
        if (!loadingCard && data?.length > 0) {
            getLayout(numserie)
        }
    }, [numserie, activeTab, currentDevice])

    return (
        <Grid item xs={12} className='mx-6'>
            <div className='flex-end mt-8 '>
                <ButtonStyled className='boton-delete-alarm mb-3' onClick={handleClick}>
                    <span className='p-1'>Configurar cuadro</span>
                </ButtonStyled>
            </div>
            <DragDropContext>
                <div className=' relay-main-container'>
                    {relayKeys?.map((relayKey) => (
                        <div key={relayKey} className='relay-container flex-col'>
                            {columns?.[relayKey]?.columns?.map((column) => (
                                <DropContainer
                                    key={column?.id}
                                    columnId={column?.id}
                                    column={column}
                                    direction='horizontal'
                                    disabled={true}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </DragDropContext>
        </Grid>
    )
}
