import React, { useState, useEffect, useContext } from 'react'
import Grid from '@mui/material/Grid'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { Loader } from 'components/Loader/Loader'
import { GlobalContext } from 'context/GlobalContext'
import { headersCentroControl } from 'services/headers'
import { urls } from 'utils/constant'
import { IconMaintenance } from 'utils/icon-centrocontrol'
import { FiltroConfiguracionInstalaciones } from './FilterInstall/FiltroConfiguracionInstalaciones'
import { TableConfigInstallation } from './TableInstall/TableConfigInstallation'
import { MaintenanceModal } from './ModalMaintance/MaintenanceModal'
import 'views/Alarms/styleAlarm.scss'

export const InstallationConfig = ({ activeTab, instalacioneSeleccionadas, masterError, dispositivo }) => {
    const token = sessionStorage?.token
    const { actualProject } = useContext(GlobalContext)
    const [open, setOpen] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [selectedMasters, setSelectedMasters] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataInstall, setDataInstall] = useState([])
    const [filterMaster, setFilterMaster] = useState()
    const [permiAdmin, setPermiAdmin] = useState(false)
    const bodyFetch = { numseries: masterError?.map((dev) => dev.numserie) }

    const fetchDataFirmware = () => {
        setLoading(true)
        fetch(urls.ccontrol + `getFirmware`, { method: 'POST', headers: headersCentroControl(token), body: JSON.stringify(bodyFetch) })
            .then((res) => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    return []
                }
            })
            .then((data) => {
                setSelectedMasters(JSON.parse(JSON.stringify(data)))
                setDataInstall(JSON.parse(JSON.stringify(data)))
            })
            .catch((err) => console.error('ERROR. API Centro Control', err))
            .finally(() => {
                setLoading(false)
            })
    }

    const arrayMaster = () => {
        if (selectedMasters) {
            selectedMasters?.forEach((dev) => {
                const matchedDevice = masterError?.find((item) => dev?.numserie === item?.numserie)
                if (matchedDevice) {
                    dev.error = matchedDevice.error
                    dev.permissions = matchedDevice.permissions
                }
            })
        }
    }

    const adminDevice = (device) => {
        let admin = false
        if (device?.length > 0 && device?.some((dev) => dev.permissions === 'admin' || dev.permissions === 'instal')) {
            admin = true
        } else {
            admin = false
        }
        setPermiAdmin(admin)
    }

    useEffect(() => {
        fetchDataFirmware()
    }, [])

    useEffect(() => {
        arrayMaster()
        adminDevice(selectedMasters)
    }, [selectedMasters])

    return (
        <div className='mt-2'>
            {activeTab === 3 && (
                <div className='table-responsive-md'>
                    {!loading && !permiAdmin && (
                        <h2 style={{ width: '100%', textAlign: 'center', marginTop: '2%' }}>No se encontraron dispositivos.</h2>
                    )}
                    {!loading && dataInstall?.length > 0 && permiAdmin && (
                        <>
                            <Grid
                                item
                                className='boton-create-alarm'
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                            >
                                <FiltroConfiguracionInstalaciones data={selectedMasters} setFilterMaster={setFilterMaster} />
                                {actualProject?.category !== 'distribution' && (
                                    <div className='header-title'>
                                        <ButtonStyled
                                            className='button-with-text'
                                            onClick={() => setOpen(!open)}
                                            disabled={dispositivo?.[0]?.id_tipo_dispositivo === 20}
                                        >
                                            <IconMaintenance className='icon-hover-with-text' />
                                            <p className='ml-2'>Mantenimiento</p>
                                        </ButtonStyled>
                                    </div>
                                )}
                                {open && <MaintenanceModal setKeySwitch={setKeySwitch} open={open} setOpen={setOpen} data={dataInstall} />}
                            </Grid>
                            {filterMaster && (
                                <TableConfigInstallation
                                    instalacioneSeleccionadas={instalacioneSeleccionadas}
                                    modalEditar={modalEditar}
                                    setModalEditar={setModalEditar}
                                    filterMaster={filterMaster}
                                    keySwitch={keySwitch}
                                    data={filterMaster}
                                />
                            )}
                        </>
                    )}
                    {loading && (
                        <div className='justify-content-center'>
                            <Loader />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
