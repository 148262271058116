import React, { useState, useEffect } from 'react'
import AuthCode from 'react-auth-code-input'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersCentroControl } from 'services/headers'
import { urls } from 'utils/constant'
import './StyleVerificationCodeMaster.scss'

export const VerificationCodeMaster = (props) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const idProject = location.pathname.split('/')[3]
    const [code, setCode] = useState(' ')
    const [loading, setLoading] = useState(false)
    const [actionBody, setActionBody] = useState({})
    const [disabled, setDisabled] = useState(true)

    const deleteDevice = () => {
        setLoading(true)
        let status
        let url
        if (props?.master?.activo === 1 || props.order === 'delete') {
            url = `device`
        } else {
            url = `update/device/${props.action}`
        }
        fetch(urls.ccontrol + url, {
            method: props.method,
            redirect: 'follow',
            headers: headersCentroControl(token),
            body: JSON.stringify(actionBody)
        })
            .then((res) => {
                setLoading(false)
                status = res.status
                return res.json()
            })
            .then((data) => {
                if (props?.handleResult) {
                    props?.handleResult(status)
                }
                dispatch(createToast({ status: status, message: data.message, reload: props?.reload !== undefined ? props?.reload : true }))
            })
            .catch((err) => {
                console.error('ERROR. API Centro de Control', err)
            })
    }

    const handleValidation = () => {
        if (code === undefined || code?.toString()?.length < 6) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }

    useEffect(() => {
        setActionBody({
            code: code,
            numserie: props?.numserie,
            action: props?.order,
            id_project: idProject
        })
        handleValidation()
    }, [code])

    return (
        <Modal
            buttonAction='Eliminar'
            open={props.secondModal}
            setOpen={props?.setSecondModal}
            disabled={disabled}
            onClick={(e) => e.stopPropagation()}
            functSave={deleteDevice}
            loading={loading}
            title='Eliminar dispositivo'
        >
            <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12} id='verificationCode' className='mb-4 flex justify-center items-center'>
                    <AuthCode characters={6} onChange={(e) => setCode(Number(e))} inputClassName='ios-verified' />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} id='verificationCode'>
                    <p className='text-center'>Comprueba tu bandeja de entrada y pega el código aquí</p>
                </Grid>
            </Grid>
        </Modal>
    )
}
