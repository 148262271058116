import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { Loader } from 'components/Loader/Loader'
import Map from 'components/Mapa/MapShow'
import { apiScada } from 'services/api'
import { IconNewObject } from 'utils/icon-centrocontrol'
import { ModalCreateSensor } from './Modals/ModalCreateSensor'
import { ModalDeleteSensor } from './Modals/ModalDeleteSensor'
import { ModalEditSensor } from './Modals/ModalEditSensor'
import { TableConfigSensorLinea } from './TableConfigSensorLinea'
import './SensorLine.scss'

export const SensorLineConfig = ({ dispositivo }) => {
    const [loading, setLoading] = useState(true)
    const [sensores, setSensores] = useState()
    const [places, setPlaces] = useState()
    const [openCreate, setOpenCreate] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [sensorSelected, setSensorSelected] = useState(false)

    const handleEditSensor = (sensor) => {
        setSensorSelected(sensor)
        setOpenEdit(true)
    }

    const handleDeleteSensor = (sensor) => {
        setSensorSelected(sensor)
        setOpenDelete(true)
    }

    const fetchGetAllSensors = async () => {
        setLoading(true)
        try {
            const result = await apiScada.get(`sensorByMabt/${dispositivo?.[0]?.numserie}?idCCMaster=${dispositivo?.[0]?.numserie}`)
            if (result?.status === 200) {
                let locations = JSON.parse(JSON.stringify(result?.data))
                locations?.map(
                    (location) =>
                        (location.infoPopUp = `Número de serie: ${location?.numserie}\nNombre: ${location?.nick ?? 'Sin nombre'}\nTipo: Sensor`)
                )
                locations?.push({
                    lat: dispositivo?.[0]?.latitud,
                    lng: dispositivo?.[0]?.longitud,
                    infoPopUp: `Número de serie: ${dispositivo?.[0]?.numserie}\nNombre: ${dispositivo?.[0]?.nick ?? 'Sin nombre'}\nTipo: Controlador`,
                    icon: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png'
                })
                setSensores(result?.data?.sort((a, b) => a?.nick - b?.nick))
                setPlaces(locations)
            }
        } catch (err) {
            setSensores('Error')
            console.error('ERROR. API Scada', err)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchGetAllSensors()
    }, [])

    return (
        <Grid container className='pt-5'>
            <Grid item xs={12} md={12} lg={12} xl={12} className='justify-space-between pr-1 pb-1'>
                <h1 className='titulo'>Configuración Sensores de Línea</h1>
                <ButtonStyled
                    className='button-with-text'
                    onClick={() => setOpenCreate(true)}
                    disabled={
                        dispositivo?.[0]?.childrenDevices?.length === 0 || sensores?.length >= dispositivo?.[0]?.childrenDevices?.length * 3
                    }
                >
                    <IconNewObject className='icon-hover-with-text' />
                    <p className='ml-2'>Nuevo Sensor de Línea</p>
                </ButtonStyled>
            </Grid>
            {loading && (
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Loader />
                </Grid>
            )}
            {!loading && sensores !== 'Error' && (
                <>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='pt-5 pb-3'>
                        <TableConfigSensorLinea
                            sensores={sensores}
                            handleEditSensor={handleEditSensor}
                            handleDeleteSensor={handleDeleteSensor}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='mt-5'>
                        <Map places={places} onClickMarker={() => {}} zoom={5} className='' styleMap={{}} />
                    </Grid>
                </>
            )}
            {!loading && sensores === 'Error' && (
                <Grid item xs={12} md={12} lg={12} xl={12} className='pt-5 pb-3'>
                    <h3 className='texto-error-carga'>Error al cargar los datos, contacte con el soporte técnico.</h3>
                </Grid>
            )}
            {openCreate && (
                <ModalCreateSensor
                    open={openCreate}
                    setOpen={setOpenCreate}
                    numserieMabt={dispositivo?.[0]?.numserie}
                    medidores={dispositivo?.[0]?.childrenDevices}
                    fetchGetAllSensors={fetchGetAllSensors}
                    dispositivo={dispositivo}
                />
            )}
            {openEdit && (
                <ModalEditSensor
                    open={openEdit}
                    setOpen={setOpenEdit}
                    sensor={sensorSelected}
                    medidores={dispositivo?.[0]?.childrenDevices}
                    fetchGetAllSensors={fetchGetAllSensors}
                    dispositivo={dispositivo}
                />
            )}
            {openDelete && (
                <ModalDeleteSensor
                    open={openDelete}
                    setOpen={setOpenDelete}
                    sensor={sensorSelected}
                    fetchGetAllSensors={fetchGetAllSensors}
                    dispositivo={dispositivo}
                />
            )}
        </Grid>
    )
}
