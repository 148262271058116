import React, { useEffect, useState } from 'react'
import { Grid, TextField, Link } from '@mui/material'
import moment from 'moment'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { mostrarFecha } from 'utils/Utils'
import { validatorInput } from 'utils/ValidatorForm'
import { EliminarComentario } from './EliminarComentario'

/**
 * @param {{comentarioPrivado: {comment: string, datetime: string}, token: string, setNuevo: Function, device: Number, id_alarm: Number}}
 * @param comentarioPrivado Comentario privado de la alarma
 * @param token Token para realizar la llamada
 * @param setNuevo Funcion para actualizar los comentarios
 * @param device Numero de serie del dispositivo
 * @param id_alarm Id de la alarma
 * @returns Devuelve el comentario privado de la alarma
 */
export const ComentarioPrivado = ({ comentarioPrivado, token, setNuevo, device, id_alarm }) => {
    const [disabled, setDisabled] = useState(comentarioPrivado?.comment !== undefined ? true : false)
    const [comentario, setComentario] = useState(comentarioPrivado?.comment !== undefined ? comentarioPrivado.comment : '')
    const [keyInputComentarioAlarm, setKeyInputComentarioAlarm] = useState(Date.now() + 'comentarioAlarmaPrivado')
    const [comentarioPrevio, setComentarioPrevio] = useState()
    const [fecha, setFecha] = useState(comentarioPrivado?.datetime)
    const [modalEliminar, setModalEliminar] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [invalidComment, setInvalidComment] = useState(false)

    /**
     * @returns Crea o actualiza el comentario privado de la alarma
     */
    const fetchCrearModificarComentario = async () => {
        let now = moment()
        let url = 'alarms/'
        let method
        let body
        if (comentarioPrivado?.comment !== undefined) {
            url += 'updateCommentAlarm'
            method = 'PATCH'
            body = {
                id: comentarioPrivado.id,
                comment: comentario
            }
        } else {
            url += 'createCommentAlarm'
            method = 'POST'
            body = {
                device: device,
                id_alarm: id_alarm,
                public: 0,
                comment: comentario
            }
        }
        try {
            setLoading(true)
            const res = await fetch(urls.scada + url, { method: method, headers: headersScada(token), body: JSON.stringify(body) })
            if (res.status === 200) {
                setDisabled(true)
                if (method === 'PATCH') {
                    setFecha(now)
                } else {
                    setNuevo((current) => !current)
                }
            } else {
                setError('Error al actualizar el comentario')
            }
            setLoading(false)
            return true
        } catch (err) {
            console.error('ERROR. API Scada', err)
        }
    }

    const handleChangeComment = (e) => {
        setComentario(e)
        setInvalidComment(validatorInput(e))
    }

    useEffect(() => {
        if (comentario.length > 300) {
            setError('No se puede crear un mensaje con más de 300 caracteres')
        }
        if (comentario.length <= 300 && error) {
            setError()
        }
    }, [comentario])

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div className='pb-1'>
                        <small className='color-black'>Comentario privado:</small>
                    </div>
                    <TextField
                        fullWidth
                        className='style-textfield-text'
                        key={keyInputComentarioAlarm}
                        error={invalidComment}
                        helperText={invalidComment ? 'Caracteres no permitidos' : ''}
                        minRows={1}
                        value={comentario}
                        disabled={disabled}
                        onChange={(e) => handleChangeComment(e.target.value)}
                    />
                    {fecha && (
                        <div className='pb-1'>
                            <small>
                                <small className='font-weight-lighter'>{mostrarFecha(fecha)}</small>
                            </small>
                        </div>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {error && <small className='color-red-error'>{error}</small>}
                </Grid>
                <Grid item xs={12}>
                    {disabled ? (
                        <>
                            <Link
                                href='#'
                                variant='body2'
                                onClick={(event) => {
                                    event.preventDefault()
                                    setComentarioPrevio(comentario)
                                    setDisabled(!disabled)
                                }}
                            >
                                <small className='font-weight-bold text-underline'>Editar</small>
                            </Link>
                            <Link
                                href='#'
                                variant='body2'
                                onClick={(event) => {
                                    event.preventDefault()
                                    setModalEliminar(true)
                                }}
                            >
                                <small className='font-weight-bold text-underline'>Eliminar</small>
                            </Link>
                        </>
                    ) : (
                        <>
                            <ButtonStyled
                                loading={loading}
                                className='boton-historico mt-2'
                                disabled={comentario.length > 300 || !comentario || invalidComment}
                                onClick={() => {
                                    fetchCrearModificarComentario()
                                }}
                            >
                                Guardar
                            </ButtonStyled>
                            {comentarioPrivado?.comment !== undefined && (
                                <ButtonStyled
                                    background='white'
                                    colorHover='red'
                                    disabled={loading}
                                    color='#212529'
                                    backgroundHover='#FFFFFF'
                                    border='2px solid red'
                                    className='ml-2 mt-2 boton-cancelar-dialog'
                                    onClick={() => {
                                        setComentario(comentarioPrevio)
                                        setKeyInputComentarioAlarm(Date.now() + 'comentarioAlarmaPrivado')
                                        setDisabled(!disabled)
                                    }}
                                >
                                    Cancelar
                                </ButtonStyled>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
            {modalEliminar && (
                <EliminarComentario
                    token={token}
                    id={comentarioPrivado.id}
                    modalEliminar={modalEliminar}
                    setModalEliminar={setModalEliminar}
                    setNuevo={setNuevo}
                />
            )}
        </>
    )
}
