import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { GlobalContext } from 'context/GlobalContext'
import { headersCentroControl, headersScada } from 'services/headers'
import { dispositivos, urls } from 'utils/constant'
import { Alarms } from 'views/Alarms/Alarms'
import { ConfiguracionList } from 'views/Configuracion/ConfiguracionList'
import { MenuScada } from '../../../components/MenuScada/MenuScada'
import { OnOff } from '../CcMaster/tabs/OnOff'
import { CardPhotovoltaic } from './CardPhotovoltaic'
import { Generationview } from './Generationview'
import { ScadaPhotovoltaicGeneral } from './ScadaPhotovoltaicGeneral'
import './StylePhotovoltaic.scss'

export const Photovoltaic = () => {
    const token = sessionStorage?.token
    const histo = useNavigate()
    const location = useLocation()
    const idCcMaster = location.pathname.split('/')[4]
    const isMobile = useMediaQuery({ maxWidth: 1680 })
    const { actualProject } = useContext(GlobalContext)
    const [activeTab, setActiveTab] = useState(0)
    const [selfConsumption, setSelfConsumption] = useState('general')
    const [loading, setLoading] = useState(true)
    const [devices, setDevices] = useState()
    const [currentDevice, setCurrentDevice] = useState(null)
    const [loose, setLoose] = useState()
    const [masterError, setMasterError] = useState()
    const [currentData, setCurrentData] = useState([])
    const [keySwitch, setKeySwitch] = useState(Date.now())

    const fetchIdCcMaster = (numserie) => {
        fetch(urls.ccontrol + `type/${numserie}`, {
            method: 'GET',
            headers: headersCentroControl(token)
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json()
                } else if (res.status === 400) {
                    histo.push({ pathname: `/auth/login` })
                } else {
                    return []
                }
            })
            .then((data) => {
                setLoose(data)
            })
            .catch((err) => {
                console.error('ERROR. API de Centro de control', err)
            })
    }
    const fetchInfoPower = () => {
        fetch(urls.scada + `getInfoPower/${idCcMaster}?idCCMaster=${idCcMaster}`, {
            method: 'GET',
            headers: headersScada(token)
        })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setMasterError(data)
            })
            .catch((err) => {
                console.error('ERROR. API Scada', err)
            })
    }

    const fetchDevices = (idCCMaster) => {
        fetch(urls.scada + `getccm/${idCCMaster}?idCCMaster=${idCCMaster}`, { method: 'GET', headers: headersScada(token) })
            .then((res) => res.json())
            .then((data) => {
                setDevices({
                    ...data,
                    permissions: actualProject?.permissions,
                    error: masterError ? masterError : [],
                    loose: loose
                })
            })
            .catch((err) => console.error('ERROR. API Scada', err))
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchCurrentData = (getSelfConsumption) => {
        if (getSelfConsumption) {
            fetch(urls.scada + `getCurrentData/${getSelfConsumption?.[0]?.numserie}?idCCMaster=${getSelfConsumption?.[0]?.master_serial}`, {
                method: 'GET',
                headers: headersScada(token)
            })
                .then((res) => {
                    res.json()
                })
                .then((data) => {
                    setCurrentData(data)
                })
                .catch((err) => {
                    console.error('ERROR. API Scada', err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        fetchIdCcMaster(idCcMaster)
    }, [idCcMaster])

    const handleView = (type, numserie) => {
        setSelfConsumption(type)
        setCurrentDevice(numserie)
    }

    useEffect(() => {
        fetchInfoPower()
    }, [])

    useEffect(() => {
        if (actualProject?.nick) {
            fetchDevices(idCcMaster)
        }
    }, [actualProject])

    useEffect(() => {
        if (devices) {
            const getSelfConsumption = devices?.childrenDevices?.filter((device) => device?.id_tipo_dispositivo === dispositivos.planta)
            fetchCurrentData(getSelfConsumption)
        }
    }, [devices, keySwitch])

    return (
        <div className='container-scada-principal'>
            {loading && <Loader />}
            {!loading && (
                <>
                    <Grid item xs={12} className='pl-3 pt-2 navbar-scada scada-navbar-centro-control'>
                        <MenuScada
                            token={token}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            setCurrentDevice={setCurrentDevice}
                            production={true}
                            master={devices}
                            loose={loose}
                            setSelfConsumption={setSelfConsumption}
                            selfConsumption={selfConsumption}
                        />
                    </Grid>
                    <Grid columns={13} container className='scada-self-container'>
                        <Grid item xs={3}>
                            <CardPhotovoltaic
                                devices={[devices]}
                                selfConsumption={selfConsumption}
                                handleView={handleView}
                                data={currentData}
                                setKeySwitch={setKeySwitch}
                            />
                        </Grid>
                        <Grid item xs={12} xl={10} className='container-scada-functions '>
                            {activeTab === 0 && currentDevice === null && (
                                <ScadaPhotovoltaicGeneral
                                    nickProject={actualProject?.nick}
                                    devices={[devices]}
                                    idCcMaster={idCcMaster}
                                    data={currentData}
                                />
                            )}
                            {selfConsumption === 'production' && currentDevice !== null && (
                                <Generationview
                                    nickProject={actualProject?.nick}
                                    idCcMaster={idCcMaster}
                                    setSelfConsumption={setSelfConsumption}
                                    devices={devices}
                                />
                            )}
                            {activeTab === 1 && <Alarms instalacioneSeleccionadas={[idCcMaster]} dispositivos={[devices]} />}
                            {activeTab === 2 && (
                                <ConfiguracionList
                                    photovoltaic={true}
                                    scadaSelf={true}
                                    instalacioneSeleccionadas={[idCcMaster]}
                                    dispositivo={[devices]}
                                    scada={true}
                                    masterError={devices?.error}
                                />
                            )}
                            {activeTab === 4 && <OnOff activeTab={activeTab} isMobile={isMobile} />}
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    )
}
