import { IconPanel } from 'utils/icon-centrocontrol'

const CardInfoScada = () => {
    return (
        <div className='card-info'>
            <div className='card-info-header'>
                <IconPanel className='w-auto' />
                <p>Producción FV</p>
            </div>
            <div className='card-info-body'>
                <p>kW</p>
                <p>kWh</p>
            </div>
            <div className='card-info-footer'>
                <p>11.34</p>
                <p>31.92</p>
            </div>
        </div>
    )
}

export default CardInfoScada
