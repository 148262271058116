import React from 'react'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'

export const PopUpInfo = ({ numserie, nickdevice, instalation }) => {
    return (
        <HtmlTooltip
            title={
                <>
                    <div className='rounded-top font-weight-bold p-1'>{'Información'}</div>
                    <div className='divider' />
                    {instalation && (
                        <div className='p-1'>
                            {'Instalación:'}
                            <b> {instalation}</b>
                        </div>
                    )}
                    <div className='p-1'>
                        {'S.N.:'}
                        <b> {numserie}</b>
                    </div>
                    <div className='p-1'>
                        {'Nombre:'}
                        <b> {nickdevice}</b>
                    </div>
                </>
            }
        >
            <Button className='p-0'>
                <FontAwesomeIcon icon={faInfoCircle} size='1x' color='#858585' />
            </Button>
        </HtmlTooltip>
    )
}
