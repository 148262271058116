import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@mui/material'
import { dispositivos } from 'utils/constant'
import { datoNull } from 'views/Scada/MABT/utils'
import { TensionGraph } from './TensionGraph'
import './PhasesTensionGraph.scss'

const ConsuptionTensionRealTime = ({ data, mabt }) => {
    const [t1, setT1] = useState(0)
    const [t2, setT2] = useState(0)
    const [t3, setT3] = useState(0)

    useEffect(() => {
        if (data?.v1) {
            setT1(datoNull(data.v1))
        }
        if (data?.v2) {
            setT2(datoNull(data.v2))
        }
        if (data?.v3) {
            setT3(datoNull(data.v3))
        }
    }, [data])

    return (
        <Card className='height-44 box-shadow-0'>
            <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <h1 className='flex justify-center text-enerclic'>Tensión</h1>
                </Grid>
                <Grid item xs={3} md={3} lg={3} xl={3} />
                <Grid item xs={6} md={6} lg={6} xl={6} className='mt-5'>
                    <Grid container className='container-first-tension'>
                        <Grid item xs={2} md={2} lg={2} xl={2} className='position-relative'>
                            <h2 className='chart-title-first-tension-consumption '>
                                {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'R' : 'L1'}
                            </h2>
                        </Grid>
                        <Grid item xs={10} md={10} lg={10} xl={10} className='position-relative'>
                            <div className='chart-tension-first'>
                                <TensionGraph value={t1} triphasic={true} mabt={mabt} />
                            </div>
                            <h2 className={`${t1 === 0 && 'color-red'} chart-value-tension-first-consumption`}>{t1} V</h2>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={12} className='position-relative'>
                            <p className='char-min-tension-value-first'>220 V</p>
                            <p className='char-max-tension-value-first'>250 V</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} md={3} lg={3} xl={3} />
            </Grid>
            <Grid container className='mt-5'>
                <Grid item xs={3} md={3} lg={3} xl={3} />
                <Grid item xs={6} md={6} lg={6} xl={6}>
                    <Grid container className='container-tension'>
                        <Grid item xs={2} md={2} lg={2} xl={2} className='position-relative'>
                            <h2 className='chart-title-tension-consumption'>
                                {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'S' : 'L2'}
                            </h2>
                        </Grid>
                        <Grid item xs={10} md={10} lg={10} xl={10} className='position-relative'>
                            <div className='chart-tension'>
                                <TensionGraph value={t2} triphasic={true} mabt={mabt} />
                            </div>
                            <h2 className={`${t2 === 0 && 'color-red'} chart-value-tension-consumption`}>{t2} V</h2>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={12} className='position-relative'>
                            <p className='char-min-tension-value'>220 V</p>
                            <p className='char-max-tension-value'>250 V</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} md={2} lg={2} xl={2} />
            </Grid>
            <Grid container className='mt-5'>
                <Grid item xs={3} md={3} lg={3} xl={3} />
                <Grid item xs={6} md={6} lg={6} xl={6}>
                    <Grid container className='container-tension'>
                        <Grid item xs={2} md={2} lg={2} xl={2} className='position-relative'>
                            <h2 className='chart-title-tension-consumption'>
                                {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'T' : 'L3'}
                            </h2>
                        </Grid>
                        <Grid item xs={10} md={10} lg={10} xl={10} className='position-relative'>
                            <div className='chart-tension'>
                                <TensionGraph value={t3} triphasic={true} mabt={mabt} />
                            </div>
                            <h2 className={`${t3 === 0 && 'color-red'} chart-value-tension-consumption`}>{t3} V</h2>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={12} className='position-relative'>
                            <p className='char-min-tension-value'>220 V</p>
                            <p className='char-max-tension-value'>250 V</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} md={3} lg={3} xl={3} />
            </Grid>
        </Card>
    )
}

export default ConsuptionTensionRealTime
