import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { FormControl, MenuItem, Grid } from '@mui/material'
import moment from 'moment'
import CalendarComponent from 'components/Calendar/CalendarComponent'
import { Modal } from 'components/Modal/Modal'
import { SelectStyled } from 'components/Select/SelectStyled'
import { apiScada } from 'services/api'
import { dispositivos } from 'utils/constant'
import { variableTitlesMabt } from 'utils/Translate/variableTitles'
import { esHoy } from 'utils/Utils'
import { cargarDatosTabla } from './utils'

export const ModalDownloadMoreData = ({ devices, open, setOpen, master }) => {
    const [loading, setLoading] = useState(false)
    const [dispositivo, setDispositivo] = useState()
    const [time, setTime] = useState(30)
    const [datos, setDatos] = useState([])
    const [start, setStart] = useState(moment())
    const [end, setEnd] = useState(moment())
    const csvLinkRef = useRef()
    let url = `getRegisterData/${dispositivo?.numserie}?idCCMaster=${master?.numserie}&iniDate=${start?.format('YYYY-MM-DD')}&endDate=${end?.format('YYYY-MM-DD')}&groupData=${time}&csv=${true}`

    const getData = async () => {
        setLoading(true)
        try {
            const response = await apiScada.get(url)
            if (response.status === 200) {
                setDatos(response?.data)
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        } finally {
            setLoading(false)
        }
    }

    const fetchDonwload = () => {
        if (dispositivo && time) {
            getData()
        }
    }

    useEffect(() => {
        if (datos.length > 0) {
            csvLinkRef.current.link.click()
        }
    }, [datos])

    return (
        <>
            <Modal
                open={open}
                setOpen={setOpen}
                title='Descarga de Datos'
                buttonAction={'descargar'}
                loading={loading}
                functSave={() => {
                    fetchDonwload()
                }}
                disabled={!time || !dispositivo}
            >
                <Grid container className='mt-2 mb-2'>
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                    <Grid item xs={10} md={10} lg={10} xl={10} className='flex-center justify-center mt-2'>
                        <CalendarComponent maxDate={end} setStart={setStart} setEnd={setEnd} />
                        <FormControl className='ml-2' size='small'>
                            <SelectStyled value={time} onChange={(e) => setTime(e.target.value)} id='SelectTime'>
                                <MenuItem value={1}>1 minuto</MenuItem>
                                <MenuItem value={5}>5 minutos</MenuItem>
                                <MenuItem value={15}>15 minutos</MenuItem>
                                <MenuItem value={30}>30 minutos</MenuItem>
                                <MenuItem value={60}>1 hora</MenuItem>
                            </SelectStyled>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                    <Grid item xs={10} md={10} lg={10} xl={10} className='mt-2'>
                        <FormControl className='width-100' size='small'>
                            <small className='font-weight-lighter'>Seleccione una salida:</small>
                            <SelectStyled value={dispositivo} onChange={(e) => setDispositivo(e.target.value)} id='SelectDispositivo'>
                                {devices.map((device) => {
                                    return (
                                        <MenuItem value={device}>
                                            {device?.id_tipo_dispositivo === dispositivos.ccmabtv
                                                ? device?.nick
                                                    ? device?.nick
                                                    : 'VTN'
                                                : device?.nick
                                                  ? device?.nick
                                                  : 'Sin nombre ' + device.mabt_output}
                                        </MenuItem>
                                    )
                                })}
                            </SelectStyled>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                </Grid>
            </Modal>
            <CSVLink
                ref={csvLinkRef}
                data={datos ? cargarDatosTabla(datos, variableTitlesMabt, esHoy(start)) : []}
                separator={';'}
                filename={`${master?.alias + dispositivo?.nick}.csv`}
            />
        </>
    )
}
