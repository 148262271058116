import React, { useEffect, useState } from 'react'
import { Line, Bar, Doughnut } from 'react-chartjs-2'
import { Grid } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import './LineChart.scss'
import 'views/Scada/SelfConsumption/General/ScadaGeneral.scss'
import zoomPlugin from 'chartjs-plugin-zoom'
ChartJS.register(zoomPlugin)
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend, Filler)

export const Chart = (props) => {
    const [graph, setGraph] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(!loading)
    }, [props])

    const monthly = (obj, daily) => {
        if (daily === 'months') {
            switch (obj.labels[0]) {
                case '1':
                    obj.labels[0] = 'ENERO'
                    break
                case '2':
                    obj.labels[0] = 'FEBRERO'
                    break
                case '3':
                    obj.labels[0] = 'MARZO'
                    break
                case '4':
                    obj.labels[0] = 'ABRIL'
                    break
                case '5':
                    obj.labels[0] = 'MAYO'
                    break
                case '6':
                    obj.labels[0] = 'JUNIO'
                    break
                case '7':
                    obj.labels[0] = 'JULIO'
                    break
                case '8':
                    obj.labels[0] = 'AGOSTO'
                    break
                case '9':
                    obj.labels[0] = 'SEPTIEMBRE'
                    break
                case '10':
                    obj.labels[0] = 'OCTUBRE'
                    break
                case '11':
                    obj.labels[0] = 'NOVIEMBRE'
                    break
                case '12':
                    obj.labels[0] = 'DICIEMBRE'
                    break
                default:
                    break
            }
        }
        return obj
    }

    const chartRef = React.useRef(null)
    const handleResetZoom = () => {
        if (chartRef && chartRef.current) {
            chartRef.current.resetZoom()
        }
    }

    useEffect(() => {
        if (props?.dataFilter?.datasets !== undefined && props?.dataFilter?.labels) {
            if (props?.graphStyle === 'bar') {
                setGraph(
                    <Bar
                        style={{ width: '100%', height: '20em' }}
                        data={monthly(props?.dataFilter, props?.daily)}
                        options={props?.LineOptions}
                        ref={chartRef}
                    />
                )
            } else if (props?.graphStyle === 'doughnut') {
                let option = {
                    plugins: {
                        tooltip: {
                            enabled: false
                        }
                    }
                }
                setGraph(<Doughnut data={props?.dataFilter} ref={chartRef} options={option} />)
            } else {
                setGraph(
                    <Line style={{ width: '100%', height: '20em' }} data={props?.dataFilter} options={props?.LineOptions} ref={chartRef} />
                )
            }
        }
    }, [loading, props?.dataFilter])

    useEffect(() => {
        setLoading(!loading)
    }, [props?.dataFilter])

    useEffect(() => {
        handleResetZoom()
    }, [graph])

    return (
        <>
            {props?.graphStyle === 'doughnut' ? (
                <Grid className={props?.className ? props?.className : ''} style={{ width: '200px' }}>
                    {graph}
                </Grid>
            ) : (
                <Grid className='sonChart'>{graph}</Grid>
            )}
        </>
    )
}
