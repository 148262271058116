import React, { useEffect, useState } from 'react'
import { Grid, MenuItem, FormControl } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { SelectStyled } from 'components/Select/SelectStyled'
import { SelectMultiple } from 'components/SelectMultiple/SelectMultiple'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { dispositivos, alarmas } from 'utils/constant'
import { nombreDispositivo, nombreAlarma } from 'utils/Utils'
import { validatorInputNumber } from 'utils/ValidatorForm'

export const FormCrear = ({
    setDispositivoSeleccionado,
    tipoAlarma,
    tipoDispositivo,
    dispositivoSeleccionado,
    seleccionarTipoDispositivo,
    dispositivosProyecto,
    alarmasProyecto,
    seleccionarTipoAlarma,
    todosDispositivos,
    tiempoAlarma,
    setTiempoAlarma,
    tiempoMinimo,
    tiempoMaximo,
    alarmasPendientes,
    loadingConfig
}) => {
    const [keyAlarma, setKeyAlarma] = useState(Date.now() + 'alarma')
    const [mostrarInstalacion, setMostrarInstalacion] = useState(false)

    const handleTiempoAlarma = (valor) => {
        if (validatorInputNumber(valor)) {
            setTiempoAlarma(valor)
        } else if (valor === '' || valor === undefined || valor === null) {
            setTiempoAlarma(valor)
        }
    }

    const handleSelectDispositivos = (event, newValue) => {
        if (newValue?.[newValue.length - 1]?.nick === 'Seleccionar Todos') {
            setDispositivoSeleccionado(todosDispositivos?.slice(0))
        } else {
            setDispositivoSeleccionado([...newValue])
        }
    }

    useEffect(() => {
        setKeyAlarma(Date.now() + 'alarma')
    }, [tipoDispositivo])

    return (
        <Grid container>
            <Grid item xs={1} md={1} lg={1} xl={1} />
            <Grid item xs={4} md={4} lg={4} xl={4}>
                <FormControl className='width-100' size='small'>
                    <small className='font-weight-lighter'>Seleccione un tipo de dispositivo*:</small>
                    <SelectStyled value={tipoDispositivo} onChange={seleccionarTipoDispositivo} id='CrearTipoDispositivoAlarma'>
                        {Object?.values(dispositivos)
                            ?.filter(
                                (ele) => alarmasProyecto?.[ele] && dispositivosProyecto?.indexOf(ele) !== -1 && alarmasPendientes?.[ele]
                            )
                            ?.map((dispositivo) => {
                                return <MenuItem value={dispositivo}>{nombreDispositivo(dispositivo)}</MenuItem>
                            })}
                    </SelectStyled>
                </FormControl>
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2} />
            <Grid item xs={4} md={4} lg={4} xl={4}>
                <FormControl className='width-100' size='small'>
                    <small className='font-weight-lighter'>Seleccione un tipo de alarma*:</small>
                    <SelectStyled
                        key={keyAlarma}
                        value={tipoAlarma}
                        onChange={seleccionarTipoAlarma}
                        id='CrearTipoAlarma'
                        disabled={tipoDispositivo === undefined || alarmas === undefined}
                    >
                        {Object?.values(alarmas)
                            ?.filter(
                                (ele) =>
                                    tipoDispositivo !== undefined &&
                                    alarmasProyecto?.[tipoDispositivo] &&
                                    alarmasProyecto?.[tipoDispositivo]?.indexOf(ele) !== -1 &&
                                    alarmasPendientes?.[tipoDispositivo] &&
                                    alarmasPendientes?.[tipoDispositivo]?.includes(ele)
                            )
                            ?.map((alarma) => {
                                return <MenuItem value={alarma}>{nombreAlarma(alarma)}</MenuItem>
                            })}
                    </SelectStyled>
                </FormControl>
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} />
            <Grid item xs={1} md={1} lg={1} xl={1} />
            <Grid item xs={10} md={10} lg={10} xl={10} className='mt-2'>
                <div className='flex'>
                    <small className='font-weight-lighter'>Seleccione los dispositivos*:</small>
                </div>
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} />
            <Grid item xs={1} md={1} lg={1} xl={1} />
            <Grid item xs={10} md={10} lg={10} xl={10} className='mt-1'>
                <SelectMultiple
                    onChange={(event, newValue) => handleSelectDispositivos(event, newValue)}
                    options={
                        tipoAlarma === undefined || tipoDispositivo === undefined || todosDispositivos?.length === 0
                            ? []
                            : [{ nick: 'Seleccionar Todos' }]?.concat(todosDispositivos)
                    }
                    value={
                        tipoAlarma === undefined || tipoDispositivo === undefined || todosDispositivos?.length === 0
                            ? []
                            : dispositivoSeleccionado
                    }
                    disabled={tipoAlarma === undefined || tipoDispositivo === undefined || todosDispositivos?.length === 0}
                    optionLabelFunction={(option) =>
                        `${mostrarInstalacion ? (option?.['nickMaster'] ? option?.['nickMaster'] + ' -' : '') : ''} 
                            ${
                                option['nick']
                                    ? option['nick']
                                    : tipoDispositivo === dispositivos.ccmabtv
                                      ? 'VTN'
                                      : 'Sin nombre' + (option['posicion'] ? ' ' + option['posicion'] : '')
                            }`
                    }
                    loading={loadingConfig}
                    optionLabel='nick'
                    id='CrearDispositivosAlarma'
                />
                {dispositivoSeleccionado?.length > 0 && (
                    <FormControl className='width-100 color-red' size='small'>
                        <small className='font-weight-lighter'>Compruebe la configuración de las notificaciones antes de guardar</small>
                    </FormControl>
                )}
                <div>
                    <SwitchStyled
                        id='habilitado'
                        className='switch-extra-small mr-2'
                        checked={mostrarInstalacion}
                        onClick={() => setMostrarInstalacion(!mostrarInstalacion)}
                    />
                    <small className='font-weight-lighter'>Mostrar instalación</small>
                </div>
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} />
            {tipoAlarma !== alarmas.tramo && tipoAlarma !== alarmas.rangoSensor && tipoAlarma !== undefined && (
                <>
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                    <Grid item xs={10} md={10} lg={10} xl={10} className='mt-2'>
                        <small className='font-weight-lighter'>Introduzca el tiempo de observación para la alarma*:</small>
                        <InputStyled value={tiempoAlarma} onChange={handleTiempoAlarma} />
                        <FormControl className='width-100 color-red' size='small'>
                            <small className='font-weight-lighter'>
                                {tiempoMinimo > 0 &&
                                    tiempoMaximo > 0 &&
                                    `El valor debe estar entre ${tiempoMinimo} - ${tiempoMaximo} minutos`}
                                {tiempoMinimo > 0 && tiempoMaximo === 0 && `El valor debe ser superior a ${tiempoMinimo} minutos`}
                                {tiempoMinimo === 0 && tiempoMaximo === 0 && `El valor debe ser superior a 0 minutos`}
                                {tiempoMinimo === 0 && tiempoMaximo > 0 && `El valor debe estar entre 0 - ${tiempoMaximo} minutos`}
                            </small>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                </>
            )}
        </Grid>
    )
}
