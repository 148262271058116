import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'

export const ResumeAddCcMaster = ({ nick, numserie, address, group, project, activeTab, subgroup }) => {
    const [groups, setGroups] = useState()
    const [subGroups, setSubGroups] = useState()

    useEffect(() => {
        if (activeTab === 4) {
            setGroups(project?.groups?.filter((grup) => grup?.id_group === group))
        }
    }, [activeTab])

    useEffect(() => {
        if (groups && subgroup) {
            setSubGroups(groups?.[0]?.subgroups?.filter((sub) => sub?.id_subgroup === subgroup))
        }
    }, [groups])

    return (
        <>
            <Grid item xs={12}>
                <Grid item xs={12} className='flex justify-content-center align-items-center'>
                    <Grid item xs={12} lg={6} className='m-1'>
                        <div className='pb-1'>
                            <small className='color-black'>Nombre de la instalación:</small>
                        </div>
                        <InputStyled disabled maxLength={100} fullWidth value={nick} />
                    </Grid>
                    <Grid item xs={12} lg={6} className='m-1 '>
                        <div className='pb-1'>
                            <small className='color-black'>Número de serie:</small>
                        </div>
                        <InputStyled disabled fullWidth value={numserie} />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} className='m-1 '>
                    <div className='pb-1'>
                        <small className='color-black'>Localización:</small>
                    </div>
                    <InputStyled disabled fullWidth value={address} />
                </Grid>
                <Grid item xs={12} className='flex justify-content-center align-items-center'>
                    <Grid item xs={12} lg={subgroup > 0 ? 6 : 12} className='m-1 '>
                        <div className='pb-1'>
                            <small className='color-black'>Grupo Seleccionado:</small>
                        </div>
                        <InputStyled disabled fullWidth value={groups?.[0]?.nick} />
                    </Grid>
                    {subgroup > 0 && (
                        <Grid item xs={12} lg={6} className='m-1 '>
                            <div className='pb-1'>
                                <small className='color-black'>Subgrupo Seleccionado:</small>
                            </div>
                            <InputStyled disabled fullWidth value={subGroups?.[0]?.nick} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
