import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse, FormControlLabel, Grid } from '@mui/material'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { updateGroupTree } from 'reduxSlice/treeDevicesSlice'
import { IconAlarmWith, IconSharedInvert, IconArrowAccordion } from 'utils/icon-centrocontrol'
import { DropdownGroup } from 'views/Group/MenuGroup/DropdownGroup'
import { ModalAddCcMasterGuided } from 'views/Master/CreateMaster/ModalAddCcMasterGuided'
import { Device } from './Device'
import { SubGroup } from './SubGroup'

export const Group = ({ numserieError, orderBy, index, actualProject, group, data, setActiveTab, activeTab }) => {
    const [openAdd, setOpenAdd] = useState(false)
    const [accordion, setAccordion] = useState(true)
    const [keyGroup, setKeyGroup] = useState(new Date())
    const dispatch = useDispatch()
    const prefix = 'G-' + group?.id_group
    const groupCheck = useSelector((state) => state?.treeDevices?.tree?.[prefix])

    const toggleCheckbox = () => {
        setKeyGroup('key' + new Date())
        dispatch(updateGroupTree({ group: prefix, disabled: numserieError }))
    }

    return (
        <Grid container key={'Group' + index + keyGroup}>
            <Grid container className='bk-black pr-1 pl-1'>
                <Grid item xs={7} md={7} lg={7} xl={7} className='flex-center justify-start pt-2 pb-2'>
                    {(group?.devices?.length > 0 || group?.subgroups?.length > 0) && (
                        <HtmlTooltip title={<span>{accordion === false ? 'Expandir' : 'Colapsar'}</span>}>
                            <span
                                className={`button-accordion ${accordion === false ? '' : 'rotate-button-acordion'}`}
                                onClick={() => setAccordion(!accordion)}
                            >
                                <IconArrowAccordion />
                            </span>
                        </HtmlTooltip>
                    )}
                    <DropdownGroup groups={group} projects={actualProject} showInstalations={true} />
                    {group?.nick?.length > 22 ? (
                        <HtmlTooltip title={<span> {group?.nick} </span>}>
                            <h3 className='nick-group pl-1 cursor-pointer' onClick={(e) => toggleCheckbox(e)}>
                                {group?.nick?.substr(0, 19) + '...'}
                            </h3>
                        </HtmlTooltip>
                    ) : (
                        <h3 className='nick-group pl-1 cursor-pointer' onClick={(e) => toggleCheckbox(e)}>
                            {group?.nick}
                        </h3>
                    )}
                </Grid>
                {group?.devices?.length > 0 ? (
                    <>
                        <Grid item xs={1} md={1} lg={1} xl={1} className='flex-center justify-center'>
                            <FormControlLabel
                                className='checkbox-display-group checkbox path checkInstallations-check checkbox-display-group flex'
                                control={
                                    <input
                                        type='checkbox'
                                        style={{
                                            background: '#fff',
                                            width: '15px',
                                            height: '15px',
                                            stroke: groupCheck ? '#FF8A1F' : '#fff'
                                        }}
                                        checked={groupCheck}
                                        onChange={(e) => toggleCheckbox()}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} xl={1} className='flex-center justify-center'>
                            <p className='title-acordion m-0'>Rol</p>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} xl={1} className='flex-center justify-center'>
                            <HtmlTooltip title={<span>Instalación compartida</span>}>
                                <IconSharedInvert />
                            </HtmlTooltip>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} xl={1} className='flex-center justify-center'>
                            <HtmlTooltip title={<span>Alarmas activas</span>}>
                                <IconAlarmWith color='white' />
                            </HtmlTooltip>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1} xl={1} className='flex-center justify-center'>
                            <p className='title-acordion m-0'>Scada</p>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={5} md={5} lg={5} xl={5} className='button-new-installation-principal'>
                        <ModalAddCcMasterGuided
                            where='id_group'
                            id_group={group?.id_group}
                            id_project={actualProject?.id_project}
                            groups={group}
                            open={openAdd}
                            setOpen={setOpenAdd}
                        />
                    </Grid>
                )}
            </Grid>
            {group?.devices?.length === 0 && <Grid className='bk-white pt-2' item xs={12} md={12} lg={12} xl={12} />}
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <Collapse in={accordion}>
                    {group?.devices
                        ?.sort((a, b) =>
                            orderBy === 'nick'
                                ? a?.nick?.localeCompare(b?.nick)
                                : b?.alarmCount - b?.alarmHide - (a?.alarmCount - a?.alarmHide)
                        )
                        ?.map((device, index) => (
                            <Device
                                activeTab={activeTab}
                                key={'keyDeviceGroup' + index}
                                actualProject={actualProject}
                                group={group}
                                device={device}
                                data={data}
                                index={index}
                                setActiveTab={setActiveTab}
                                pref={prefix}
                            />
                        ))}
                    {group?.subgroups
                        ?.sort((a, b) => a?.nick?.localeCompare(b?.nick))
                        ?.map((subGroup, index) => (
                            <SubGroup
                                activeTab={activeTab}
                                numserieError={numserieError}
                                key={'keySubGroup' + index}
                                orderBy={orderBy}
                                actualProject={actualProject}
                                group={group}
                                subGroup={subGroup}
                                data={data}
                                index={index}
                                setActiveTab={setActiveTab}
                                pref={prefix}
                            />
                        ))}
                </Collapse>
            </Grid>
        </Grid>
    )
}
