import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Loader } from 'components/Loader/Loader'
import { urls } from 'utils/constant'
import { Card, Grid } from '@mui/material'
import { headersCentroControl } from 'services/headers'
import { MinervaIcon } from 'utils/icon-centrocontrol'

/**
 * @param Nos hace una llamada gracias a useEffect que nos confirma que el usuario al que ha sido cedido el proyecto acepta ese compartido
 * @returns Retornamos el mensaje que nos envía el back con la API y habilitamos un botón que permite volver al login
 */
export const CedeConfirm = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const type = location.pathname.split('/')[3]
    const code = location.pathname.split('/')[4]
    const permission = location.pathname.split('/')[5]
    const idCede = location.pathname.split('/')[7]
    const idUser = location.pathname.split('/')[8]
    const Usertoken = location.pathname.split('/')[9]
    const [body, setBody] = useState()
    const [url, setUrl] = useState()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    //datos enersim
    const idEnersim = location.pathname.split('/')[5]
    const permissionEnersim = location.pathname.split('/')[6]
    const idNewUserEnersim = location.pathname.split('/')[10]
    const accepted = location.pathname.split('/')[11]
    const idUserEnersim = location.pathname.split('/')[12]
    const eraserEnersim = location.pathname.split('/')[13]

    const mountCede = () => {
        if (type === 'project') {
            setBody({ acepted: 1, id_project: parseInt(idCede), id_User: Number(idUser), permission: permission })
            setUrl(`cede/project/${code}`)
        } else if (type === 'group') {
            setBody({ acepted: 1, id_group: parseInt(idCede), permission: permission, id_User: Number(idUser) })
            setUrl(`cede/group/${code}`)
        } else if (type === 'subgroup') {
            setBody({ acepted: 1, id_subgroup: parseInt(idCede), permission: permission, id_User: Number(idUser) })
            setUrl(`cede/subgroup/${code}`)
        } else if (type === 'device') {
            setBody({ acepted: 1, numserie: Number(idCede), permission: permission, id_User: Number(idUser) })
            setUrl(`cede/device/${code}`)
        } else if (type === 'enersim') {
            setBody({
                acepted: Number(accepted),
                enerSim: idEnersim,
                permission: permissionEnersim,
                id_user: idUserEnersim,
                id_new_user: idNewUserEnersim,
                action: 'cedeSim',
                eraser: eraserEnersim
            })
            setUrl(`cedeEnerSim/${code}`)
        }
    }

    useEffect(() => {
        mountCede()
    }, [])

    const handleForm = () => {
        setLoading(true)
        fetch(urls.ccontrol + url, {
            method: 'PATCH',
            redirect: 'follow',
            headers: headersCentroControl('bearer' + Usertoken),
            body: JSON.stringify(body)
        })
            .then((res) => {
                setLoading(false)
                if (res.status === 200 || res.status.toString().charAt(0) === '4') {
                    return res.json()
                } else {
                    throw res
                }
            })
            .then((data) => {
                setMessage(data.message)
            })
            .catch((err) => {
                console.error('ERROR. API Centro de Control', err)
            })
    }

    useEffect(() => {
        if (code && body) {
            handleForm()
        }
    }, [body])

    //Si el usuario no está logueado, lo mando al LOGIN y en el estado, guardo la información nencesaria
    const goLogin = () => navigate(`/auth/login`)

    return (
        <Grid item xs={11} md={12} lg={12} xl={12} className='grid-login'>
            <MinervaIcon />
            <h2 className='title-login'> ACCESO CLIENTES</h2>
            <p className='subtitle-login'>
                ¡Bienvenido! Accede a tu <b>Portal Enerclic</b>
            </p>
            <Card className='card-reset-password'>
                <Grid item xs={12} md={12} lg={12} xl={12} className='grid-return-login'>
                    <a onClick={goLogin} className='button-return-login'>
                        x
                    </a>
                </Grid>
                {type === 'enersim' ? (
                    <h2 className='text-register-user'>Petición de ceder enerSim </h2>
                ) : (
                    <h2 className='text-register-user'>
                        Aceptar{' '}
                        {type === 'project' ? 'proyecto' : type === 'group' ? 'grupo' : type === 'subgroup' ? 'subgrupo' : 'dispositivo'}{' '}
                        cedido{' '}
                    </h2>
                )}
                {loading ? (
                    <Loader />
                ) : (
                    <Grid item xs={12} sm={12} lg={12} xl={12} className='register-login-textfield new-password-auth mt-4 mb-4'>
                        <Grid
                            item
                            xs={11}
                            sm={11}
                            lg={8}
                            xl={8}
                            style={{ marginTop: window.innerWidth < 378 ? '6px' : null, width: '100%' }}
                        >
                            <h3 className='text-align-center'>{message}</h3>
                            <Grid item xs={12} sm={12} lg={12} xl={12} className='register-login-textfield container-button-register-user'>
                                <Grid item xs={12} sm={12} lg={6} xl={6}>
                                    <button variant='contained' loading={loading} className='button-create-user-login' onClick={goLogin}>
                                        Ir a Login
                                    </button>
                                </Grid>
                            </Grid>
                            <Grid className='mt-4'>
                                <p>
                                    Si está Logueado, por favor, cierre esta pestaña y refresque la pestaña abierta (F5) para ver los
                                    Projectos
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Card>
        </Grid>
    )
}
