import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import './StyleModal.scss'

export const ModalPauseCalendarCcMaster = ({ data, idEntry, idCcMaster, modal, setModal }) => {
    const token = sessionStorage?.token

    const [enable, setEnable] = useState()
    const [loadingPause, setLoadingPause] = useState(false)
    const [body2, setBody2] = useState([])
    const dispatch = useDispatch()

    const handleEnable = () => {
        if (data?.enable === false) {
            setEnable(true)
        } else {
            setEnable(false)
        }
    }

    useEffect(() => {
        handleEnable()
        setBody2([
            {
                state: data?.state,
                enable: enable,
                SN_device: idCcMaster?.toString(),
                alias: data?.alias,
                week_day: data?.week_day,
                hour: data?.hour,
                min: data?.min,
                internal_mode: data?.internal_mode,
                ID_entry: idEntry
            }
        ])
    }, [enable, data, idCcMaster, idEntry])

    const asyncCalendar = () => {
        let status
        setLoadingPause(true)
        handleEnable()

        fetch(urls.scada + `calendar/${idCcMaster}?idCCMaster=${idCcMaster}`, {
            method: 'PATCH',
            headers: headersScada(token),
            body: JSON.stringify(body2)
        })
            .then((res) => {
                status = res.status
                if (res.status === 200) {
                    data.enable = !data?.enable
                } else {
                }
                return res.json()
            })
            .then((data) => {
                setModal(false)
                setLoadingPause(false)
                dispatch(createToast({ status: status, message: data.message, reload: false }))
            })
            .catch((err) => console.error('ERROR. API Scada', err))
    }

    return (
        <>
            <Modal
                open={modal}
                setOpen={setModal}
                functSave={asyncCalendar}
                disabled={loadingPause}
                title='Confirmación sobre el calendario'
                loading={loadingPause}
                buttonAction='Aplicar'
            >
                <span style={{ margin: '0 auto' }}>
                    <h2>¿Quieres {enable ? 'encender' : 'pausar'} esta programación?</h2>
                </span>
            </Modal>
        </>
    )
}
