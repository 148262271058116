import React, { useEffect, useState } from 'react'
import { Grid, FormControl } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { validatorFloatVacio } from 'utils/ValidatorForm'

export const FormCrearRangoSensor = ({ setValidateFormTramo, objAlarmRangoSensor, setObjAlarmRangoSensor }) => {
    const [valueLower, setValueLower] = useState(objAlarmRangoSensor?.down ?? '')
    const [valueUpper, setValueUpper] = useState(objAlarmRangoSensor?.up ?? '')
    const error = valueLower !== '' && valueUpper !== '' && Number(valueLower) > Number(valueUpper)

    const handleLowerValue = (valor) => {
        if (validatorFloatVacio(valor)) {
            setValueLower(valor)
            objAlarmRangoSensor.down = valor === '' ? undefined : parseFloat(valor)
            setObjAlarmRangoSensor(objAlarmRangoSensor)
        }
    }

    const handleUpperValue = (valor) => {
        if (validatorFloatVacio(valor)) {
            setValueUpper(valor)
            objAlarmRangoSensor.up = valor === '' ? undefined : parseFloat(valor)
            setObjAlarmRangoSensor(objAlarmRangoSensor)
        }
    }

    useEffect(() => {
        if (
            (valueLower !== '' && valueUpper === '') ||
            (valueLower === '' && valueUpper !== '') ||
            (valueLower !== '' && valueUpper !== '' && Number(valueLower) < Number(valueUpper))
        ) {
            setValidateFormTramo(true)
        } else {
            setValidateFormTramo(false)
        }
    }, [valueLower, valueUpper])

    return (
        <Grid container>
            <Grid item xs={1} md={1} lg={1} xl={1} />
            <Grid item xs={4} md={4} lg={4} xl={4} className='mt-2'>
                <small className='font-weight-lighter'>Introduzca valor mínimo:</small>
                <InputStyled value={valueLower} onChange={handleLowerValue} />
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2} />
            <Grid item xs={4} md={4} lg={4} xl={4} className='mt-2'>
                <small className='font-weight-lighter'>Introduzca el valor máximo:</small>
                <InputStyled value={valueUpper} onChange={handleUpperValue} />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} />
            {error && (
                <>
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                    <Grid item xs={10} md={10} lg={10} xl={10} className='mt-2'>
                        <FormControl className='width-100 color-red' size='small'>
                            <small className='font-weight-lighter'>El valor máximo no puede ser superior al valor mínimo</small>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                </>
            )}
        </Grid>
    )
}
