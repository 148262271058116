import React from 'react'
import ReactDOM from 'react-dom/client'
import ErrorBoundary from 'views/PageError/NotFound'
import './styleRefactor.scss'
import './views/Principal/StylePrincipal.scss'
import { App } from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>
)
