import React, { useState, useEffect } from 'react'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { Chart } from 'views/Scada/SelfConsumption/GraphsUtils/LineChart'
import { toKiloW, toKilowPercent } from 'utils/functionsforGraphs'
import { IconWarning } from 'utils/icon-centrocontrol'
import '../ScadaConsumptionPower/TotalActivepower.scss'

export const TotalIntensity = ({ data, sinConectar }) => {
    const [graph, setGraph] = useState()
    const [negativeGraph, setNegativeGraph] = useState()
    const [it, setIt] = useState()
    const hiredIntensity = data?.intensidad_magneto

    useEffect(() => {
        if (data) {
            switch (data?.id_tipo_dispositivo) {
                case 1:
                    setIt(data?.it)
                    break
                case 2:
                    setIt(data?.i1)
                    break
                case 12:
                    setIt(data?.it)
                    break
                default:
                    setIt(data?.i1 + data?.i2 + data?.i3)
                    break
            }
        }
    }, [data])

    const itValue = (value) => {
        if (data?.intensidad_magneto >= 0 && value) {
            let aux = (value * 100) / hiredIntensity
            if (aux > 100) {
                aux = 100
            }
            return aux.toFixed(2)
        } else {
            return 0
        }
    }

    const dataFilterPositive = (data, id) => {
        if (data === undefined || data === null || data <= 0 || sinConectar || hiredIntensity === null) {
            data = 0
        } else {
            data = itValue(data)
        }
        let rest = 100 - data
        let res = {
            labels: [],
            datasets: [
                {
                    id: id,
                    type: 'doughnut',
                    data: [data, rest],
                    backgroundColor: ['#8E1822', '#CBCBCC'],
                    responsive: true,
                    circumference: 180,
                    rotation: -90,
                    borderWidth: 3,
                    cutout: '80',
                    radius: '100%'
                }
            ]
        }
        return res
    }

    const dataFilterNegative = (data, id) => {
        if (data >= 0 || sinConectar) {
            data = 0
        } else {
            data = itValue(data)
        }
        let rest = 100 - data
        let res = {
            labels: [],
            datasets: [
                {
                    id: id,
                    type: 'doughnut',
                    data: [data, rest],
                    backgroundColor: ['#8E1822', '#CBCBCC'],
                    responsive: true,
                    circumference: 180,
                    rotation: 90,
                    borderWidth: 3,
                    cutout: '80',
                    radius: '100%'
                }
            ]
        }
        return res
    }

    useEffect(() => {
        setGraph(<Chart imprime={true} dataFilter={dataFilterPositive(Number(it), 1)} graphStyle={'doughnut'} />)
        setNegativeGraph(<Chart imprime={true} dataFilter={dataFilterNegative(Number(it), 2)} graphStyle={'doughnut'} />)
    }, [data, it])

    return (
        <div className='flex flex-col items-center justify-start'>
            {graph && negativeGraph && (
                <>
                    <div className='flex items-center gap-4'>
                        <p className='font-bold mb-0 mt-5'>0 A</p>
                        <div className='flex flex-col relative'>
                            <h3 className='text-enerclic text-2xl font-bold '>Intensidad</h3>
                            <div className='-mb-14'>{graph}</div>
                            <div className='absolute top-1/2 -translate-y-1/2 w-full flex flex-col items-center justify-center pt-5'>
                                <p>{hiredIntensity !== null && <>{!sinConectar ? toKiloW(it) + ' A' : '-'}</>}</p>
                                <p>
                                    {hiredIntensity === null ? (
                                        <span>
                                            <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                <IconWarning width={20} height={20} />
                                            </HtmlTooltip>
                                        </span>
                                    ) : it !== ' - ' && it && !sinConectar ? (
                                        toKilowPercent(itValue(it)) + '%'
                                    ) : (
                                        ' 0.0 %'
                                    )}
                                </p>
                            </div>
                            <div className='-mt-14'>{negativeGraph}</div>
                        </div>
                        <p className='font-bold mt-5'>{hiredIntensity && toKiloW(hiredIntensity)} A</p>
                    </div>
                </>
            )}
        </div>
    )
}
