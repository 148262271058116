import React, { useContext, useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { GlobalContext } from 'context/GlobalContext'
import { apiCentroControl, apiScada } from 'services/api'
import { instalaciones, dispositivos } from 'utils/constant'
import { ConfiguracionPlantaMabt } from 'views/Configuracion/Mabt/ConfiguracionPlantaMabt'
import { ConfigCCmasterInstall } from './ConfigCCmasterInstall'
import { ModalConfigChangeInstall } from './ModalConfigChangeInstall'
import './EditInstall.scss'

export const ModalEditInstall = ({ data, modalEditar, setModalEditar }) => {
    const { actualProject } = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [dataConfig, setDataConfig] = useState([])
    const [dataConfigInitial, setDataConfigInitial] = useState([])
    const [dataInitialPmax, setDataInitialPmax] = useState([])
    const [activeTab, setActiveTab] = useState(0)
    const [open, setOpen] = useState(false)
    const [answer, setAnswer] = useState([])
    const [configPlant, setConfigPlant] = useState(data?.inst_type)
    const [masterConfigInstall, setMasterConfigInstall] = useState({})
    const [disabled, setDisabled] = useState(true)
    const [disabledTable, setDisabledTable] = useState(true)
    const [dataEdit, setDataEdit] = useState([])
    const [MABT, setMABT] = useState()
    const MABTProject = actualProject?.category === instalaciones?.mabt

    const fetchErrorInstalations = async () => {
        try {
            const res = await apiCentroControl.get(`userInfoInstallations/${data?.numserie}`)
            if (res.status === 200) {
                let master = res?.data?.filter((dev) => dev?.id_tipo_dispositivo === dispositivos.ccmaster)
                data.inst_type = master?.[0]?.inst_type
            }
        } catch (error) {
            console.error('No ha sido posible comunicarse con la Api de ....user Info Installations', error)
        } finally {
            setLoadingTable(false)
            setLoading(false)
        }
    }

    const fetchMabt = async (numSerie) => {
        setLoading(true)
        try {
            const res = await apiScada.get(`getccm/${numSerie}?idCCMaster=${numSerie}`)
            if (res?.status === 200) {
                return res.data
            }
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchErrorInstalations()
    }, [data])

    useEffect(() => {
        if (configPlant !== data?.inst_type) {
            setMasterConfigInstall([
                {
                    config: {
                        plant_type: configPlant
                    }
                }
            ])
        } else {
            setMasterConfigInstall({})
        }
    }, [configPlant])

    useEffect(() => {
        if (activeTab === 1) {
            setDisabled(!dataEdit?.length > 0)
        }
    }, [dataEdit])

    useEffect(() => {
        async function fetchData() {
            if (MABTProject) {
                let dis
                dis = await fetchMabt(data?.numserie)
                setMABT([dis])
            }
        }
        fetchData()
    }, [data])

    return (
        <>
            <Modal maxWidth='xl' open={modalEditar} setOpen={setModalEditar} title='Configuración por MQTT' footer=' '>
                <Grid container className='mb-5'>
                    {MABTProject && !loading && MABT && (
                        <Grid item xs={12} className='container-modal-loader-mabt pl-5'>
                            <ConfiguracionPlantaMabt dispositivo={MABT} modal={true} />
                        </Grid>
                    )}
                    {!MABTProject && (
                        <ConfigCCmasterInstall
                            centroControl={true}
                            loadingTable={loadingTable}
                            loading={loading}
                            disabledTable={disabledTable}
                            setDisabledTable={setDisabledTable}
                            data={data}
                            dataInitial={dataInitialPmax}
                            setDataInitial={setDataInitialPmax}
                            setAnswer={setAnswer}
                            answer={answer}
                            activeTab={activeTab}
                            actualProject={actualProject}
                            dataEdit={dataEdit}
                            setDataEdit={setDataEdit}
                            configPlant={configPlant}
                            dataConfig={dataConfig}
                            setDataConfig={setDataConfig}
                            setOpen={setOpen}
                            disabled={disabled}
                            setDisabled={setDisabled}
                            setConfigPlant={setConfigPlant}
                            setActiveTab={setActiveTab}
                            dataConfigInitial={dataConfigInitial}
                            setDataConfigInitial={setDataConfigInitial}
                        />
                    )}
                </Grid>
            </Modal>
            {open && (
                <ModalConfigChangeInstall
                    centroControl={true}
                    setDisabledTable={setDisabledTable}
                    dataConfigInstall={data}
                    masterConfigInstall={masterConfigInstall}
                    dataInitialPmax={dataInitialPmax}
                    setDataInitialPmax={setDataInitialPmax}
                    setDisabled={setDisabled}
                    data={dataConfig}
                    dataPotMax={dataEdit}
                    setAnswer={setAnswer}
                    dataInitial={dataConfigInitial}
                    setDataInitial={setDataConfigInitial}
                    activeTab={activeTab}
                    open={open}
                    setOpen={setOpen}
                    idMaster={data?.numserie}
                />
            )}
        </>
    )
}
