import { useEffect } from 'react'

/**
 * Custom hook to set the document title dynamically based on a template and multiple values.
 * It replaces placeholders in the template with corresponding values if they are provided.
 * If any value is missing, the document title will fall back to the default title.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.template - The template for the document title, which can include placeholders like `{value1}`, `{value2}`, etc.
 *   For example, `template: 'Welcome, {name} - {page} - MyApp'` will set the title to "Welcome, John - Home - MyApp" if `name` is "John" and `page` is "Home".
 * @param {Object} params.values - An object containing values to replace the placeholders in the template.
 *   For example: `{ name: "John", page: "Home" }`
 * @param {string} params.defaultTitle - The title to use if any value is missing.
 */
const useDocumentTitle = ({ template, values, defaultTitle }) => {
    useEffect(() => {
        let updatedTitle = template

        // Check if any value is missing, and if so, use the default title
        const hasMissingValues = Object.values(values).some((value) => !value)

        if (hasMissingValues) {
            updatedTitle = defaultTitle
        } else {
            // Replace placeholders with corresponding values
            for (const [key, value] of Object.entries(values)) {
                updatedTitle = updatedTitle.replace(`{${key}}`, value)
            }
        }

        document.title = updatedTitle
    }, [template, values, defaultTitle])
}

export default useDocumentTitle
