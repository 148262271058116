import { LogoMinerva } from 'utils/icon-centrocontrol'
import './navbar.scss'

export default function Navbar({ children, className, companyLogos, logo = <LogoMinerva className='h-full' />, onClick }) {
    return (
        <nav className={`${className} navbar-style`} onClick={onClick}>
            <div className='logo-container pl-2'>{logo}</div>
            <div className='logos-container'>
                {companyLogos && companyLogos?.map((url, i) => <img src={url} key={i} className='image-container' alt='logo' />)}
            </div>
            <div className='items-container pr-2'>{children}</div>
        </nav>
    )
}
