import { createSlice } from '@reduxjs/toolkit'

export const dataSensorSlice = createSlice({
    name: 'dataSensor',
    initialState: [],
    reducers: {
        addDataSensor: (state, action) => {
            return action.payload
        }
    }
})

export const { addDataSensor } = dataSensorSlice.actions
export default dataSensorSlice.reducer
