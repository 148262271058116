import React, { useContext, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DragDropContext } from '@hello-pangea/dnd'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { FormControl, Grid, MenuItem } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { SelectStyled } from 'components/Select/SelectStyled'
import { GlobalContext } from 'context/GlobalContext'
import { createToast } from 'reduxSlice/toastSlice'
import { headersCentroControl } from 'services/headers'
import { urls } from 'utils/constant'
import { CategoryType } from 'utils/MiddleWareProjects'
import Column from './Column'
import './ModalMoveAllDevices.scss'

export const ModalMoveAllDevices = ({ open1, setOpen1 }) => {
    const dispatch = useDispatch()
    const token = sessionStorage?.token
    const { projectsCompleto, actualProject } = useContext(GlobalContext)
    const currentProject = projectsCompleto?.filter((elem) => elem?.id_project === actualProject?.id_project)

    const devices = []

    actualProject?.groups?.forEach((group) => {
        group?.devices?.forEach((device) => {
            devices.push({
                nick: device.nick,
                numserie: device.numserie
            })
        })
        group?.subgroups?.forEach((subgroup) => {
            subgroup?.devices?.forEach((device) => {
                devices.push({
                    nick: device.nick,
                    numserie: device.numserie
                })
            })
        })
    })

    const initialColumns = {
        devices: {
            id: 'devices',
            list: devices
        },
        moved: {
            id: 'moved',
            list: []
        }
    }

    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [grupos, setGrupos] = useState()
    const [groupSelected, setGroupSelected] = useState()
    const [subgrupos, setSubgrupos] = useState()
    const [subGroupSelected, setSubGroupSelected] = useState()
    const [selectedProject, setSelectedProject] = useState(currentProject[0]?.id_project || [])
    const [columns, setColumns] = useState(initialColumns)
    const [moveGroups, setMoveGroups] = useState()
    const [loading, setLoading] = useState(false)
    const [body, setBody] = useState()

    const movedNumseries = columns?.moved?.list?.map((device) => device?.numserie)
    const isThereSugroup = subgrupos?.length > 0

    const handleProject = (e) => {
        setSelectedProject(e.target.value)
        setGroupSelected()
        setSubGroupSelected()
        setSubgrupos()
    }

    const handleGroup = (e) => {
        setGroupSelected(e.target.value)
        setSubGroupSelected()
    }

    const hanleSubgroup = (e) => {
        setSubGroupSelected(e.target.value)
    }

    const fechGroupProject = (idproject) => {
        if (selectedProject) {
            setLoading(true)
            fetch(urls.ccontrol + `getInfoProjectIds/${idproject}`, { method: 'GET', headers: headersCentroControl(token) })
                .then((res) => res.json())
                .then((data) => {
                    setMoveGroups(data)
                })
                .catch((err) => console.error('ERROR. API Centro de control', err))
                .finally(() => setLoading(false))
        }
    }

    const move = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + `moveDevice`, {
            method: 'PATCH',
            headers: headersCentroControl(token),
            body: JSON.stringify(body)
        })
            .then((res) => {
                status = res.status
                return res.json()
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: data.message, reload: true }))
            })
            .catch((err) => console.error('ERROR. API Centro de control', err))
            .finally(() => {
                setOpen1(!open1)
                setLoading(false)
            })
    }

    const onDragEnd = (result) => {
        const { source, destination } = result
        // To make sure we have a valid destination
        if (!destination) return

        //To make sure we're actually moving the item
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return
        }

        // Set start and end variables
        const start = columns[source.droppableId]
        const end = columns[destination.droppableId]

        // If start is the same as end, we're in the same column
        if (start === end) {
            const newList = Array.from(start.list)
            newList.splice(source.index, 1)
            newList.splice(destination.index, 0, start.list[source.index])

            // Create a new copy of the column object
            const newCol = {
                id: start.id,
                list: newList
            }

            // Update the state
            setColumns((prevState) => ({ ...prevState, [newCol.id]: newCol }))
        } else {
            const newStartList = Array.from(start.list)
            newStartList.splice(source.index, 1)

            // Create a new start column
            const newStartCol = {
                id: start.id,
                list: newStartList
            }

            // Make a new end list array
            const newEndList = Array.from(end.list)

            // Insert the item into the end list
            newEndList.splice(destination.index, 0, start.list[source.index])

            const newEndCol = {
                id: end.id,
                list: newEndList
            }

            setColumns((prevState) => ({
                ...prevState,
                [newStartCol.id]: newStartCol,
                [newEndCol.id]: newEndCol
            }))
        }
    }

    const moveAllItemsToMoved = () => {
        const allItems = [...columns?.devices?.list, ...columns?.moved?.list]
        const newColumns = {
            devices: {
                id: 'devices',
                list: []
            },
            moved: {
                id: 'moved',
                list: allItems
            }
        }

        setColumns(newColumns)
    }

    const moveAllItemsToDevices = () => {
        const allItems = [...columns?.devices?.list, ...columns?.moved?.list]
        const newColumns = {
            devices: {
                id: 'devices',
                list: allItems
            },
            moved: {
                id: 'moved',
                list: []
            }
        }

        setColumns(newColumns)
    }

    useEffect(() => {
        setSelectedProject(currentProject[0]?.id_project)
        setColumns(initialColumns)
    }, [open1])

    useEffect(() => {
        if (selectedProject) {
            fechGroupProject(selectedProject)
        }
    }, [selectedProject])

    useEffect(() => {
        if (selectedProject && moveGroups) {
            setGrupos(moveGroups?.groups)
        }
        if (groupSelected) {
            grupos
                ?.filter((grup) => grup?.id_group === Number(groupSelected))
                ?.forEach((sub) => {
                    setSubgrupos(sub?.subgroups)
                })
        }
    }, [moveGroups, groupSelected])

    useEffect(() => {
        subGroupSelected && subGroupSelected > 0
            ? setBody({
                  id_project: parseInt(selectedProject),
                  id_group: parseInt(groupSelected),
                  id_subgroup: Number(subGroupSelected),
                  numserie: movedNumseries
              })
            : setBody({
                  id_project: parseInt(selectedProject),
                  id_group: parseInt(groupSelected),
                  numserie: movedNumseries
              })
    }, [subGroupSelected, groupSelected, columns])

    useEffect(() => {
        const selectedGroup = actualProject?.groups?.find((group) => group?.id_group === Number(groupSelected)) || {}
        const allDevicesInGroup = selectedGroup?.devices || []
        const allSubgroups = selectedGroup?.subgroups || []
        const isEmpty = columns?.moved?.list.length === 0

        let isDisabled = false

        if (isThereSugroup && allSubgroups.length > 0) {
            if (subGroupSelected) {
                // Verificar si los dispositivos movidos están en los subgrupos
                const allDevicesInSubgroups = allSubgroups.flatMap((subgroup) => subgroup.devices || [])
                const allMovedDevicesInSubgroups = columns?.moved?.list.every((device) =>
                    allDevicesInSubgroups?.some((subDevice) => subDevice.numserie === device.numserie)
                )

                isDisabled = allMovedDevicesInSubgroups
            } else {
                isDisabled = isEmpty
            }
        } else {
            // Verificar si los dispositivos movidos están en el grupo seleccionado
            const allMovedDevicesInGroup = columns?.moved?.list.every((device) =>
                allDevicesInGroup?.some((groupDevice) => groupDevice.numserie === device.numserie)
            )

            isDisabled = allMovedDevicesInGroup
        }

        if (!groupSelected || isDisabled) {
            setIsButtonDisabled(true)
        } else {
            setIsButtonDisabled(false)
        }
    }, [columns, groupSelected, actualProject, isThereSugroup])

    return (
        <Modal
            maxWidth='md'
            open={open1}
            setOpen={setOpen1}
            title='Mover dispostivos'
            buttonAction='Aceptar'
            functSave={move}
            loading={loading}
            disabled={isButtonDisabled}
        >
            <Grid container className='pr-5 pl-5'>
                <Grid
                    item
                    xs={12}
                    md={isThereSugroup ? 4 : 6}
                    lg={isThereSugroup ? 4 : 6}
                    xl={isThereSugroup ? 4 : 6}
                    className='inputs-container'
                >
                    <FormControl className='width-100 pb-2' size='small'>
                        <div className='pb-1'>
                            <small className='color-black'>Selecciona un proyecto:</small>
                        </div>
                        <SelectStyled value={selectedProject} onChange={handleProject}>
                            {projectsCompleto?.map((prj) => (
                                <MenuItem value={prj?.id_project} key={prj?.id_project}>
                                    <span className='flex align-items-center'>
                                        {prj?.nick}
                                        <p className='th-data ml-2'>{CategoryType(prj?.category)}</p>
                                    </span>
                                </MenuItem>
                            ))}
                        </SelectStyled>
                    </FormControl>
                </Grid>
                {moveGroups && selectedProject && grupos?.length > 0 && (
                    <Grid
                        item
                        xs={12}
                        md={isThereSugroup ? 4 : 6}
                        lg={isThereSugroup ? 4 : 6}
                        xl={isThereSugroup ? 4 : 6}
                        className='pb-2 inputs-container'
                    >
                        <FormControl className='width-100 pb-2' size='small'>
                            <div className='pb-1'>
                                <small className='color-black'>Selecciona un grupo:</small>
                            </div>
                            <SelectStyled value={groupSelected} onChange={handleGroup}>
                                {grupos?.map((grp) => {
                                    return (
                                        <MenuItem key={grp.id_group} value={grp.id_group}>
                                            |-{grp.nick}
                                        </MenuItem>
                                    )
                                })}
                            </SelectStyled>
                        </FormControl>
                    </Grid>
                )}
                {groupSelected && subgrupos?.length > 0 && (
                    <Grid item xs={12} md={4} lg={4} xl={4} className='pb-2 inputs-container'>
                        <FormControl className='width-100 pb-2' size='small'>
                            <div className='pb-1'>
                                <small className='color-black'>Selecciona un subgrupo:</small>
                            </div>
                            <SelectStyled value={subGroupSelected} onChange={hanleSubgroup}>
                                {subgrupos?.map((sub) => (
                                    <MenuItem key={sub.id_subgroup} value={sub.id_subgroup}>
                                        |-{sub.nick}
                                    </MenuItem>
                                ))}
                            </SelectStyled>
                        </FormControl>
                    </Grid>
                )}
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className='drag-container'>
                        <div className='styled-columns'>
                            {Object.values(columns).map((col) => (
                                <Column key={col.id} id={col.id} list={col.list} />
                            ))}
                        </div>
                        <div className='arrow-container'>
                            <ArrowBackIcon className='arrow-back-icon' onClick={moveAllItemsToDevices} />
                            <ArrowForwardIcon className='arrow-forward-icon' onClick={moveAllItemsToMoved} />
                        </div>
                    </div>
                </DragDropContext>
            </Grid>
        </Modal>
    )
}
