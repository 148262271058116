import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Grid } from '@mui/material'
import { PopUpInfo } from 'components/PopUp/PopUpInfo'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { GlobalContext } from 'context/GlobalContext'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { DataDevices } from 'utils/DataDevices/DataDevices'
import { DeviceDate } from 'utils/DeviceDate/DeviceDate'
import { ImageDevice } from 'utils/ImageDevice/ImageDevice'
import { OpenToScada } from 'utils/Utils'
import { DropDownDevices } from 'views/Devices/MenuDevices/DropDownDevices'

export const CcMWifi = ({ idProject, master, masterPermission, setDevices }) => {
    const token = sessionStorage.token
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const { actualProject } = useContext(GlobalContext)

    const fetchInfoCards = async () => {
        let url
        if ((master?.id_padre || master?.id_tipo_dispositivo === 0) && !master?.cloud) {
            url = `getInfoCardDevice/${master?.numserie}?idCCMaster=${master?.id_padre}`
        } else {
            url = `getInfoCardDevice/${master?.numserie}`
        }
        try {
            const res = await fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
            let json = await res.json()
            setLoading(false)
            setData(json)
        } catch (err) {
            setLoading(false)
            console.error(err)
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchInfoCards()
    }, [])

    return (
        <Grid item sm={6} xs={12} md={4} lg={3} xl={2} className='card-device-scada'>
            <div className='mx-2 card-style-prototype' style={{ opacity: master?.activo === 1 ? 1 : 0.6 }}>
                <Grid container spacing={0} columns={14}>
                    <Grid item xs={3} md={3} lg={3} xl={3}>
                        <Grid container spacing={0}>
                            <Grid item xs={3} md={3} lg={3} xl={3}>
                                <DropDownDevices
                                    device={master}
                                    masterPermission={masterPermission}
                                    active={master?.activo}
                                    instalacion={master}
                                    setDevices={setDevices}
                                />
                            </Grid>
                            <Grid item xs={9} md={9} lg={9} xl={9} className='pl-1' style={{ textAlign: 'center' }}>
                                <ImageDevice id_type_device={master?.id_tipo_dispositivo} name={master?.nick} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={10} md={10} lg={10} xl={10} className='pl-2'>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12} lg={12} xl={12} style={{ height: '25px' }}>
                                {master?.nick?.length > 9 ? (
                                    <HtmlTooltip title={<div className='rounded-top font-weight-bold'>{master?.nick}</div>}>
                                        <Button className='p-0'>
                                            <Link
                                                className={`p-0`}
                                                to='#'
                                                onMouseDown={(e) => {
                                                    if (e.button === 0 || e.button === 1) {
                                                        e.preventDefault()
                                                        window.open(
                                                            OpenToScada(
                                                                actualProject.category,
                                                                idProject,
                                                                master?.id_padre ? master?.id_padre : master?.numserie
                                                            )
                                                        )
                                                    }
                                                }}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <small style={{ fontWeight: 'bold' }}>
                                                    {master?.nick?.length > 9 ? master?.nick?.substr(0, 8) + '...' : master?.nick}
                                                </small>
                                            </Link>
                                        </Button>
                                    </HtmlTooltip>
                                ) : (
                                    <Link
                                        className={`p-0`}
                                        to='#'
                                        onMouseDown={(e) => {
                                            if (e.button === 0 || e.button === 1) {
                                                e.preventDefault()
                                                window.open(
                                                    OpenToScada(
                                                        actualProject.category,
                                                        idProject,
                                                        master?.id_padre ? master?.id_padre : master?.numserie
                                                    )
                                                )
                                            }
                                        }}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <small style={{ fontWeight: 'bold' }}>
                                            {master?.nick?.length > 9 ? master?.nick?.substr(0, 8) + '...' : master?.nick}
                                        </small>
                                    </Link>
                                )}
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} style={{ position: 'relative', top: '-8px' }}>
                                <DeviceDate
                                    device={master}
                                    data={data}
                                    loading={loading}
                                    masterPermission={masterPermission?.[master?.id_padre ? master.id_padre : master?.numserie]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} xl={1} className='text-align-center'>
                        <PopUpInfo instalation={master?.numserie} numserie={master?.numserie} nickdevice={master?.nick} />
                    </Grid>
                    <Grid item xs={14} md={14} lg={14} xl={14}>
                        <DataDevices device={master} data={data} />
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}
