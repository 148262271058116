import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { Modal } from 'components/Modal/Modal'
import { GlobalContext } from 'context/GlobalContext'
import { apiCentroControl } from 'services/api'
import { ProjectsListPremium } from 'views/Project/ProjectPremium/ProjectsListPremium'

export const ModalProjectList = ({ open, setOpen }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [projects, setProjects] = useState()
    const { setProjectsCompleto, setRefreshApp } = useContext(GlobalContext)

    const getCategoryID = (type) => {
        if (type === 'enerSIM') {
            return '1'
        } else if (type === 'generic') {
            return '0'
        } else if (type === 'energ_community') {
            return '2'
        } else if (type === 'shared') {
            return '6'
        }
    }

    const handleSelectedProject = (id, type) => {
        navigate(`/admin/inicio/${id}/${projects?.[0]?.category === 'generic' ? '2' : '0'}`)
        setRefreshApp((current) => !current)
        setOpen(false)
    }

    const sendAndChange = (project) => {
        if (getCategoryID(project?.category) === '1') {
            navigate(`/admin/inicio/${project?.id_project}/enerSIM`)
            setOpen(false)
        } else {
            handleSelectedProject(project?.id_project, getCategoryID(project?.category))
        }
        window.document.title = project?.nick
    }

    const fecthProjects = async () => {
        try {
            const response = await apiCentroControl.get('userProjects')

            if (response?.status === 200) {
                setProjectsCompleto(response?.data)
                setProjects(response?.data)
            }
        } catch (err) {
            console.error('ERROR. API Centro de Control', err)
        }
    }

    useEffect(() => {
        async function fetchData() {
            if (open) {
                setLoading(true)
                await fecthProjects()
                setLoading(false)
            }
        }
        fetchData()
    }, [open])

    return (
        <Modal maxWidth='lg' open={open} setOpen={setOpen} onClick={(e) => e.stopPropagation()} title='Listado de proyectos' footer=' '>
            {loading && projects ? (
                <Loader />
            ) : (
                <Grid container className='container-shared-cede-modal mb-5'>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <ProjectsListPremium modal={true} searchResults={projects} sendAndChange={sendAndChange} />
                    </Grid>
                </Grid>
            )}
        </Modal>
    )
}
