import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { Loader } from 'components/Loader/Loader'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { transformData } from 'utils/Utils'
import { CalendarCcMaster } from './Calendar/CalendarCcMaster'
import { ManualControl } from './ManualControl/ManualControl'
import { TableManualControl } from './ManualControl/TableManualControl'
import './CcMaster.scss'

export const OnOff = ({ isMobile, master }) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const idCcMaster = location.pathname.split('/')[4]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [updateCalendar, setUpdateCalendar] = useState(false)
    const [relay, setRelay] = useState([])
    const [activeTab, setActiveTab] = useState(0)
    const [loadingOn, setLoadingOn] = useState(true)
    const [loadingAlias, setLoadingAlias] = useState(false)
    const [index, setIndex] = useState(0)
    const [pulse, setPulse] = useState(false)
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [error, setError] = useState(false)

    const fetchCalendar = async () => {
        await fetch(urls.scada + `calendar/${idCcMaster}?idCCMaster=${idCcMaster}`, { method: 'GET', headers: headersScada(token) })
            .then((res) => {
                if (res.ok) {
                    return res.json()
                } else {
                    return []
                }
            })
            .then((data) => {
                setData(data)
            })
            .catch((err) => console.error('ERROR. API Scada', err))
    }

    const fetchRelay = async () => {
        setLoadingOn(true)
        await fetch(urls.scada + `calendarRelay/${idCcMaster}?idCCMaster=${idCcMaster}`, { method: 'GET', headers: headersScada(token) })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setRelay(transformData(data))
            })
            .catch((err) => console.error('ERROR. API Scada', err))
            .finally(() => setLoadingOn(false))
    }

    const reloadRelay = () => {
        setLoading(true)
        fetchCalendar()
        fetchRelay()
        setLoading(false)
    }

    const checkedInternal = (mode) => {
        if (mode === 103 || mode === 101) {
            return false
        } else {
            return true
        }
    }

    const fetchsCalendar = async (activeTab) => {
        setLoading(true)
        if (activeTab === 1) {
            await fetchCalendar()
        } else if (activeTab === 0) {
            await fetchRelay()
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchsCalendar(activeTab)
    }, [updateCalendar, activeTab])

    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12} className='menu-configuracion'>
                <div
                    onClick={() => {
                        setActiveTab(0)
                    }}
                >
                    <span className={activeTab === 0 ? 'list-item-icon active-button' : 'list-item-icon'}>Control manual</span>
                </div>
                <div
                    onClick={() => {
                        setActiveTab(1)
                    }}
                >
                    <span className={activeTab === 1 ? 'list-item-icon active-button' : 'list-item-icon'}>Programación</span>
                </div>
            </Grid>
            {!loading && relay?.length > 0 ? (
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    {activeTab === 0 && (
                        <div className={`tab-item-wrapper  ${activeTab === 0 ? 'active' : ''}`} index={0}>
                            <span className='flex justify-content-center align-items-center mb-5'>
                                <ManualControl
                                    loadingAlias={loadingAlias}
                                    error={error}
                                    setKeySwitch={setKeySwitch}
                                    pulse={pulse}
                                    checkedInternal={checkedInternal}
                                    index={index}
                                    loadingOn={loadingOn}
                                    data={relay}
                                    loading={loading}
                                    idCcMaster={idCcMaster}
                                    setLoadingOn={setLoadingOn}
                                />
                            </span>
                            <TableManualControl
                                pulse={pulse}
                                setLoadingAlias={setLoadingAlias}
                                error={error}
                                setError={setError}
                                setPulse={setPulse}
                                keySwitch={keySwitch}
                                setKeySwitch={setKeySwitch}
                                checkedInternal={checkedInternal}
                                loadingOn={loadingOn}
                                setLoadingOn={setLoadingOn}
                                index={index}
                                setIndex={setIndex}
                                idCcMaster={idCcMaster}
                                loading={loading}
                                setLoading={setLoading}
                                data={relay}
                                setUpdateCalendar={setUpdateCalendar}
                            />
                        </div>
                    )}
                    <div className={`tab-item-wrapper ${activeTab === 1 ? 'active' : ''}`} index={0}>
                        {activeTab === 1 && (
                            <CalendarCcMaster
                                relay={relay}
                                isMobile={isMobile}
                                idCcMaster={idCcMaster}
                                data={data}
                                setData={setData}
                                loading={loading}
                                setLoading={setLoading}
                                setUpdateCalendar={setUpdateCalendar}
                                updateCalendar={updateCalendar}
                                setRelay={setRelay}
                                master={master}
                            />
                        )}
                    </div>
                </Grid>
            ) : loading ? (
                <Grid item xs={12} className='flex justify-content-center align-items-center mt-3'>
                    <Loader loading={loading} />
                </Grid>
            ) : (
                <Grid item xs={12} className='flex justify-content-center align-items-center flex-column mt-6 mb-3'>
                    <h2>El máster no está conectado</h2>
                    <ButtonStyled onClick={() => reloadRelay()}>Intentar Conectar</ButtonStyled>
                </Grid>
            )}
        </Grid>
    )
}
