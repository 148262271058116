import React, { useEffect, useState } from 'react'
import { Grid, Typography, Menu } from '@mui/material'
import moment from 'moment'
import { ArrowRight, ArrowLeft } from 'utils/icon-centrocontrol'
import { obtenerNombreMes } from 'utils/Utils'
import { CalendarButton } from './CalendarButton'
import { CalendarDatePicker } from './CalendarDatePicker'
import { CalendarRowButtons } from './CalendarRowButtons'
import './CalendarComponent.scss'

//Props del componente
// className, Props para personalizar clases de CSS
// setStart,  Props para establecer el date en el padre
// setEnd, Props para establecer el endDate en el padre
export const CalendarComponent = ({ className, maxDate, setEnd, setStart, setDaily, setSelectDaily, enableButton, charge }) => {
    const today = moment()
    const startOfToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const endOfToday = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    const [anchorEl, setAnchorEl] = useState(null)
    const [date, setDate] = useState(startOfToday)
    const [endDate, setEndDate] = useState(endOfToday)
    const [isRange, setIsRange] = useState(false)
    const [dateType, setDateType] = useState('dia')
    const [rangeDate, setRangeDate] = useState(today)
    const [selectedButton, setSelectedButton] = useState('Hoy')
    const [first, setFirst] = useState(true)

    // Abre el menú
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    // Cierra el calendario y setea la fecha de hoy
    const handleClose = () => {
        setAnchorEl(null)
    }

    // Establece la fecha del primer calendario
    const handleChangeDate = (newValue) => {
        const startOfDay = moment().set({
            year: newValue.getFullYear(),
            month: newValue.getMonth(),
            date: newValue.getDate(),
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        })
        const endOfDay = moment().set({
            year: newValue.getFullYear(),
            month: newValue.getMonth(),
            date: newValue.getDate(),
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999
        })
        if (!isRange) {
            setDate(startOfDay)
            setEndDate(endOfDay)
            setAnchorEl(null)
        } else {
            setDate(startOfDay)
        }
        if (setDaily) {
            setDaily('days')
            setSelectDaily('day')
        }
        setDateType('dia')
        setSelectedButton('Hoy')
    }

    // Formatea la fecha
    const getDateFormat = (date) => {
        return moment(date).format('DD-MM-YYYY')
    }

    // Ir a la fecha de hoy
    const handleToday = () => {
        setDateType('dia')
        setIsRange(false)
        setSelectedButton('Hoy')
        setDate(startOfToday)
        setEndDate(endOfToday)
        setAnchorEl(null)

        if (setDaily) {
            setDaily('days')
            setSelectDaily('day')
        }
    }

    // Ir a la fecha de ayer
    const handleYesterday = () => {
        const startOfYesterday = moment(today).subtract(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        const endOfYesterday = moment(today).subtract(1, 'day').set({ hour: 23, minute: 59, second: 59, millisecond: 999 })

        setSelectedButton('Ayer')
        setDateType('dia')
        setIsRange(false)
        setDate(startOfYesterday)
        setEndDate(endOfYesterday)
        setAnchorEl(null)

        if (setDaily) {
            setDaily('days')
            setSelectDaily('day')
        }
    }

    // Resta un día a la fecha actual
    const subtractOneDay = () => {
        const newStartDate = moment(date).subtract(1, 'days')
        const newEndDate = moment(endDate).subtract(1, 'days')

        setDate(newStartDate)
        setEndDate(newEndDate)
    }

    // Suma un día a la fecha actual
    const addOneDay = () => {
        const newStartDate = moment(date).add(1, 'days')
        const newEndDate = moment(endDate).add(1, 'days')

        setDate(newStartDate)
        setEndDate(newEndDate)
    }

    // Ir al mes actual
    const handleMonth = () => {
        const startOfMonth = moment(today).startOf('month')
        const endOfMonth = moment(today).endOf('month')

        setDate(startOfMonth)
        setEndDate(endOfMonth)
        setAnchorEl(null)
        setDateType('mes')
        setIsRange(false)
        setSelectedButton('Mes')

        if (setDaily) {
            setDaily('months')
            setSelectDaily('month')
        }
    }

    // Ir al mes seleccionado
    const handleSelectedMonth = () => {
        const startOfMonth = moment(date).startOf('month')
        const endOfMonth = moment(date).endOf('month')

        setDate(startOfMonth)
        setEndDate(endOfMonth)
        setDateType('mes')
        setAnchorEl(null)
        setSelectedButton('Mes')

        if (setDaily) {
            setDaily('months')
            setSelectDaily('month')
        }
    }

    // Restar un mes
    const subtractOneMonth = () => {
        const newDate = moment(date).subtract(1, 'months')
        const startOfNewMonth = moment(newDate).startOf('month')
        const endOfNewMonth = moment(newDate).endOf('month')
        if (!isRange) {
            setDate(startOfNewMonth)
            setEndDate(endOfNewMonth)
        } else {
            setDate(startOfNewMonth)
        }
    }

    // Sumar un mes
    const addOneMonth = () => {
        const newDate = moment(date).add(1, 'months')
        const startOfNewMonth = moment(newDate).startOf('month')
        const endOfNewMonth = moment(newDate).endOf('month')
        if (!isRange) {
            setDate(startOfNewMonth)
            setEndDate(endOfNewMonth)
        } else {
            setDate(startOfNewMonth)
        }
    }

    // Ir al año actual
    const handleYear = () => {
        const startOfYear = moment(today).startOf('year')
        const endOfYear = moment(today).endOf('year')

        setDate(startOfYear)
        setEndDate(endOfYear)
        setIsRange(false)
        setAnchorEl(null)
        setDateType('año')
        setSelectedButton('Año')

        if (setDaily) {
            setDaily('years')
            setSelectDaily('year')
        }
    }

    // Ir al año seleccionado
    const handleSelectedYear = () => {
        const startOfYear = moment(date).startOf('year')
        const endOfYear = moment(date).endOf('year')

        setDate(startOfYear)
        setEndDate(endOfYear)
        setDateType('año')
        setAnchorEl(null)
        setSelectedButton('Año')

        if (setDaily) {
            setSelectDaily('year')
            setDaily('years')
        }
    }

    // Sumar un año a la fecha seleccionada
    const addOneYear = () => {
        const newYear = moment(date).add(1, 'year')
        const newEndYear = moment(endDate).add(1, 'year')
        if (!isRange) {
            setDate(newYear)
            setEndDate(newEndYear)
        } else {
            setDate(newYear)
        }
    }

    // Restar un año a la fecha seleccionada
    const subtractOneYear = () => {
        const newYear = moment(date).subtract(1, 'year')
        const newEndYear = moment(endDate).subtract(1, 'year')
        if (!isRange) {
            setDate(newYear)
            setEndDate(newEndYear)
        } else {
            setDate(newYear)
        }
    }

    // Alterna la visibilidad del segundo calendario cuando se hace clic en el botón "Rango"
    const handleRangeToggle = () => {
        setIsRange(!isRange)
        setSelectedButton('Rango')
    }

    // Establece la fecha del segundo calendario
    const handleSetRange = (newValue) => {
        const endOfDay = moment().set({
            year: newValue.getFullYear(),
            month: newValue.getMonth(),
            date: newValue.getDate(),
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999
        })
        setRangeDate(endOfDay)
        setEndDate(endOfDay)
        if (setDaily) {
            setDaily('days')
            setSelectDaily('day')
        }
    }

    //Restar un año al segundo calendario
    const subtractOneYearForRange = () => {
        const newRangeDate = moment(rangeDate).subtract(1, 'year')
        setRangeDate(newRangeDate)
        setEndDate(newRangeDate)
        setSelectDaily('year')
    }

    //Sumar un año al segundo calendario
    const addOneYearForRange = () => {
        const newRangeDate = moment(rangeDate).add(1, 'year')
        setRangeDate(newRangeDate)
        setEndDate(newRangeDate)
        setSelectDaily('year')
    }

    //Restar un mes al segundo calendario
    const subtractOneMonthForRange = () => {
        const newRangeDate = moment(rangeDate).subtract(1, 'months')
        setRangeDate(newRangeDate)
        setEndDate(newRangeDate)
        setSelectDaily('month')
    }

    //Sumar un mes al segundo calendario
    const addOneMonthForRange = () => {
        const newRangeDate = moment(rangeDate).add(1, 'months')
        setRangeDate(newRangeDate)
        setEndDate(newRangeDate)
        setSelectDaily('month')
    }

    const leftArrowCalendar = () => {
        switch (dateType) {
            case 'dia':
                subtractOneDay()
                setSelectDaily('day')

                break
            case 'mes':
                subtractOneMonth()
                setSelectDaily('month')

                break
            case 'año':
                subtractOneYear()
                setSelectDaily('year')
                break
            default:
                break
        }
    }

    const rightArrowCalendar = () => {
        switch (dateType) {
            case 'dia':
                addOneDay()
                setSelectDaily('day')
                break
            case 'mes':
                addOneMonth()
                setSelectDaily('month')
                break
            case 'año':
                addOneYear()
                setSelectDaily('year')
                break
            default:
                break
        }
    }

    const showDateCalendar = () => {
        switch (dateType) {
            case 'dia':
                return getDateFormat(date)
            case 'mes':
                return `${obtenerNombreMes(date.month() + 1)} - ${date.year()}`
            case 'año':
                return date.year()
            default:
                break
        }
    }

    useEffect(() => {
        if (!first || charge === undefined) {
            setStart(date)
            setEnd(endDate)
        }
        setFirst(false)
    }, [date, endDate])

    return (
        <Grid className='flex-grow calendar-filter-card-alarms'>
            <div className={`${className} calendar-filter`}>
                <CalendarButton onClick={leftArrowCalendar} className='arrow-calendar' disabled={isRange}>
                    <ArrowLeft />
                </CalendarButton>
                <CalendarButton>
                    <Typography textAlign='center' onClick={handleMenu} className='pr-2 pl-2 typography-main-btn'>
                        {isRange && rangeDate ? `${getDateFormat(date)} - ${getDateFormat(rangeDate)}` : showDateCalendar()}
                    </Typography>
                </CalendarButton>
                <CalendarButton
                    onClick={rightArrowCalendar}
                    className='arrow-calendar'
                    enableButton={enableButton}
                    disabled={
                        !enableButton &&
                        ((dateType === 'dia' && moment(date).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) ||
                            (dateType === 'mes' && moment(date).format('MM-YYYY') === moment().format('MM-YYYY')) ||
                            (dateType === 'año' && moment(date).year() === moment().year()) ||
                            isRange)
                    }
                >
                    <ArrowRight />
                </CalendarButton>
            </div>
            <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Grid container justifyContent='center' className='pr-3 pl-3'>
                    {/* Primer calendario*/}
                    <CalendarDatePicker
                        isRange={isRange}
                        onClickArrowLeftFirstRow={() => subtractOneYear()}
                        onClickTitleFirstRow={handleSelectedYear}
                        titleFirstRow={date && date.year()}
                        onClickArrowRightFirstRow={() => addOneYear()}
                        onClickArrowLeftSecondRow={() => subtractOneMonth()}
                        titleSecondRow={date && `${obtenerNombreMes(date.month() + 1)}`}
                        onClickTitleSecondRow={handleSelectedMonth}
                        onClickArrowRightSecondRow={() => addOneMonth()}
                        value={date._d}
                        onChange={handleChangeDate}
                        maxDate={maxDate ? today._d : ''}
                    />
                    {/* Segundo calendario */}
                    {isRange && (
                        <CalendarDatePicker
                            isRange={isRange}
                            onClickArrowLeftFirstRow={() => subtractOneYearForRange()}
                            titleFirstRow={rangeDate && rangeDate.year()}
                            onClickTitleFirstRow={handleSelectedYear}
                            onClickArrowRightFirstRow={() => addOneYearForRange()}
                            onClickArrowLeftSecondRow={() => subtractOneMonthForRange()}
                            titleSecondRow={rangeDate && `${obtenerNombreMes(rangeDate?.month() + 1)}`}
                            onClickTitleSecondRow={handleSelectedMonth}
                            onClickArrowRightSecondRow={() => addOneMonthForRange()}
                            value={rangeDate._d}
                            onChange={handleSetRange}
                            maxDate={maxDate ? today._d : ''}
                        />
                    )}
                </Grid>
                {/* Mensaje de error*/}
                {isRange && date > endDate && <div className='message-error'>La fecha inicial no puede ser posterior a la final.</div>}
                {/* Botones con opciones */}
                <CalendarRowButtons
                    selectedButton={selectedButton}
                    handleToday={handleToday}
                    handleYesterday={handleYesterday}
                    handleMonth={handleMonth}
                    handleYear={handleYear}
                    handleRangeToggle={handleRangeToggle}
                    handleClose={handleClose}
                />
            </Menu>
        </Grid>
    )
}

export default CalendarComponent
