import React, { useState } from 'react'
import { Button, Menu } from '@mui/material'
import { IconMenu } from 'utils/icon-centrocontrol'
import { PermissionsForMenuSubgroup } from 'views/Menu/PermissionsforMenu.jsx'

export const DropdownSubgroup = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        event.stopPropagation()
    }

    const handleClose = () => setAnchorEl(null)

    return (
        <>
            {props?.groups?.permissions !== 'user' && (
                <>
                    <Button
                        className='btn-square'
                        onClick={handleClick}
                        style={{ backgroundColor: 'transparent', border: '0px solid transparent' }}
                    >
                        <span>{props.showInstalations ? <IconMenu color='white' /> : <IconMenu />}</span>
                    </Button>
                    <Menu
                        id='simple-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        classes={{ list: 'p-0' }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <PermissionsForMenuSubgroup
                            group={props?.groups}
                            project={props?.projects}
                            subgroup={props?.subgroups}
                            permissions={props?.subgroups?.permissions}
                            handleClose={handleClose}
                            loading={props?.loading}
                            select={'id_subgroup'}
                            setUpdateGroups={props?.setResultSearch}
                            idGroupSelected={props?.subgroups.id_subgroup}
                            projects={props?.resultSearch}
                            setProjects={props.setProjects}
                            groupId={props?.subgroups?.id_subgroup}
                        />
                    </Menu>
                </>
            )}
        </>
    )
}
