import React from 'react'
import { LoadingButton } from '@mui/lab'
import { capitalize } from 'utils/Capitalize'
import { Button } from '@mui/material'

export const ButtonStyledIcon = ({
    size,
    type,
    onClick,
    children,
    style,
    className,
    fullWidth,
    disabled,
    backgroundColor,
    value,
    title,
    loading,
    onMouseOver,
    onMouseOut,
    onMouseEnter,
    onMouseLeave,
    padding
}) => {
    return (
        <>
            {loading === undefined ? (
                <Button
                    sx={{
                        background: '#FFFFFF',
                        border: '1px solid #7B7A7B',
                        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
                        borderRadius: '3px',
                        padding: padding ? padding : '6px 6px !important',
                        '&:hover': {
                            background: backgroundColor ? backgroundColor + ' !important' : '#FF8A1F !important'
                        }
                    }}
                    className={`${className}`}
                    type={type}
                    size={size}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    fullWidth={fullWidth}
                    title={title}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    style={style}
                    disabled={disabled}
                    variant='contained'
                    // setTokenRecaptcha={setTokenRecaptcha}
                    value={value}
                >
                    {typeof children === 'string' ? capitalize(children) : children}
                </Button>
            ) : (
                <LoadingButton
                    sx={{
                        background: '#FFFFFF',
                        border: '1px solid #7B7A7B',
                        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
                        borderRadius: '3px',
                        padding: padding ? padding : '6px 6px !important',
                        '&:hover': {
                            background: backgroundColor ? backgroundColor + ' !important' : '#FF8A1F !important'
                        }
                    }}
                    loadingIndicator='Cargando…'
                    loading={loading}
                    className={`${className}`}
                    type={type}
                    size={size}
                    onClick={onClick}
                    fullWidth={fullWidth}
                    title={title}
                    style={style}
                    disabled={disabled}
                    variant='contained'
                    value={value}
                >
                    {typeof children === 'string' ? capitalize(children) : children}
                </LoadingButton>
            )}
        </>
    )
}
