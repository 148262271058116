import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersCentroControl } from 'services/headers'
import { urls } from 'utils/constant'
import { validatorEmail } from 'utils/ValidatorForm'

/**
 * @param {groups {}, menuDrop: boolean} Recibimos datos del grupo
 * @param Hacemos una llamada a validateAction con la acción que nos dpregunta a quien le cedemos, que le cedemos
 * @returns Retornamos un modal que nos permite introducir el mail de la persona a ceder
 */
export const ModalCedeGroup = ({ groups, modal, setModal, toggle }) => {
    const token = sessionStorage?.token
    const [email, setEmail] = useState('')
    const dispatch = useDispatch()
    const [disabled, setDisabled] = useState(true)

    const [loading, setLoading] = useState(false)
    const [isValid, setIsValid] = useState(true)

    //Fetch para compartir Grupo
    const cedeGroup = () => {
        setLoading(true)
        let status
        if (email && email.length > 0 && isValid === true) {
            fetch(urls.ccontrol + `validateAction/group`, {
                method: 'PATCH',
                redirect: 'follow',
                headers: headersCentroControl(token),
                body: JSON.stringify({
                    id_group: groups.id_group,
                    address: email,
                    action: 'cede',
                    nick: groups?.nick
                })
            })
                .then((res) => {
                    setLoading(false)
                    status = res.status
                    return res.json()
                })
                .then((data) => {
                    dispatch(createToast({ status: status, message: data.message, reload: true }))
                })
                .catch((err) => console.error('ERROR. API Centro de Control', err))
        }
        setDisabled(true)
        setLoading(false)
        setModal(false)
    }

    const validateEmail = () => {
        setIsValid(validatorEmail(email))
    }

    const handleBlur = () => {
        if (email.length > 0) {
            validateEmail()
        }
        if (!email) {
            setIsValid(true)
        }
    }

    useEffect(() => {
        if (isValid === true && email.length > 0) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [isValid, email])

    useEffect(() => {
        setEmail('')
        setIsValid(true)
    }, [setModal, modal])

    return (
        <>
            <Modal
                onClick={(e) => e.stopPropagation()}
                maxWidth='sm'
                open={modal}
                setOpen={setModal}
                title={`Ceder grupo "${groups?.nick}"`}
                loading={loading}
                functSave={cedeGroup}
                buttonAction='Ceder'
                disabled={disabled}
            >
                <Grid container className='pr-5 pl-5'>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className='color-black'>Email de usuario:</small>
                        </div>
                        <InputStyled
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e)}
                            onBlur={handleBlur}
                            error={!isValid}
                            errorMessage={'Email inválido'}
                            maxLength={50}
                        />
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
