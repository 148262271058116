import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Loader } from 'components/Loader/Loader'
import MapShow from 'components/Map/MapShow'
import Map from 'components/Mapa/MapShow'
import { apiCentroControl } from 'services/api'
import { OpenToScada } from 'utils/Utils'
import getPlaces from './utils/getPlaces'
import './StyleMap.scss'

const MapCentroControl = ({ idMaster, active, actualProject }) => {
    const location = useLocation()
    const idP = location?.pathname.split('/')[3]
    const [places, setPlaces] = useState([])
    const [loading, setLoading] = useState(false)
    const [firstLoading, setFirstLoading] = useState(true)
    const tree = useSelector((state) => state.treeDevices.tree)
    const errors = useSelector((state) => state.infoInstal)
    const linkScada = (numSerie) => {
        const url = OpenToScada(actualProject.category, idP, numSerie)
        window.open(url)
    }

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await apiCentroControl.get(`getMap/${idP}`)
                if (result.status === 200) {
                    const mapData = result?.data
                    const installSelect = Object?.entries(tree)
                        ?.filter(([_, valor]) => valor === true)
                        ?.map(([clave, _]) => clave?.substring(clave?.lastIndexOf('-') + 1))
                    const clavesModificadas = installSelect?.map((clave) => clave?.substring(clave?.lastIndexOf('-') + 1))
                    setPlaces(
                        getPlaces(
                            mapData.filter((data) => errors?.[data?.numserie]?.error !== 1),
                            clavesModificadas
                        )
                    )
                }
            } catch (err) {
                setPlaces([])
                console.error('ERROR. API Centro de Control', err)
            } finally {
                setLoading(false)
                setFirstLoading(false)
            }
        }
        if (active) {
            setLoading(true)
            getData()
        }
    }, [active, tree])

    return (
        <>
            {loading && (actualProject?.category !== 4 || firstLoading) && <Loader />}
            {active && actualProject?.category === 4 && !firstLoading && <MapShow places={places} />}
            {places && !loading && (
                <Map
                    idMaster={idMaster}
                    places={places?.filter((place) => place?.selected === true)}
                    zoom={5}
                    className={`${active ? '' : 'display-none'} ${
                        actualProject?.category === 4 ? 'display-none' : ''
                    } mapaConfiguracionFullHeight`}
                    onClickMarker={(numSerie) => linkScada(numSerie)}
                />
            )}
        </>
    )
}
export default MapCentroControl
