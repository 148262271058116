import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SpeedIcon from '@mui/icons-material/Speed'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { apiCentroControl } from 'services/api'
import { validatorInput } from 'utils/ValidatorForm'
import { FormEditSensor } from '../Forms/FormEditSensor'
import { generateBodyEdit, haveChanges, updateSensor } from '../UtilsSensor'

export const ModalEditSensor = ({ open, setOpen, sensor, medidores, fetchGetAllSensors, dispositivo }) => {
    const [body, setBody] = useState({ ...sensor })
    const [loading, setLoading] = useState(false)
    const [positions, setPositions] = useState([])
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()

    const validateBody = () => {
        let errorsBody = {}
        if (validatorInput(body?.nick)) {
            errorsBody.nick = 'Caracteres no permitidos'
        } else if (body?.nick?.length === 0) {
            errorsBody.nick = 'Este campo es obligatorio'
        }
        if (body?.position === -1) {
            errorsBody.position = 'Este campo es obligatorio'
        }
        if (body?.lat === '' || body?.lng === '' || body?.address === '') {
            errorsBody.address = 'Este campo es obligatorio'
        }
        setErrors(errorsBody)
    }

    const fetchGetAllPositionsMed = async (numserie) => {
        try {
            const res = await apiCentroControl.post('sensorPosition', { numserie: numserie })
            if (res?.status === 200) {
                const data = res?.data
                if (sensor?.medidor_asociado === numserie) {
                    data?.push(sensor?.position)
                }
                data?.sort((a, b) => a - b)
                setPositions(res?.data)
                if (data?.length === 0) {
                    let errorsBody = {}
                    errorsBody.position = 'No hay posiciones disponibles para esta salida'
                    setErrors(errorsBody)
                }
            }
        } catch (err) {
            let errorsBody = {}
            errorsBody.position = 'No se han podido obtener las posiciones disponibles del medidor'
            setBody({ ...body, position: -1 })
            setErrors(errorsBody)
            console.error('ERROR. API Centro Control')
        }
    }

    const fetchEditSensor = async () => {
        setLoading(true)
        const bodyEdit = generateBodyEdit(sensor, body)
        try {
            const result = await apiCentroControl.patch('updateLineSensor', bodyEdit)
            if (result.status === 200) {
                setOpen(false)
                fetchGetAllSensors()
                if (bodyEdit?.numserie_medidor) {
                    updateSensor(sensor?.medidor_asociado, bodyEdit?.numserie_medidor, sensor?.numserie, dispositivo)
                }
                dispatch(createToast({ status: result.status, message: 'Sensor actualizado correctamente', reload: false }))
            }
        } catch (err) {
            dispatch(createToast({ status: 400, message: 'Ha habido un fallo, por favor espere y vuélvalo a intentar', reload: false }))
            console.error('ERROR. API Centro Control')
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchGetAllPositionsMed(body?.medidor_asociado)
    }, [])

    useEffect(() => {
        validateBody()
    }, [body])

    return (
        <Modal
            disabled={Object?.keys(errors)?.length !== 0 || !haveChanges(sensor, body)}
            fullWidth={true}
            open={open}
            setOpen={setOpen}
            title={`Editar Sensor ${sensor?.nick ?? 'Sin nombre'}`}
            loading={loading}
            buttonAction='Aceptar'
            iconTitle={<SpeedIcon />}
            functSave={fetchEditSensor}
        >
            <Grid container className='pr-5 pl-5 pt-3'>
                <FormEditSensor
                    body={body}
                    medidores={medidores}
                    setBody={setBody}
                    errors={errors}
                    setErrors={setErrors}
                    positions={positions}
                    fetchGetAllPositionsMed={fetchGetAllPositionsMed}
                />
            </Grid>
        </Modal>
    )
}
