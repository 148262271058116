import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { Group } from './MenuDevice/Group'
import { OrderSelect } from './OrderSelect/OrderSelect'
import { Seeker } from './Seeker/Seeker'
import './StylePrincipal.scss'

export const Principal = ({
    actualProject,
    selectOneInstallation,
    setSelectOneInstallation,
    data,
    setActiveTab,
    numserieError,
    activeTab
}) => {
    const [orderBy, setOrderBy] = useState('nick')
    const [visible, setVisible] = useState(false)
    actualProject?.groups?.sort((a, b) => a?.nick?.localeCompare(b?.nick))

    return (
        <div className='principal-device'>
            <Grid container className='header-principal-visible principal-header-selected'>
                <Grid item xs={12} lg={4}>
                    <OrderSelect orderBy={orderBy} setOrderBy={setOrderBy} />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Seeker
                        searchResults={actualProject?.groups}
                        setSelectOneInstallation={setSelectOneInstallation}
                        selectOneInstallation={selectOneInstallation}
                        setVisible={setVisible}
                    />
                </Grid>
                <Grid item xs={12} lg={4} />
                <Grid item xs={12} lg={8}>
                    <span className='error-search-principal'>
                        {visible && (
                            <small style={{ color: 'red', alignItems: 'center' }}>
                                No encontramos instalaciones con ese parámetro de búsqueda
                            </small>
                        )}
                    </span>
                </Grid>
            </Grid>
            {actualProject?.groups
                ?.sort((a, b) => a?.nick?.localeCompare(b?.nick))
                ?.map((group, index) => (
                    <Group
                        activeTab={activeTab}
                        numserieError={numserieError}
                        key={'keyGroup' + index}
                        orderBy={orderBy}
                        index={index}
                        actualProject={actualProject}
                        group={group}
                        data={data}
                        setActiveTab={setActiveTab}
                    />
                ))}
        </div>
    )
}
export default Principal
