import { alarmas } from 'utils/constant'

export const getUrlAlarm = (type) => {
    switch (type) {
        case alarmas.tramo:
            return 'createSectionAlarm'
        default:
            return 'createAlarm'
    }
}
