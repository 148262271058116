import React, { useEffect, useRef } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { AuthFooter } from 'views/Footer/AuthFooter'
import { CedeConfirm } from 'views/Share/CedeConfirm'
import { ShareConfirm } from 'views/Share/ShareConfirm'
import { Register } from 'views/User/CreateUser/Register'
import { Login } from 'views/User/Login/Login.jsx'
import { VerifyToken } from 'views/User/RememberPassword/VerifyToken'
import { VerifyProfile } from 'views/User/Verify/Verify'

const Auth = () => {
    const history = useLocation()
    const mainContent = useRef(null)

    useEffect(() => {
        document.body.classList.add('bg-default')
        return () => {
            document.body.classList.remove('bg-default')
        }
    }, [])

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        mainContent.current.scrollTop = 0
    }, [history])

    return (
        <Grid container className='auth-container' ref={mainContent}>
            <Grid item xl={5} lg={8} md={10} xs={10} className='main-content auth-content'>
                <Routes>
                    <Route path='login' element={<Login />} />
                    <Route path='registro' element={<Register />} />
                    <Route path='cede/enersim' element={<CedeConfirm />} />
                    <Route path='cedeDevice' element={<CedeConfirm />} />
                    <Route path='/share/project/*' element={<ShareConfirm />} />
                    <Route path='/share/group/*' element={<ShareConfirm />} />
                    <Route path='/share/subgroup/*' element={<ShareConfirm />} />
                    <Route path='/share/device/*' element={<ShareConfirm />} />
                    <Route path='/cede/project/*' element={<CedeConfirm />} />
                    <Route path='/cede/group/*' element={<CedeConfirm />} />
                    <Route path='/cede/subgroup/*' element={<CedeConfirm />} />
                    <Route path='/cede/device/*' element={<CedeConfirm />} />
                    <Route path='/verify/*' element={<VerifyProfile />} />
                    <Route path='/recoverpass/*' element={<VerifyToken />} />
                    <Route path='*' element={<Navigate to='/auth/login' replace />} />
                </Routes>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12} className='footer'>
                <AuthFooter />
            </Grid>
        </Grid>
    )
}

export default Auth
