import React, { useState, useEffect } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { Grid } from '@mui/material'
import { ClearIcon } from '@mui/x-date-pickers'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { toLatLon } from 'utm'
import InputStyled from 'components/Input/InputStyled'
import { isUTM, isLatLngString, getUTMCoordinates } from 'utils/Utils'
import './styleMap.scss'

const MapAutocomplete = ({ emptyMap, onPlaceChanged, place, adres, setAddress, className }) => {
    const getAddressFromLatLng = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder()
        const latLngObj = new window.google.maps.LatLng(lat, lng)
        geocoder?.geocode({ location: latLngObj }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    setAddress(results[0]?.formatted_address)
                }
            } else {
                console.error('Geocoding failed:', status)
            }
        })
    }

    const handleSelect = async (selected) => {
        try {
            const results = await geocodeByAddress(selected)
            const latLng = await getLatLng(results[0])
            const place = { address: results[0]?.formatted_address, placeId: results[0]?.place_id, lat: latLng?.lat, lng: latLng?.lng }
            onPlaceChanged(place, results[0]?.formatted_address)
        } catch (error) {
            console.error('Error selecting place:', error)
        }
    }

    useEffect(() => {
        if (place && place.lat && place.lng) {
            getAddressFromLatLng(place.lat, place.lng)
        }
    }, [place])

    return (
        <div className={`${className} autocomplete-container`}>
            <PlacesAutocomplete value={adres} onChange={setAddress} onSelect={handleSelect}>
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div>
                        <input
                            className='autocomplete-selector'
                            {...getInputProps({ placeholder: adres && adres?.length > 0 ? adres : 'Escriba la dirección' })}
                        />
                        <div>
                            {suggestions?.map((suggestion) => (
                                <div {...getSuggestionItemProps(suggestion)}>{suggestion?.description}</div>
                            ))}
                        </div>
                        {adres && <ClearIcon className='clear-icon' onClick={emptyMap} />}
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    )
}

const Map = ({ center, zoom, onSelectMarker, address, utmShow, classNameInput, messageError }) => {
    const [adres, setAddress] = useState(address)
    const [places, setPlaces] = useState(center?.lat && center?.lng ? { lat: center?.lat, lng: center?.lng } : undefined)
    const [utm, setUtm] = useState(center?.lat && center?.lng ? getUTMCoordinates(center?.lat, center?.lng) : undefined)
    const [aux, setAux] = useState()
    const [keyUtm, setKeyUtm] = useState('keyUtm')

    const getAddressFromLatLng = async (lat, lng, place) => {
        const geocoder = new window.google.maps.Geocoder()
        const latLngObj = new window.google.maps.LatLng(lat, lng)

        return new Promise((resolve, reject) => {
            geocoder.geocode({ location: latLngObj }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        setAddress(results[0]?.formatted_address)
                        showPlace(place, results[0]?.formatted_address)
                        resolve()
                    }
                } else {
                    console.error('Geocoding failed:', status)
                    reject(status)
                }
            })
        })
    }

    const showPlace = (place, adr) => {
        setPlaces(place)
        setUtm(getUTMCoordinates(place?.lat, place?.lng))
        onSelectMarker(place?.lat, place?.lng, adr)
    }

    const getLatLngFromUTM = (utm) => {
        const coordinates = utm.split(' ')
        const easting = parseFloat(coordinates[0])
        const northing = parseFloat(coordinates[1])
        const zoneNum = parseInt(coordinates[2].slice(0, -1))
        const zoneLetter = coordinates[2].slice(-1)
        const { latitude, longitude } = toLatLon(easting, northing, zoneNum, zoneLetter)
        return { lat: latitude, lng: longitude }
    }

    const handleMapClick = async (event) => {
        const lat = event?.latLng?.lat()
        const lng = event?.latLng?.lng()
        const newMarker = { lat, lng }
        await getAddressFromLatLng(lat, lng, newMarker)
    }

    const guardarInstalacion = async () => {
        setUtm(aux)
        let obj
        if (isLatLngString(aux)) {
            obj = aux.split(' ')
        }
        const place = isUTM(aux) ? getLatLngFromUTM(aux) : { lat: Number(obj[0]), lng: Number(obj[1]) }
        await getAddressFromLatLng(place?.lat, place?.lng, place)
    }

    const handleCancelarInstalacion = () => {
        setAux(utm)
    }

    const emptyMap = () => {
        onSelectMarker(null, null, '')
        setPlaces()
        setUtm()
        setAux()
        setAddress('')
        setKeyUtm(address)
    }

    useEffect(() => {
        setAux(utm)
    }, [utm])

    const mapStyles = {
        height: '100%',
        width: '100%'
    }

    return (
        <Grid container>
            <Grid
                item
                xs={utmShow ? 11 : 12}
                md={utmShow ? 11 : 12}
                lg={utmShow ? 5 : 12}
                xl={utmShow ? 5 : 12}
                className={!messageError && 'mb-3'}
            >
                {utmShow && <h4 style={{ fontWeight: 500 }}>Dirección:</h4>}
                <MapAutocomplete
                    emptyMap={emptyMap}
                    adres={adres}
                    onPlaceChanged={showPlace}
                    place={places}
                    center={center}
                    setAddress={setAddress}
                    className={classNameInput}
                />
            </Grid>
            {utmShow && (
                <>
                    <Grid item xs={1} md={1} lg={1} xl={1} />
                    <Grid item xs={12} md={12} lg={6} xl={6} className='mb-3'>
                        <h4 style={{ fontWeight: 500 }}>UTM:</h4>
                        <InputStyled
                            key={keyUtm}
                            classname='inputTextoConfiguracionMabt-edit'
                            enableEdit={true}
                            value={aux}
                            onChange={setAux}
                            onSave={guardarInstalacion}
                            onCancelar={handleCancelarInstalacion}
                            error={aux && !isLatLngString(aux) && !isUTM(aux)}
                            errorMessage='Los datos introducidos no corresponden a ningún tipo de coordenada'
                        />
                    </Grid>
                </>
            )}
            {messageError && messageError}
            <Grid
                item
                xs={utmShow ? 11 : 12}
                md={utmShow ? 11 : 12}
                lg={utmShow ? 11 : 12}
                xl={utmShow ? 11 : 12}
                className='mapaConfiguracion-container'
            >
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={places ? 12 : zoom}
                    center={{ lat: center?.lat ? center.lat : 40.4167754, lng: center?.lng ? center.lng : -3.7037902 }}
                    onClick={handleMapClick}
                >
                    {places && <Marker key='marca' position={{ lat: places?.lat, lng: places?.lng }} />}
                </GoogleMap>
            </Grid>
        </Grid>
    )
}

export default Map
