import { createSlice } from '@reduxjs/toolkit'

export const sensorSlice = createSlice({
    name: 'sensor',
    initialState: [],
    reducers: {
        addSensor: (state, action) => {
            return action.payload
        }
    }
})

export const { addSensor } = sensorSlice.actions
export default sensorSlice.reducer
