import React, { useContext, useEffect, useState } from 'react'
import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import moment from 'moment'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import CalendarComponent from 'components/Calendar/CalendarComponent'
import { Loader } from 'components/Loader/Loader'
import { StyledTableCellOrdenado, StyledTableCellHead, StyledTableCell, StyledTableRow } from 'components/Table/TableStyle'
import { GlobalContext } from 'context/GlobalContext'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { IconData } from 'utils/icon-centrocontrol'
import { ActionHistoricalCalendar, formatDate } from 'utils/Utils'
import './StyleCalendar.scss'
import { ModalHistoricalInfoCalendar } from './Modal/ModalHistoricalInfoCalendar'

export const HistoricalCalendar = ({ master }) => {
    const token = sessionStorage?.token
    const { actualProject } = useContext(GlobalContext)
    const [start, setStart] = useState(moment())
    const [end, setEnd] = useState(moment())
    const [body, setBody] = useState()
    const [historicalCalendar, setHistoricalCalendar] = useState([])
    const [loading, setLoading] = useState(true)
    const [openInfo, setOpenInfo] = useState(false)
    const [config, setConfig] = useState()
    const [noCalendar, setNoCalendar] = useState(true)
    const [sortedData, setSortedData] = useState([])
    const [sortConfig, setSortConfig] = useState({ key: 'action_description', direction: 'asc' })

    const fetchHistorialCalendar = () => {
        setLoading(true)
        setHistoricalCalendar([])
        if (master && body)
            fetch(urls.scada + `getHistoricalOutputDigital`, { method: 'POST', headers: headersScada(token), body: JSON.stringify(body) })
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    setHistoricalCalendar(data)

                    const allEntries = Object.values(data).flat()
                    setSortedData(allEntries)
                })
                .catch((err) => console.error('ERROR. API Scada', err))
                .finally(() => {
                    setLoading(false)
                })
    }

    const getNickByNumserie = (numserie) => {
        const device = master?.find((device) => device.numserie === numserie)
        return device && device.nick
    }

    const hasEmptyArray = (obj) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (Array.isArray(obj[key]) && obj[key].length > 0) {
                    return true
                }
            }
        }
        return false
    }

    useEffect(() => {
        const numserie = master && master.map((num) => num.numserie)
        if (end && start && numserie) {
            setBody({
                idProject: actualProject?.id_project,
                numserie: numserie,
                startDate: `${start?.format('YYYY-MM-DD')}`,
                endDate: `${end?.format('YYYY-MM-DD')}`
            })
        }
    }, [master, end, start])

    useEffect(() => {
        fetchHistorialCalendar()
    }, [body])

    useEffect(() => {
        if (historicalCalendar) {
            setNoCalendar(hasEmptyArray(historicalCalendar))
        }
    }, [historicalCalendar])

    const handleSort = (key) => {
        const copyData = [...sortedData]

        if (key === 'action_description') {
            copyData.sort((a, b) => {
                const actionA = ActionHistoricalCalendar(a[key])
                const actionB = ActionHistoricalCalendar(b[key])
                if (actionA < actionB) return sortConfig.direction === 'asc' ? -1 : 1
                if (actionA > actionB) return sortConfig.direction === 'asc' ? 1 : -1
                return 0
            })
        } else {
            copyData.sort((a, b) => {
                if (a[key] < b[key]) return sortConfig.direction === 'asc' ? -1 : 1
                if (a[key] > b[key]) return sortConfig.direction === 'asc' ? 1 : -1
                return 0
            })
        }

        setSortedData(copyData)
        setSortConfig({ key, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })
    }

    return (
        <>
            <div>
                <Grid item xs={12}>
                    <Grid item xs={12} className='calendar-historical-calendar'>
                        <div>
                            <CalendarComponent maxDate={end} setStart={setStart} setEnd={setEnd} />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {loading ? (
                            <Loader />
                        ) : !noCalendar ? (
                            <Grid items xs={12} className='calendar-historical-flex'>
                                <h4 align='center' className='mt-4'>
                                    No hay históricos de calendarios para la fecha seleccionada
                                </h4>
                            </Grid>
                        ) : (
                            <TableContainer component={Paper} className='table-historical-calendar'>
                                <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCellHead align='start' style={{ padding: '5px 0px' }}>
                                                Instalación
                                            </StyledTableCellHead>
                                            <StyledTableCellOrdenado
                                                celda={'datetime'}
                                                funcion={() => handleSort('datetime')}
                                                sortConfig={sortConfig}
                                            >
                                                {' '}
                                                Fecha
                                            </StyledTableCellOrdenado>
                                            <StyledTableCellOrdenado
                                                celda={'action_description'}
                                                funcion={() => handleSort('action_description')}
                                                sortConfig={sortConfig}
                                            >
                                                Acción{' '}
                                            </StyledTableCellOrdenado>
                                            <StyledTableCellOrdenado
                                                celda={'email'}
                                                funcion={() => handleSort('email')}
                                                sortConfig={sortConfig}
                                            >
                                                Usuario{' '}
                                            </StyledTableCellOrdenado>
                                            <StyledTableCellHead align='center' style={{ padding: '5px 0px' }}></StyledTableCellHead>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedData?.map((calendar) => (
                                            <StyledTableRow key={`${calendar.payload.SN_device}-${calendar.ID_entry}`}>
                                                <StyledTableCell align='center' style={{ padding: '10px 0px ' }}>
                                                    {getNickByNumserie(parseInt(calendar.payload.SN_device))}
                                                </StyledTableCell>
                                                <StyledTableCell align='center' style={{ padding: '10px 0px ' }}>
                                                    {formatDate(calendar.datetime)}
                                                </StyledTableCell>
                                                <StyledTableCell align='center' style={{ padding: '10px 0px ' }}>
                                                    {ActionHistoricalCalendar(calendar.action_description)}
                                                </StyledTableCell>
                                                <StyledTableCell align='center' style={{ padding: '10px 0px ' }}>
                                                    {calendar.email}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {calendar?.action_description !== 'delete_Calendar' && (
                                                        <ButtonStyledIcon
                                                            className='boton-delete-alarm'
                                                            title='Más Info'
                                                            onClick={() => {
                                                                setOpenInfo(!openInfo)
                                                                setConfig(calendar)
                                                            }}
                                                            style={{ height: '30px', width: '30px' }}
                                                        >
                                                            <IconData className='boton-delete-hijo' />
                                                        </ButtonStyledIcon>
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>
                </Grid>
            </div>
            {openInfo && <ModalHistoricalInfoCalendar data={config} open={openInfo} setOpen={setOpenInfo} />}
        </>
    )
}
