import CardInfoScada from './Cards/CardInfoScada'

const CardsInfoContainer = () => {
    // TODO - fill with real data
    return (
        <div className='cards-info-container'>
            {Array(3)
                .fill('')
                .map((_, i) => (
                    <CardInfoScada key={i} />
                ))}
        </div>
    )
}

export default CardsInfoContainer
