import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { GlobalContext } from 'context/GlobalContext'
import { createToast } from 'reduxSlice/toastSlice'
import { headersCentroControl } from 'services/headers'
import { urls } from 'utils/constant'
import { jwtDecode } from 'utils/JwtUtils'

/**
 * @param {project {}, grup {}, type: String, master {}, subgroup {}, nick: string || number, email: string, fontSize: string, shared: string} Recibimos según donde convengan estas variables procedentes del padre
 * @param hacemos una llamada pasandole el id_user y el id del componente según proceda
 * @returns Un modal donde podamos eliminar el dispositivo compartido con un botón
 */
export const ModalDeleteUser = ({ project, group, subgroup, master, type, email, nick, idUser, fontSize, shared, acepted }) => {
    const { setRefreshApp } = useContext(GlobalContext)
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [body, setBody] = useState()
    const [modal, setModal] = useState(false)

    const [loading, setLoading] = useState(false)
    const [url, setUrl] = useState()
    const toggle = () => setModal(!modal)
    const verifyToken = jwtDecode(token)

    useEffect(() => {
        if (type === 'project') {
            setUrl('unshareProject')
            setBody({
                id_users: [idUser ? idUser : verifyToken],
                id_project: project?.id_project
            })
        } else if (type === 'group') {
            setUrl('unshareGroup')
            setBody({
                id_users: [idUser ? idUser : verifyToken],
                id_group: group?.id_group
            })
        } else if (type === 'subgroup') {
            setUrl('unshareSubgroup')
            setBody({
                id_users: [idUser ? idUser : verifyToken],
                id_subgroup: subgroup?.id_subgroup
            })
        } else if (type === 'master') {
            setUrl('unshareDevice')
            setBody({
                id_users: [idUser ? idUser : verifyToken],
                numSerie: master?.numserie
            })
        }
    }, [type])

    const getDeleteShareAcept = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + 'deletePetition', {
            method: 'PATCH',
            redirect: 'follow',
            headers: headersCentroControl(token),
            body: JSON.stringify({
                id_object: project ? project.id_project : group ? group.id_group : subgroup ? subgroup?.id_subgroup : master?.numserie,
                id_user: verifyToken,
                id_user_new: idUser
            })
        })
            .then((res) => {
                setLoading(false)
                status = res.status
                setRefreshApp((current) => !current)
                return res.json()
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: data.message, reload: true }))
                toggle(data)
            })
            .catch((err) => console.error('ERROR. API Centro de control', err))
        setModal(!modal)
    }

    const getDeleteShareUser = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + url, {
            method: 'DELETE',
            redirect: 'follow',
            headers: headersCentroControl(token),
            body: JSON.stringify(body)
        })
            .then((res) => {
                setLoading(false)
                status = res.status
                setRefreshApp((current) => !current)
                return res.json()
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: data.message, reload: true }))
                toggle(data)
            })
            .catch((err) => console.error('ERROR. API Centro de control', err))
        setModal(!modal)
    }

    const handleDeleteUser = () => {
        if (acepted === 0) {
            getDeleteShareAcept()
        } else {
            getDeleteShareUser()
        }
    }

    return (
        <>
            <div className='text-center'>
                <HtmlTooltip
                    arrow
                    title={
                        type === 'project' && shared
                            ? 'Abandonar proyecto'
                            : type === 'group' && shared
                              ? 'Abandonar grupo'
                              : type === 'subgroup' && shared
                                ? 'Abandonar subgrupo'
                                : type === 'master' && shared
                                  ? 'Abandonar dispositivo'
                                  : 'Eliminar Usuario'
                    }
                >
                    <span
                        onClick={toggle}
                        className={`${shared && 'icon-delete-share-bottom'} mail-user-admin pointer-button modal-close-delete-user`}
                        style={{ fontSize: fontSize ? fontSize : null, marginTop: '-3px !important' }}
                    >
                        X
                    </span>
                </HtmlTooltip>
            </div>
            <Modal
                open={modal}
                setOpen={setModal}
                title={shared ? '¿Quieres salir de este proyecto compartido?' : `Eliminar usuario ${email}`}
                functSave={handleDeleteUser}
                loading={loading}
                buttonAction='Eliminar'
            >
                <Grid container className='text-center mt-2'>
                    <h4 className='font-weight-lighter' style={{ fontSize: '1.2rem' }}>
                        {shared ? (
                            <>
                                <p>Si lo haces, no se podrá visualizar este proyecto de nuevo</p>
                            </>
                        ) : (
                            ' ¿Deseas eliminar al usuario del proyecto?'
                        )}
                    </h4>
                </Grid>
                <hr className='m-0' />
            </Modal>
        </>
    )
}

export default ModalDeleteUser
