import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { MinervaIcon } from 'utils/icon-centrocontrol'
import { LoginForm } from './LoginForm'
import '../styles.scss'

export const Login = () => {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const historyState = location?.state
    const [visible, setVisible] = useState(false)

    const goRegister = () => {
        navigate('/auth/registro')
    }

    useEffect(() => {
        window.document.title = 'Login - Enerclic'
    }, [])

    return (
        <Grid container>
            <Grid item xs={11} md={12} lg={12} xl={12} className='grid-login'>
                <MinervaIcon />
            </Grid>
            <Grid item xs={11} md={12} lg={12} xl={12} className='grid-login'>
                <h2 className='title-login mt-4'>ACCESO CLIENTES</h2>
                <p className='subtitle-login'>
                    ¡Bienvenido! Accede a tu <b>Portal Enerclic</b>
                </p>
                <LoginForm
                    loading={loading}
                    setLoading={setLoading}
                    setVisible={setVisible}
                    visible={visible}
                    users={users}
                    setUsers={setUsers}
                    historyState={historyState}
                />
                <Grid item xs={12} md={12} lg={6} xl={6} style={{ width: '80%' }}>
                    <button variant='contained' type='submit' className='button-create-user-login' onClick={goRegister}>
                        Crea tu cuenta en Enerclic
                    </button>
                </Grid>
            </Grid>
            <form
                id='authentication_formAcceso'
                name='authentication_formAcceso'
                style={{ display: 'none' }}
                action='https://scada.energyccm.com/registro/checks/validate.php'
                method='POST'
            >
                <input type='text' name='email' autoComplete='username' />
                <input type='password' name='pass' autoComplete='current-password' />
            </form>
            <form
                id='authentication_formAcceso_integracion'
                name='authentication_formAcceso_integracion'
                style={{ display: 'none' }}
                action='https://scadaintegracion.enerclic.es/loginMonsolV12/loginMinerva.php'
                method='POST'
            >
                <input type='text' name='token_minerva' autoComplete='token_minerva' />
            </form>
        </Grid>
    )
}
