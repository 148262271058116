import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconWarning } from 'utils/icon-centrocontrol'
import { dispositivos } from 'utils/constant'
import { isMobile } from 'utils/Utils'
import { Chart } from '../../../SelfConsumption/GraphsUtils/LineChart'
import './IntensityRadialGraphs.scss'

const IntensityRadialGraphs = ({ activeTab, data, ccm, sinConectar }) => {
    const mobile = isMobile()
    const [L1Graph, setL1Graph] = useState(0)
    const [L2Graph, setL2Graph] = useState(0)
    const [L3Graph, setL3Graph] = useState(0)
    const [L1GraphNegative, setL1GraphNegative] = useState(0)
    const [L2GraphNegative, setL2GraphNegative] = useState(0)
    const [L3GraphNegative, setL3GraphNegative] = useState(0)
    const hiredIntensity = ccm?.id_tipo_dispositivo === dispositivos.ccmabt ? ccm?.max_iac : data?.intensidad_magneto
    const [i1, setI1] = useState()
    const [i2, setI2] = useState()
    const [i3, setI3] = useState()

    const iValue = (value) => {
        if (ccm?.id_tipo_dispositivo === dispositivos.ccmabt) {
            if (ccm?.max_iac !== null && ccm?.max_iac !== undefined && value) {
                let aux = (value * 100) / ccm?.max_iac
                if (aux > 100) {
                    aux = 100
                }
                return Number(aux).toFixed(1)
            } else {
                return '0.0'
            }
        }
        if (
            data?.intensidad_magneto !== ' - ' &&
            data?.potencia_contratada !== null &&
            data?.potencia_contratada !== undefined &&
            data.intensidad_magneto > 0 &&
            value
        ) {
            let aux = (value * 100) / data?.intensidad_magneto
            if (aux > 100) {
                aux = 100
            }
            return Number(aux).toFixed(1)
        } else {
            return '0.0'
        }
    }

    const dataFilterPositive = (data, id) => {
        let power = data
        if (data === undefined || data === null || data < 0 || sinConectar) {
            power = 0
        }
        let rest = 100 - power
        let res = {
            labels: [],
            datasets: [
                {
                    id: id,
                    type: 'doughnut',
                    data: [power, rest],
                    backgroundColor: ['#3B9FD1', '#CBCBCC'],
                    responsive: true,
                    circumference: 180,
                    rotation: -90,
                    borderWidth: 3,
                    cutout: '80',
                    radius: '80%'
                }
            ]
        }
        return res
    }

    const dataFilterNegative = (data, id) => {
        let power = data
        if (data === undefined || data === null || data > 0 || sinConectar) {
            power = 0
        }
        let rest = 100 - power
        let res = {
            labels: [],
            datasets: [
                {
                    id: id,
                    type: 'doughnut',
                    data: [power, rest],
                    backgroundColor: ['#B4DA9D', '#CBCBCC'],
                    responsive: true,
                    circumference: 180,
                    rotation: 90,
                    borderWidth: 3,
                    cutout: '80',
                    radius: '80%'
                }
            ],
            options: {
                plugins: {
                    options: {
                        tooltips: {
                            display: false
                        }
                    }
                }
            }
        }
        return res
    }

    useEffect(() => {
        if (data) {
            setI1(data?.i1)
            setI2(data?.i2)
            setI3(data?.i3)
        }
    }, [data])

    useEffect(() => {
        setL1GraphNegative(<Chart imprime={true} dataFilter={dataFilterNegative(iValue(i1), 1)} graphStyle={'doughnut'} />)
        setL1Graph(<Chart imprime={true} dataFilter={dataFilterPositive(iValue(i1), 2)} graphStyle={'doughnut'} />)
        setL2GraphNegative(<Chart imprime={true} dataFilter={dataFilterNegative(iValue(i2), 3)} graphStyle={'doughnut'} />)
        setL2Graph(<Chart imprime={true} dataFilter={dataFilterPositive(iValue(i2), 4)} graphStyle={'doughnut'} />)
        setL3GraphNegative(<Chart imprime={true} dataFilter={dataFilterNegative(iValue(i3), 5)} graphStyle={'doughnut'} />)
        setL3Graph(<Chart imprime={true} dataFilter={dataFilterPositive(iValue(i3), 6)} graphStyle={'doughnut'} />)
    }, [activeTab, i1, i2, i3])

    return (
        <>
            {activeTab === 1 && (
                <Grid item columns={19} xs={19} className='d-flex justify-content-center'>
                    <Grid item xs={mobile ? 19 : 3} md={3} lg={3} xl={2} style={{ height: mobile ? '15em' : 'auto' }}>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center ',
                                        margin: '10px 0'
                                    }}
                                >
                                    <span
                                        style={{
                                            textAlign: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ position: 'relative', top: '0.5em' }}>{L1Graph}</div>
                                        <h1 className='intensity-radial-graph-title text-[#FF8A1F]'>
                                            {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'R' : 'L1'}
                                        </h1>
                                        <p
                                            style={{
                                                position: 'relative',
                                                top: '-4.5em',
                                                fontSize: '1.5em',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                padding: hiredIntensity === null ? '14px' : '8px'
                                            }}
                                        >
                                            {hiredIntensity === null ? (
                                                <span style={{ position: 'relative' }}>
                                                    <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                        <IconWarning width={20} height={20} />
                                                    </HtmlTooltip>
                                                </span>
                                            ) : i1 !== ' - ' && i1 >= 0 && !sinConectar ? (
                                                iValue(i1) + '%'
                                            ) : (
                                                ' - %'
                                            )}
                                        </p>
                                        <div style={{ position: 'relative', top: '-15.5em' }}>{L1GraphNegative}</div>
                                        <span
                                            style={{
                                                position: 'relative',
                                                top: '-17em',
                                                textAlign: 'center',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            <h2>{i1 !== ' - ' && i1 >= 0 && !sinConectar ? i1?.toFixed(2) : ' - '} A</h2>
                                        </span>
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={mobile ? 19 : 2}
                        md={2}
                        lg={1}
                        xl={1}
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <div
                            className='divider'
                            style={{
                                position: 'relative',
                                top: mobile ? '5.1em' : '11.6em',
                                width: mobile ? '0.1em' : '2em',
                                height: mobile ? '2em' : '0.1em'
                            }}
                        />
                    </Grid>
                    <Grid item xs={mobile ? 19 : 3} md={3} lg={3} xl={2} style={{ height: mobile ? '15em' : 'auto' }}>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center ',
                                        margin: '10px 0'
                                    }}
                                >
                                    <span style={{ textAlign: 'center' }}>
                                        <div style={{ position: 'relative', top: '0.5em' }}>{L2Graph}</div>
                                        <h1 className='intensity-radial-graph-title text-[#FF8A1F]'>
                                            {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'S' : 'L2'}
                                        </h1>
                                        <p
                                            style={{
                                                position: 'relative',
                                                top: '-4.5em',
                                                fontSize: '1.5em',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                padding: hiredIntensity === null ? '14px' : '8px'
                                            }}
                                        >
                                            {hiredIntensity === null ? (
                                                <span style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                                    <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                        <IconWarning width={20} height={20} />
                                                    </HtmlTooltip>
                                                </span>
                                            ) : i2 !== ' - ' && i2 >= 0 && !sinConectar ? (
                                                iValue(i2) + '%'
                                            ) : (
                                                ' - %'
                                            )}
                                        </p>
                                        <div style={{ position: 'relative', top: '-15.5em' }}>{L2GraphNegative}</div>
                                        <span
                                            style={{
                                                position: 'relative',
                                                top: '-17em',
                                                textAlign: 'center',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            <h2>{i2 !== ' - ' && i2 >= 0 && !sinConectar ? i2?.toFixed(2) : ' - '} A</h2>
                                        </span>
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={mobile ? 19 : 2}
                        md={2}
                        lg={1}
                        xl={1}
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <div
                            className='divider'
                            style={{
                                position: 'relative',
                                top: mobile ? '5.1em' : '11.6em',
                                width: mobile ? '0.1em' : '2em',
                                height: mobile ? '2em' : '0.1em'
                            }}
                        />
                    </Grid>
                    <Grid item xs={mobile ? 19 : 3} md={3} lg={3} xl={2} style={{ height: mobile ? '15em' : 'auto' }}>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center ',
                                        margin: '10px 0'
                                    }}
                                >
                                    <span style={{ textAlign: 'center' }}>
                                        <div style={{ position: 'relative', top: '0.5em' }}>{L3Graph}</div>
                                        <h1 className='intensity-radial-graph-title text-[#FF8A1F]'>
                                            {data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'T' : 'L3'}
                                        </h1>
                                        <p
                                            style={{
                                                position: 'relative',
                                                top: '-4.5em',
                                                fontSize: '1.5em',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                padding: hiredIntensity === null ? '14px' : '8px'
                                            }}
                                        >
                                            {hiredIntensity === null ? (
                                                <span style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                                    <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                        <IconWarning width={20} height={20} />
                                                    </HtmlTooltip>
                                                </span>
                                            ) : i3 !== ' - ' && i3 >= 0 && !sinConectar ? (
                                                iValue(i3) + '%'
                                            ) : (
                                                ' - %'
                                            )}
                                        </p>
                                        <div style={{ position: 'relative', top: '-15.5em' }}>{L3GraphNegative}</div>
                                        <span
                                            style={{
                                                position: 'relative',
                                                top: '-17em',
                                                textAlign: 'center',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            <h2>{i3 !== ' - ' && i3 >= 0 && !sinConectar ? i3?.toFixed(2) : ' - '} A</h2>
                                        </span>
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
export default IntensityRadialGraphs
