// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivePowerMain .DoughnutPositive {
  position: relative;
  top: 128px;
  left: -1%;
}
.ActivePowerMain .activePowerMediciones {
  position: relative;
  z-index: 2;
  width: 1000px;
  margin-left: 60px;
}
.ActivePowerMain .DoughnutNegative {
  position: relative;
  transform: scaleX(-1);
  top: -135px;
  left: -1%;
}

.ActivePowerMain2 {
  height: 22em;
}`, "",{"version":3,"sources":["webpack://./src/views/Scada/Consumption/GraphConsumption/ScadaConsumptionPower/TotalActivepower.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,UAAA;EACA,SAAA;AAAR;AAGI;EACI,kBAAA;EACA,UAAA;EACA,aAAA;EACA,iBAAA;AADR;AAII;EACI,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,SAAA;AAFR;;AAMA;EACI,YAAA;AAHJ","sourcesContent":[".ActivePowerMain {\n    .DoughnutPositive {\n        position: relative;\n        top: 128px;\n        left: -1%;\n    }\n\n    .activePowerMediciones {\n        position: relative;\n        z-index: 2;\n        width: 1000px;\n        margin-left: 60px;\n    }\n\n    .DoughnutNegative {\n        position: relative;\n        transform: scaleX(-1);\n        top: -135px;\n        left: -1%;\n    }\n}\n\n.ActivePowerMain2 {\n    height: 22em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
