import React, { useContext, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { ButtonModal } from 'components/Buttons/ButtonModal'
import { Modal } from 'components/Modal/Modal'
import { GlobalContext } from 'context/GlobalContext'
import { createToast } from 'reduxSlice/toastSlice'
import { apiCentroControl } from 'services/api'

export const RemoveProject = ({ project, open1, setOpen1 }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const path = location.pathname.split('/')[1]
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [buttonAccept, setButtonAccept] = useState()
    const { projectsCompleto, setProjectsCompleto } = useContext(GlobalContext)

    const deleteProject = async () => {
        setLoading(true)
        try {
            const response = await apiCentroControl.delete('project', {
                data: { id_project: project?.id_project }
            })

            if (response?.status === 200) {
                setProjectsCompleto(projectsCompleto?.filter((item) => item?.id_project !== project?.id_project))
                dispatch(createToast({ status: response?.status, message: response?.data?.message, reload: false }))
                if (path === 'admin') {
                    navigate(`/projects/inicio`)
                }
            }
        } catch (err) {
            console.error('ERROR. API Centro de Control', err)
            dispatch(createToast({ status: 400, message: 'Error al realizar la solicitud', reload: false }))
        } finally {
            setLoading(false)
            setOpen1(!open1)
        }
    }

    useEffect(() => {
        setButtonAccept(
            <Grid container className='mt-4 mb-4'>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div className='modal-footer'>
                        <ButtonModal fullWidth={true} className='button-ok-modal' onClick={() => setOpen1(false)}>
                            Aceptar
                        </ButtonModal>
                    </div>
                </Grid>
            </Grid>
        )
    }, [])

    return (
        <Modal
            onClick={(e) => e.stopPropagation()}
            open={open1}
            setOpen={setOpen1}
            title={`Eliminar proyecto "${project?.nick}"`}
            footer={projectsCompleto?.length <= 1 && buttonAccept}
            functSave={projectsCompleto?.length > 1 && deleteProject}
            buttonAction='Eliminar'
            loading={loading}
            disabled={projectsCompleto?.length <= 1}
        >
            {project &&
                project?.groups?.map((grp) => {
                    return (
                        <Grid item xs={12} className='text-center mt-2'>
                            {grp?.devices?.length !== 0 && (
                                <h4 className='font-weight-lighter' style={{ fontSize: '1.2rem' }}>
                                    El grupo "{grp?.nick}" tiene {grp?.devices?.length} instalaciones.
                                </h4>
                            )}
                            {grp?.subgroups?.map((sbgrp) => {
                                return (
                                    <div key={sbgrp.id_subgroup}>
                                        {sbgrp?.devices?.length !== 0 && (
                                            <div id='verificationCode'>
                                                <h4 className='font-weight-lighter' style={{ fontSize: '1.2rem' }}>
                                                    Este proyecto tiene un subgrupo con {sbgrp?.devices?.length} instalaciones.
                                                </h4>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </Grid>
                    )
                })}
            <Grid item xs={12} className='text-center mt-2 '>
                {projectsCompleto?.length <= 1 ? (
                    <p>El último proyecto no se puede eliminar</p>
                ) : (
                    <h4 className='font-weight-lighter' style={{ fontSize: '1.2rem' }}>
                        ¿Deseas eliminar el proyecto?
                    </h4>
                )}
            </Grid>
            <hr className='m-0' />
        </Modal>
    )
}
