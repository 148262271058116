import React, { useState, useEffect } from 'react'
import { Card } from '@mui/material'
import Grid from '@mui/material/Grid'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { dispositivos } from 'utils/constant'
import { ElectronicTower, ArrowRightBlue, ArrowLeftGreen, IconWarning } from 'utils/icon-centrocontrol'
import { isMobile } from 'utils/Utils'
import { Chart } from '../../../SelfConsumption/GraphsUtils/LineChart'
import './PhasesActivepower.scss'

const ConsuptionPowerRealTime = (props) => {
    const mobile = isMobile(450)
    const [L1Graph, setL1Graph] = useState(0)
    const [L2Graph, setL2Graph] = useState(0)
    const [L3Graph, setL3Graph] = useState(0)
    const [L1GraphNegative, setL1GraphNegative] = useState(0)
    const [L2GraphNegative, setL2GraphNegative] = useState(0)
    const [L3GraphNegative, setL3GraphNegative] = useState(0)
    const [loading, setLoading] = useState(false)
    const [pact1, setPact1] = useState()
    const [pact2, setPact2] = useState()
    const [pact3, setPact3] = useState()
    const hiredPotency =
        props?.ccm?.id_tipo_dispositivo === dispositivos.ccmabt
            ? props?.ccm?.max_iac
                ? props?.ccm?.max_iac * 230
                : null
            : props?.data?.potencia_contratada

    const pactValue = (value) => {
        if (props.ccm.id_tipo_dispositivo === dispositivos.ccmabt) {
            if (props.ccm.max_iac !== null && props.ccm.max_iac !== undefined && value) {
                let aux = (value * 100) / ((props.ccm.max_iac * 230) / 1000)
                if (aux > 100) {
                    aux = 100
                }
                return Number(aux).toFixed(1)
            } else {
                return '0.0'
            }
        }
        if (
            props?.data?.potencia_contratada !== ' - ' &&
            props?.data?.potencia_contratada !== undefined &&
            props?.data?.potencia_contratada !== null &&
            props?.data?.potencia_contratada > 0 &&
            value
        ) {
            let aux = (value * 100) / (props?.data?.potencia_contratada / 3)
            if (aux > 100) {
                aux = 100
            }
            return Number(aux).toFixed(1)
        } else {
            return '0.0'
        }
    }

    const dataFilterPositive = (data, id) => {
        let power = data
        if (data === undefined || data === null || data <= 0 || props?.sinConectar) {
            power = 0
        }
        let rest = 100 - power
        let res = {
            labels: [],
            datasets: [
                {
                    id: id,
                    type: 'doughnut',
                    data: [power, rest],
                    backgroundColor: ['#3B9FD1', '#CBCBCC'],
                    responsive: true,
                    circumference: 180,
                    rotation: -90,
                    borderWidth: 2,
                    cutout: '80',
                    radius: '80%'
                }
            ]
        }
        return res
    }

    const dataFilterNegative = (data, id) => {
        let power = data
        if (data === undefined || data === null || data >= 0 || props?.sinConectar) {
            power = 0
        }
        power = Math.abs(power)
        let rest = 100 - power
        let res = {
            labels: [],
            datasets: [
                {
                    id: id,
                    type: 'doughnut',
                    data: [power, rest],
                    backgroundColor: ['#B4DA9D', '#CBCBCC'],
                    responsive: true,
                    circumference: 180,
                    rotation: 90,
                    borderWidth: 2,
                    cutout: '80',
                    radius: '80%'
                }
            ],
            options: {
                plugins: {
                    options: {
                        tooltips: {
                            display: false
                        }
                    }
                }
            }
        }
        return res
    }

    useEffect(() => {
        if (props?.data) {
            setPact1(props?.data?.pact1 ? props?.data?.pact1 : props?.data?.pact1 === 0 ? props?.data?.pact1 : props?.data?.pac1)
            setPact2(props?.data?.pact2 ? props?.data?.pact2 : props?.data?.pact2 === 0 ? props?.data?.pact2 : props?.data?.pac2)
            setPact3(props?.data?.pact3 ? props?.data?.pact3 : props?.data?.pact3 === 0 ? props?.data?.pact3 : props?.data?.pac3)
        }
    }, [props])

    useEffect(() => {
        setL1GraphNegative(<Chart imprime={true} dataFilter={dataFilterNegative(pactValue(pact1), 1)} graphStyle={'doughnut'} />)
        setL1Graph(<Chart imprime={true} dataFilter={dataFilterPositive(pactValue(pact1), 2)} graphStyle={'doughnut'} />)
        setL2GraphNegative(<Chart imprime={true} dataFilter={dataFilterNegative(pactValue(pact2), 3)} graphStyle={'doughnut'} />)
        setL2Graph(<Chart imprime={true} dataFilter={dataFilterPositive(pactValue(pact2), 4)} graphStyle={'doughnut'} />)
        setL3GraphNegative(<Chart imprime={true} dataFilter={dataFilterNegative(pactValue(pact3), 5)} graphStyle={'doughnut'} />)
        setL3Graph(<Chart imprime={true} dataFilter={dataFilterPositive(pactValue(pact3), 6)} graphStyle={'doughnut'} />)
    }, [loading])

    useEffect(() => {
        if (pact1 !== undefined && pact2 !== undefined && pact3 !== undefined) {
            setLoading(!loading)
        }
    }, [pact1, pact2, pact3])

    return (
        <Card className={`${mobile ? 'height-55' : 'height-24'} box-shadow-0`}>
            <Grid container columns={20}>
                <Grid item xs={20} md={20} lg={20} xl={20}>
                    <h3 className='text-power-title'>Potencia activa</h3>
                </Grid>
                <Grid item xs={2} md={2} lg={2} xl={2} className='top-6rem'>
                    <div className='centered-margin-container '>
                        <HtmlTooltip title={<span>Importada</span>}>
                            <div>
                                <ElectronicTower />
                            </div>
                            <div>
                                <ArrowRightBlue />
                            </div>
                        </HtmlTooltip>
                    </div>
                </Grid>
                <Grid item xs={mobile ? 16 : 4} md={4} lg={4} xl={4} style={{ height: mobile ? '15em' : 'auto' }}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <div className='flex justify-center margin-10px'>
                                <span className='text-align-center'>
                                    <div className='chart-up-consumption'>{L1Graph}</div>
                                    <h1 className='chart-title-consumption'>
                                        {props?.data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'R' : 'L1'}
                                    </h1>
                                    <p className='chart-percent-consumption'>
                                        {hiredPotency === null ? (
                                            <span className='chart-tooltip-consumption'>
                                                <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                    <IconWarning width={20} height={20} />
                                                </HtmlTooltip>
                                            </span>
                                        ) : !props?.sinConectar && pact1 !== ' - ' && pact1 !== null && pact1 !== undefined ? (
                                            pactValue(pact1) + '%'
                                        ) : (
                                            ' - %'
                                        )}
                                    </p>
                                    <div className='chart-down-consumption'>{L1GraphNegative}</div>
                                    <span className='chart-pie-consumption'>
                                        <h2>{pact1 !== ' - ' && pact1 >= 0 && !props?.sinConectar ? pact1?.toFixed(2) : ' - '} kW</h2>
                                    </span>
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={mobile ? 20 : 2} md={2} lg={2} xl={2} className='chart-div-consumption'>
                    <div className={`divider position-relative ${mobile ? 'divider-consumption-mobile' : 'divider-consumption'}`} />
                </Grid>
                <Grid item xs={mobile ? 20 : 4} md={4} lg={4} xl={4} className={`${mobile ? 'height-15' : 'height-auto'}`}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <div className='chart-consumption'>
                                <span style={{ textAlign: 'center' }}>
                                    <div className='chart-up-consumption'>{L2Graph}</div>
                                    <h1 className='chart-title-consumption'>
                                        {props?.data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'S' : 'L2'}
                                    </h1>
                                    <p className='chart-percent-consumption'>
                                        {hiredPotency === null ? (
                                            <span className='chart-tooltip-consumption'>
                                                <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                    <IconWarning width={20} height={20} />
                                                </HtmlTooltip>
                                            </span>
                                        ) : !props?.sinConectar && pact2 !== ' - ' && pact2 !== null && pact2 !== undefined ? (
                                            pactValue(pact2) + '%'
                                        ) : (
                                            ' - %'
                                        )}
                                    </p>
                                    <div className='chart-down-consumption'>{L2GraphNegative}</div>
                                    <span className='chart-pie-consumption'>
                                        <h2>
                                            {pact2 !== ' - ' && pact2 >= 0 && !props?.sinConectar
                                                ? pact2?.toFixed(2)
                                                : pact2 === 0
                                                  ? 0
                                                  : ' - '}{' '}
                                            kW
                                        </h2>
                                    </span>
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={mobile ? 20 : 2} md={2} lg={2} xl={2} className='chart-div-consumption'>
                    <div className={`divider position-relative ${mobile ? 'divider-consumption-mobile' : 'divider-consumption'}`} />
                </Grid>
                {mobile && <Grid item xs={2} />}
                <Grid item xs={mobile ? 16 : 4} md={4} lg={4} xl={4} className={`${mobile ? 'height-15' : 'height-auto'}`}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <div className='chart-consumption'>
                                <span className='text-align-center'>
                                    <div className='chart-up-consumption'>{L3Graph}</div>
                                    <h1 className='chart-title-consumption'>
                                        {props?.data?.id_tipo_dispositivo === dispositivos.ccmabtm ? 'T' : 'L3'}
                                    </h1>
                                    <p className='chart-percent-consumption'>
                                        {hiredPotency === null ? (
                                            <span className='chart-tooltip-consumption'>
                                                <HtmlTooltip title={<span>Falta información en la configuración del dispositivo</span>}>
                                                    <IconWarning width={20} height={20} />
                                                </HtmlTooltip>
                                            </span>
                                        ) : !props?.sinConectar && pact3 !== ' - ' && pact3 !== null && pact3 !== undefined ? (
                                            pactValue(pact3) + '%'
                                        ) : (
                                            ' - %'
                                        )}
                                    </p>
                                    <div className='chart-down-consumption'>{L3GraphNegative}</div>
                                    <span className='chart-pie-consumption'>
                                        <h2>{pact3 !== ' - ' && pact3 >= 0 && !props?.sinConectar ? pact3?.toFixed(2) : ' - '} kW</h2>
                                    </span>
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} md={2} lg={2} xl={2} className='top-9rem'>
                    <div className='centered-margin-container'>
                        <HtmlTooltip title={<span>Exportada</span>}>
                            <div>
                                <ArrowLeftGreen />
                            </div>
                            <div>
                                <ElectronicTower />
                            </div>
                        </HtmlTooltip>
                    </div>
                </Grid>
            </Grid>
        </Card>
    )
}

export default ConsuptionPowerRealTime
