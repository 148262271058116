import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import SpeedIcon from '@mui/icons-material/Speed'
import { FormControlLabel, Grid } from '@mui/material'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { addFilterAlarm } from 'reduxSlice/filterAlarmSlice'
import { updateDeviceTree } from 'reduxSlice/treeDevicesSlice'
import { IconShared, IconScada, IconWarning } from 'utils/icon-centrocontrol'
import { PermissionType } from 'utils/MiddleWareProjects'
import { OpenToScada } from 'utils/Utils'
import { DropdownMaster } from 'views/Master/MenuMaster/DropdownMaster'

export const Device = ({ activeTab, actualProject, group, device, data, index, setActiveTab, pref }) => {
    const token = sessionStorage?.token
    const prefix = pref + '_D-' + device?.numserie
    const dispatch = useDispatch()
    const alarma = useSelector((state) => state?.alarma)
    const objetoEncontrado = alarma?.history?.find((objeto) => objeto?.numserie === device?.numserie)
    const deviceCheck = useSelector((state) => state?.treeDevices?.tree?.[prefix])
    const mapVisible = useSelector((state) => state?.map)
    const sensors = useSelector((state) => state?.sensor)
    const sensor = sensors?.filter((sensor) => String(sensor?.mabt) === String(device?.numserie))
    const alarmsSensors = alarma?.history?.filter((objeto) => sensor[0]?.sensors?.includes(Number(objeto?.numserie)))
    const countAlarmSensors = alarmsSensors.reduce((acumulador, elemento) => {
        return acumulador + (elemento?.alarmCount - elemento?.alarmHide)
    }, 0)

    const show =
        activeTab === 0 && actualProject?.category === 4 ? mapVisible?.find((ele) => Number(ele?.ns) === Number(device?.numserie)) : true

    const handleAlarm = () => {
        dispatch(addFilterAlarm({ numserie: device?.numserie }))
        setActiveTab(3)
    }

    const toggleCheckbox = () => {
        dispatch(updateDeviceTree({ device: prefix }))
    }

    return (
        <Grid
            container
            className={`${index === 0 || index % 2 === 0 ? 'bk-gray' : 'bk-white'} ${show ? '' : 'display-none'} pt-2 pb-2 pr-1 pl-1`}
            key={'Device' + index}
        >
            <Grid item xs={7} md={7} lg={7} xl={7} className='flex-center justify-start pl-2'>
                <DropdownMaster
                    token={token}
                    data={data}
                    projects={actualProject}
                    master={device}
                    actualProject={actualProject}
                    groups={group}
                    subgroups={group?.subgroups}
                />
                {device?.error !== 1 ? (
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={() => window.open(OpenToScada(actualProject?.category, actualProject?.id_project, device?.numserie))}
                        className='card-master-nick ml-2 pl-0'
                    >
                        {device?.nick?.length > 29 ? (
                            <HtmlTooltip title={<span>{device?.nick}</span>}>{device?.nick?.substr(0, 26) + '...'}</HtmlTooltip>
                        ) : (
                            device?.nick
                        )}
                    </a>
                ) : (
                    <span className='card-master-nick ml-2 pl-0 error-card-nick'>
                        {device?.nick?.length > 29 ? (
                            <HtmlTooltip title={<span>{device?.nick}</span>}>{device?.nick?.substr(0, 26) + '...'}</HtmlTooltip>
                        ) : (
                            device?.nick
                        )}
                    </span>
                )}
                {sensor?.length > 0 && (
                    <HtmlTooltip
                        title={
                            <span>
                                {sensor[0]?.sensors?.map((sen, index) => (
                                    <div key={index}>{sen}</div>
                                ))}
                            </span>
                        }
                    >
                        <SpeedIcon sx={{ color: objetoEncontrado?.alarmCount > 0 || countAlarmSensors > 0 ? 'red' : 'black' }} />
                    </HtmlTooltip>
                )}
                {device?.error === 2 && (
                    <HtmlTooltip title={<span>Falta configuración de la planta</span>}>
                        <IconWarning width={20} height={20} />
                    </HtmlTooltip>
                )}
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} className='flex-center justify-center'>
                <FormControlLabel
                    className='checkbox-display-group checkbox path checkInstallations-check checkbox-display-group flex'
                    control={
                        <input
                            disabled={device?.error === 1 ? true : false}
                            type='checkbox'
                            style={{
                                background: '#fff',
                                width: '15px',
                                height: '15px',
                                stroke: deviceCheck ? '#FF8A1F' : '#fff'
                            }}
                            checked={deviceCheck}
                            onChange={(e) => {
                                toggleCheckbox()
                            }}
                        />
                    }
                />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} className='flex-center justify-center'>
                <HtmlTooltip className='permission-type-installation' title={<span>{PermissionType(device?.permissions)}</span>}>
                    <span className={`type-permission ${device?.permissions}`}>{device?.permissions?.toUpperCase()?.charAt(0)}</span>
                </HtmlTooltip>
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} className='flex-center justify-center'>
                {device?.shared === 1 && (
                    <HtmlTooltip title={<span>Dispositivo compartido</span>}>
                        <IconShared />
                    </HtmlTooltip>
                )}
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} className='flex-center justify-center'>
                {!objetoEncontrado || objetoEncontrado?.alarmHide === undefined ? (
                    <BeatLoader color='#ea5e0b' size={5} />
                ) : (
                    <span className='total-alarm' onClick={() => handleAlarm()}>
                        {(objetoEncontrado?.alarmCount > 0 || countAlarmSensors > 0) && objetoEncontrado?.alarmCount + countAlarmSensors}
                    </span>
                )}
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1} className='flex-center justify-center'>
                {(device?.error === 0 || device?.error === 2) && (
                    <HtmlTooltip title={<span>Scada</span>}>
                        <a
                            className='nopadding'
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={() => window.open(OpenToScada(actualProject?.category, actualProject?.id_project, device?.numserie))}
                        >
                            <ButtonStyledIcon className='boton-scada'>
                                <IconScada className='boton-scada-icon' />
                            </ButtonStyledIcon>
                        </a>
                    </HtmlTooltip>
                )}
                {device?.error === 1 && (
                    <HtmlTooltip title={<span>El tipo de proyecto y el tipo de instalación no coinciden</span>}>
                        <IconWarning width={20} height={20} />
                    </HtmlTooltip>
                )}
            </Grid>
        </Grid>
    )
}
