import React, { useContext, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useSelector } from 'react-redux'
import { Button, Grid, FormControl, MenuItem, InputLabel, Select } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import CalendarComponent from 'components/Calendar/CalendarComponent'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { GlobalContext } from 'context/GlobalContext'
import { capitalize } from 'utils/Capitalize'
import { dispositivos, alarmas } from 'utils/constant'
import { IconDownloadFile, IconInvisibleAlarm, IconVisibleAlarm, IconRefresh } from 'utils/icon-centrocontrol'
import { nombreDispositivo, nombreAlarma, isMobile } from 'utils/Utils'
import '../styleAlarm.scss'

/**
 * @param {{ now: moment, start: moment, end: moment, alarmasProyecto: Number[][], setStart: Function, setEnd: Function, dispositivosProyecto: Number[], tipoDispositivo: Number, setTipoDispositivo: Function, tipoAlarma: Number, setTipoAlarma: Function, historico: Boolean, setHistorico: Function, nickDispositivo: Number, setNickDispositivo: Function, data: {}[], visible: Boolean, setVisible: Function, actualizarFiltro: Boolean}}
 * @param now Objeto Moment del dia de hoy
 * @param start Estado que guarda la informacion de la fecha inicio
 * @param setStart Funcion para cambiar el estado de start
 * @param end Estado que guarda la informacion de la fecha fin
 * @param setEnd Funcion para cambiar el estado de end
 * @param alarmasProyecto Array
 * @param dispositivosProyecto Array con los tipos de dispositivos que tiene la instalacion
 * @param tipoDispositivo Estado que guarda la informacion del tipo de dispositivo
 * @param setTipoDispositivo Funcion para cambiar el estado de tipoDispositivo
 * @param tipoAlarma Estado que guarda la informacion del tipo de alarma
 * @param setTipoAlarma Funcion para cambiar el estado de tipoAlarma
 * @param historico Estado que guarda la informacion de la historico
 * @param setHistorico Funcion para cambiar el estado de historico
 * @param nickDispositivo Estado que guarda la informacion del dispositivo seleccionado
 * @param setNickDispositivo Funcion para cambiar el estado de nickDispositivo
 * @param data Estado que guarda la informacion de las alarmas activas o historicas
 * @param visible Estado que guarda la informacion de si se muestran los dispositivos visibles o no visibles
 * @param setVisible Funcion para cambiar el estado de visible
 * @param actualizarFiltro Estado que indica si se debe resetear el dispositivo
 * @returns Crea el filtro de alarmas
 */
export const FiltroAlarms = ({
    setStart,
    end,
    setEnd,
    alarmasProyecto,
    dispositivosProyecto,
    tipoDispositivo,
    setTipoDispositivo,
    tipoAlarma,
    setTipoAlarma,
    historico,
    setHistorico,
    nickDispositivo,
    setNickDispositivo,
    data,
    visible,
    setVisible,
    actualizarFiltro
}) => {
    const [keyDispositivo, setKeyDispositivo] = useState(Date.now() + 'dispositivo')
    const [keyAlarma, setKeyAlarma] = useState(Date.now() + 'alarma')
    const [keyNick, setKeyNick] = useState(Date.now() + 'nick')
    const filterAlarm = useSelector((state) => state.filterAlarm)
    const mobile = isMobile(1200)
    const filtroRef = useRef(null)
    const { actualProject } = useContext(GlobalContext)
    let responsiveFiltro =
        window.innerWidth < 350
            ? 12
            : window.innerWidth < 390
              ? 10
              : window.innerWidth > 700
                ? 5
                : window.innerWidth > 600
                  ? 6
                  : window.innerWidth > 500
                    ? 8
                    : 9
    let menuCentroControl = filtroRef?.current?.offsetWidth < 1750
    const valoresUnicos = new Set()
    const arraySinDuplicados = data.filter((objeto) => {
        const valor = objeto.numserie
        if (!valoresUnicos.has(valor)) {
            valoresUnicos.add(valor)
            return true
        }
        return false
    })

    /**
     * @param {{}[]} data datos en crudo de las alarmas
     * @returns devuelve un array con los objetos formateados para el excel
     */
    const cargarDatosExcel = (data) => {
        let datosExcel = []
        data.forEach((element) => {
            if ((element.hide === 0 && visible) || (!visible && element.hide === 1) || historico) {
                let obj = {
                    Instalación: element.instalacion,
                    Dispositivo: element.nick,
                    'Tipo de Dispositivo': nombreDispositivo(element.device_type),
                    'Tipo de Alarma': nombreAlarma(element.alarm_id),
                    'Fecha Activación': element.datetime_active
                }
                if (historico) {
                    obj['Fecha Finalización'] = element.datetime_solved
                    obj['Duración'] = element.duration
                }
                datosExcel.push(obj)
            }
        })
        return datosExcel
    }

    /**
     * @returns vacia los campos de los filtros
     */
    const vaciarFiltro = () => {
        setKeyDispositivo(Date.now() + 'dispositivo')
        setKeyAlarma(Date.now() + 'alarma')
        setKeyNick(Date.now() + 'nick')
        setTipoAlarma()
        setTipoDispositivo()
        setNickDispositivo()
    }

    /**
     * @returns actualiza los estados de la fecha cuando se selecciona ver historicos
     */
    const cambiarHistorico = () => {
        setHistorico(!historico)
    }

    useEffect(() => {
        setKeyDispositivo(Date.now() + 'dispositivo')
        setKeyNick(Date.now() + 'nick')
    }, [actualizarFiltro])

    useEffect(() => {
        setKeyDispositivo(Date.now() + 'dispositivo')
        setKeyAlarma(Date.now() + 'alarma')
        setKeyNick(Date.now() + 'nick')
        setNickDispositivo(filterAlarm?.numserie)
        setTipoAlarma()
        setTipoDispositivo()
    }, [filterAlarm?.numserie])

    return (
        <Grid className='mt-1 mb-1' container spacing={2} columns={18} ref={filtroRef}>
            <Grid
                item
                xs={responsiveFiltro}
                md={menuCentroControl ? 5 : 4}
                lg={menuCentroControl ? 4 : 3}
                xl={window.innerWidth < 1700 || menuCentroControl ? 3 : 2}
            >
                <FormControl sx={{ minWidth: 180 }} size='small'>
                    <InputLabel id='select-tipo-dispositivo-input' style={{ color: '#8c8c8c' }}>
                        Tipo Dispositivo
                    </InputLabel>
                    <Select
                        key={keyDispositivo}
                        className='tamano-select style-textfield-text'
                        labelId='select-tipo-dispositivo-label'
                        id='select-tipo-dispositivo'
                        value={tipoDispositivo}
                        label='Tipo Dispositivo'
                        onChange={(e) => {
                            setTipoDispositivo(e.target.value)
                        }}
                    >
                        <MenuItem value={-1}>Todos</MenuItem>
                        {Object.values(dispositivos).map((dispositivo, index) => {
                            if (
                                alarmasProyecto[dispositivo] &&
                                dispositivosProyecto.indexOf(dispositivo) !== -1 &&
                                ((tipoAlarma !== undefined && alarmasProyecto[dispositivo].indexOf(tipoAlarma) !== -1) ||
                                    tipoAlarma === undefined ||
                                    tipoAlarma === -1)
                            ) {
                                return (
                                    <MenuItem key={index} value={dispositivo}>
                                        {capitalize(nombreDispositivo(dispositivo))}
                                    </MenuItem>
                                )
                            } else {
                                return null
                            }
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid
                item
                xs={responsiveFiltro}
                md={menuCentroControl ? 5 : 4}
                lg={menuCentroControl ? 4 : 3}
                xl={window.innerWidth < 1700 || menuCentroControl ? 3 : 2}
            >
                <FormControl sx={{ minWidth: 180 }} size='small'>
                    <InputLabel id='select-tipo-alarma-input' style={{ color: '#8c8c8c' }}>
                        Tipo Alarma
                    </InputLabel>
                    <Select
                        key={keyAlarma}
                        className='tamano-select style-textfield-text'
                        labelId='select-tipo-alarma-label'
                        id='select-tipo-alarma'
                        value={tipoAlarma}
                        label='Tipo Alarma'
                        onChange={(e) => {
                            setTipoAlarma(e.target.value)
                        }}
                    >
                        <MenuItem value={-1}>Todos</MenuItem>
                        {Object.values(alarmas).map((alarma, index) => {
                            if (
                                (tipoDispositivo !== undefined &&
                                    alarmasProyecto[tipoDispositivo] &&
                                    alarmasProyecto[tipoDispositivo].indexOf(alarma) !== -1) ||
                                tipoDispositivo === undefined ||
                                tipoDispositivo === -1
                            ) {
                                return (
                                    <MenuItem key={index} value={alarma}>
                                        {nombreAlarma(alarma)}
                                    </MenuItem>
                                )
                            } else {
                                return null
                            }
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid
                item
                xs={responsiveFiltro}
                md={menuCentroControl ? 5 : 4}
                lg={menuCentroControl ? 4 : 3}
                xl={window.innerWidth < 1700 || menuCentroControl ? 3 : 2}
            >
                <FormControl sx={{ minWidth: 180 }} size='small'>
                    <InputLabel id='select-nick-dispositivo-input' style={{ color: '#8c8c8c' }}>
                        Dispositivo
                    </InputLabel>
                    <Select
                        key={keyNick}
                        className='tamano-select style-textfield-text'
                        labelId='select-nick-dispositivo-label'
                        id='select-nick-dispositivo'
                        value={nickDispositivo}
                        label='Dispositivo'
                        onChange={(e) => {
                            setNickDispositivo(e.target.value)
                        }}
                    >
                        <MenuItem value={-1}>Todos</MenuItem>
                        {arraySinDuplicados.map((ele, index) => {
                            return (
                                <MenuItem key={index} value={ele.numserie}>
                                    {ele.nick}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3} md={3} lg={2} xl={2}>
                <div className='flex-center justify-start'>
                    <div className='mr-2'>
                        <ButtonStyledIcon className='boton-filtro-refresh' title='Refrescar' onClick={vaciarFiltro}>
                            <IconRefresh className='boton-filtro-refresh-hijo' />
                        </ButtonStyledIcon>
                    </div>
                    <div>
                        {!historico && (
                            <ButtonStyledIcon
                                className='boton-filtro-visible'
                                title={visible ? 'Ver Ocultos' : 'Ver Visibles'}
                                onClick={() => setVisible((current) => !current)}
                            >
                                {visible ? (
                                    <IconVisibleAlarm className='boton-filtro-visible-hijo' />
                                ) : (
                                    <IconInvisibleAlarm className='boton-filtro-visible-hijo' />
                                )}
                            </ButtonStyledIcon>
                        )}
                    </div>
                </div>
            </Grid>
            <Grid item xs={18} md={18} lg={menuCentroControl ? 18 : 7} xl={window.innerWidth < 1700 || menuCentroControl ? 7 : 10}>
                <div className={`${mobile ? 'flex-start' : 'flex-end'}`}>
                    <div>
                        <CalendarComponent maxDate={end} setStart={setStart} setEnd={setEnd} />
                    </div>
                    <div>
                        <Button size='small' disabled={!data}></Button>
                    </div>
                    <div>
                        <ButtonStyled className='boton-historico' onClick={cambiarHistorico}>
                            {!historico ? 'Ver histórico' : 'Ver activas'}
                        </ButtonStyled>
                    </div>
                    <div className='mt-1 flex'>
                        <CSVLink
                            data={cargarDatosExcel(data)}
                            separator={';'}
                            style={{ color: 'white' }}
                            filename={actualProject ? `${actualProject?.nick}_Alarmas.csv` : 'alarmas.csv'}
                        >
                            <HtmlTooltip title={<span>Descargar datos</span>}>
                                <IconDownloadFile width='28' height='28' />
                            </HtmlTooltip>
                        </CSVLink>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}
