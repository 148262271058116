import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { CardDevicesScada } from 'components/CardScada/CardDevicesScada'
import { DragDropPrincipal } from 'components/Dragdrop/DragDropPrincipal'
import { Loader } from 'components/Loader/Loader'
import { MenuScada } from 'components/MenuScada/MenuScada'
import { GlobalContext } from 'context/GlobalContext'
import { apiCentroControl, apiScada } from 'services/api'
import { devicesMinervaConsumption, dispositivos } from 'utils/constant'
import { Alarms } from 'views/Alarms/Alarms'
import { ConfiguracionList } from 'views/Configuracion/ConfiguracionList'
import { OnOff } from '../CcMaster/tabs/OnOff'
import { ScadaData } from './ScadaData'
import './StyleConsumption.scss'

export const Consumption = () => {
    const token = sessionStorage?.token
    const navigate = useNavigate()
    const location = useLocation()
    const idCcMaster = location.pathname.split('/')[4]
    const isMobile = useMediaQuery({ maxWidth: 1680 })
    const { actualProject, setUpdateDevice, updateDevice } = useContext(GlobalContext)
    const [activeTab, setActiveTab] = useState(0)
    const [loading, setLoading] = useState(true)
    const [loadingDevice, setLoadingDevice] = useState(true)
    const [loadingCard, setLoadingCard] = useState(true)
    const [activeTabCuadro, setActiveTabCuadro] = useState(false)
    const [devices, setDevices] = useState()
    const [data, setData] = useState([])
    const [activeTabDevice, setActiveTabDevice] = useState(null)
    const [activeDevice, setActiveDevice] = useState(null)
    const [currentDevice, setCurrentDevice] = useState([])
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [loose, setLoose] = useState([])
    const [masterError, setMasterError] = useState()
    const [isSelected, setIsSelected] = useState()

    const loadingSlice = useSelector((state) => state.loading.name)

    const fetchIdCcMaster = async (numserie) => {
        try {
            const response = await apiCentroControl.get(`type/${numserie}`)

            if (response?.status === 200) {
                setLoose(response?.data)
            }
        } catch (err) {
            if (err?.status === 400) {
                navigate(`/auth/login`)
            }
            console.error('ERROR. API de Centro de control', err)
        }
    }

    const fetchInfoPower = async (loose) => {
        let url = loose !== 0 && loose !== 20 ? `${idCcMaster}` : `${idCcMaster}?idCCMaster=${idCcMaster}`
        try {
            const response = await apiScada.get(`getInfoPower/${url}`)
            if (response?.status === 200) {
                setMasterError(response?.data)
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        }
    }

    const fetchDevices = async (idCCMaster) => {
        let url
        if (loose === 0) {
            url = `getccm/${idCCMaster}?idCCMaster=${idCCMaster}`
        } else if (loose > 0) {
            url = `getccm/${idCCMaster}`
        }
        try {
            const response = await apiScada.get(url)
            if (response?.status === 200) {
                setDevices({
                    ...response?.data,
                    permissions: actualProject?.permissions,
                    error: masterError ? masterError : [],
                    loose: loose
                })
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        }
    }

    const filtrarObjetos = (objeto) => {
        let resultado = []
        if (objeto?.childrenDevices && objeto?.childrenDevices?.length > 0) {
            objeto?.childrenDevices?.forEach((element) => {
                let hijo = []
                if (element?.childrenDevices && element?.childrenDevices?.length > 0) {
                    hijo = filtrarObjetos(element)
                }
                resultado.push(element, ...hijo)
            })
        }
        return resultado
    }

    const ArrayData = () => {
        setLoading(true)
        if (devices) {
            const mapaDispositivos = dispositivos?.ccmaster === devices?.id_tipo_dispositivo ? [] : [devices]
            const mapDevice = mapaDispositivos?.concat(filtrarObjetos(devices))
            const result = mapDevice?.filter((dev) => devicesMinervaConsumption?.includes(dev?.id_tipo_dispositivo))
            if (result?.length === 0) {
                setActiveTabDevice(null)
                setActiveDevice(null)
            }
            setData(result)
            setIsSelected(result?.length > 1)
        }
        setLoading(false)
    }

    const fetchCurrentData = async (numserie) => {
        let url
        if (idCcMaster && loose === 0) {
            url = `getCurrentData/${numserie}?idCCMaster=${idCcMaster}`
        } else {
            url = `getCurrentData/${numserie}`
        }
        try {
            const response = await apiScada.get(url)
            const data = response?.data
            if (data && data?.currentData?.numserie === null) {
                data.currentData.numserie = numserie
            }
            if (
                data?.currentData?.intensidad_magneto >= 0 &&
                data?.currentData?.intensidad_magneto !== null &&
                data?.currentData?.potencia_contratada >= 0 &&
                data?.currentData?.potencia_contratada !== null
            ) {
                data.currentData.error = 0
            } else {
                data.currentData.error = 1
            }
            return data
        } catch (err) {
            console.error('ERROR. API Scada', err)
        }
    }

    const ArrayCurrentData = async () => {
        setCurrentDevice([])
        setLoading(true)
        let result = await Promise.all(data?.filter((dev) => dev?.activo)?.map((device) => fetchCurrentData(device?.numserie)))
        const arrayNew = result?.flatMap((array) => array)
        setCurrentDevice(arrayNew)
        setLoadingCard(false)
        setLoading(false)
    }

    useEffect(() => {
        fetchIdCcMaster(idCcMaster)
    }, [idCcMaster])

    useEffect(() => {
        if (loose !== undefined) {
            fetchInfoPower(loose)
        }
    }, [loose, loadingSlice])

    useEffect(() => {
        if (loose !== undefined && actualProject?.permissions) {
            fetchDevices(idCcMaster)
        }
    }, [actualProject, loose, keySwitch, updateDevice])

    useEffect(() => {
        ArrayData()
    }, [devices])

    useEffect(() => {
        if (devices) {
            setIsSelected(activeDevice === null && activeTab === 0)
        }
    }, [activeDevice, activeTab])

    useEffect(() => {
        setLoading(true)
        ArrayCurrentData()
        if (data?.length === 1 && data?.[0]?.activo) {
            setActiveDevice(data?.[0]?.numserie)
            setActiveTabDevice(data?.[0])
        }
        setLoading(false)
    }, [data])

    return (
        <div className='container-scada-principal'>
            {(loading || !devices) && <Loader />}
            {!loading && devices && (
                <>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 pt-2 navbar-scada scada-navbar-centro-control'>
                        <MenuScada
                            setActiveDevice={setActiveDevice}
                            token={token}
                            activeTabCuadro={activeTabCuadro}
                            setActiveTabCuadro={setActiveTabCuadro}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            master={devices}
                            masterError={masterError}
                            loose={loose}
                        />
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            xs={isSelected ? 12 : 2}
                            className={`mb-3 ${isSelected && 'containter-scada-graphs-center'} container-card-devices-scada`}
                        >
                            {data &&
                                data
                                    ?.filter((ele) => ele?.activo)
                                    ?.map((dev, index) => {
                                        return (
                                            <Grid item xs={isSelected ? 2 : 12} key={index}>
                                                <CardDevicesScada
                                                    activeDevice={activeDevice}
                                                    selected={data.length === 1}
                                                    setActiveTab={setActiveTab}
                                                    key={index}
                                                    setKeySwitch={setKeySwitch}
                                                    keySwitch={keySwitch}
                                                    activeTabDevice={activeTabDevice}
                                                    setActiveTabDevice={setActiveTabDevice}
                                                    masterData={devices}
                                                    devices={dev}
                                                    currentDevice={
                                                        currentDevice?.filter((devi) => devi?.currentData?.numserie === dev?.numserie)?.[0]
                                                    }
                                                    loading={loadingCard}
                                                    setLoading={setLoadingCard}
                                                    loose={loose}
                                                    refresh={updateDevice}
                                                    setRefresh={setUpdateDevice}
                                                    permissions={devices.permissions}
                                                    setActiveDevice={setActiveDevice}
                                                />
                                            </Grid>
                                        )
                                    })}
                        </Grid>
                        <Grid item xs={isSelected ? 12 : 10} className='container-scada-functions container-scada-dnd mb-2'>
                            {isSelected && !loading && devices && (
                                <DragDropPrincipal
                                    numserie={idCcMaster}
                                    setActiveTab={setActiveTab}
                                    activeTab={activeTab}
                                    setActiveTabCuadro={setActiveTabCuadro}
                                    loadingCard={loadingCard}
                                    data={data}
                                    currentDevice={currentDevice}
                                    devices={devices}
                                />
                            )}
                            {activeTab === 0 && activeDevice !== null && (
                                <ScadaData
                                    currentDevice={currentDevice?.filter((devi) => devi?.currentData?.numserie === activeDevice)?.[0]}
                                    data={activeTabDevice}
                                    loading={loadingDevice}
                                    setLoading={setLoadingDevice}
                                    loadingCard={loadingCard}
                                    loose={loose}
                                />
                            )}
                            {activeTab === 1 && <Alarms instalacioneSeleccionadas={[idCcMaster]} dispositivos={[devices]} />}
                            {activeTab === 2 && (
                                <ConfiguracionList
                                    activeTabCuadro={activeTabCuadro}
                                    consumption={true}
                                    scadaSelf={true}
                                    instalacioneSeleccionadas={[idCcMaster]}
                                    dispositivo={[devices]}
                                    scada={true}
                                    masterError={masterError}
                                />
                            )}
                            {activeTab === 4 && <OnOff activeTab={activeTab} isMobile={isMobile} master={devices} />}
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    )
}
