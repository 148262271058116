import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { MenuScada } from 'components/MenuScada/MenuScada'
import { GlobalContext } from 'context/GlobalContext'
import { apiScada } from 'services/api'
import useDocumentTitle from 'utils/Hooks/useDocumentTitle'
import CardsInfoContainer from './CardsInfoContainer'
import './styleSelfConsumptionColective.scss'

export const SelfConsumptionColective = () => {
    const location = useLocation()
    const idCcMaster = location.pathname.split('/')[4]
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0)
    const [devices, setDevices] = useState()
    const [selfConsumption, setSelfConsumption] = useState('general')
    const [currentData, setCurrentData] = useState([])
    const { actualProject } = useContext(GlobalContext)
    const [masterError, setMasterError] = useState([])

    useDocumentTitle({
        template: 'Scada - {nick} - Autoconsumo Colectivo',
        values: { nick: actualProject?.nick },
        defaultTitle: 'Scada - Autoconsumo Colectivo'
    })

    const fetchDevices = async (idCCMaster) => {
        try {
            const response = await apiScada.get(`getccm/${idCCMaster}?idCCMaster=${idCCMaster}`)
            if (response?.status === 200) {
                setDevices({
                    ...response.data,
                    permissions: actualProject?.permissions,
                    error: actualProject?.error
                })
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (actualProject && idCcMaster) {
            fetchDevices(idCcMaster)
            setMasterError([
                {
                    nick: actualProject.nick,
                    numserie: actualProject.numserie,
                    permissions: actualProject.permissions,
                    shared: actualProject.shared,
                    error: actualProject.error
                }
            ])
        }
    }, [actualProject, idCcMaster])

    return (
        <div id='selfConsumptionColective' className='container-scada-principal'>
            {loading && <Loader />}
            {!loading && (
                <Grid container>
                    <Grid item xs={12} className='pl-3 pt-2 navbar-scada scada-navbar-centro-control'>
                        <MenuScada
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            master={devices}
                            currentData={currentData}
                            loose={0}
                            setSelfConsumption={setSelfConsumption}
                            selfConsumption={selfConsumption}
                        />
                    </Grid>

                    <Grid item xs={12} className='pl-3 pt-2'>
                        <CardsInfoContainer />
                    </Grid>
                </Grid>
            )}
        </div>
    )
}
