import React from 'react'
import { BeatLoader } from 'react-spinners'
import { Grid, Table } from '@mui/material'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconImportAndExport } from 'utils/icon-centrocontrol'
import { showDate, esHoy } from 'utils/Utils'
import { datoNull, datoNullPercent } from './utils'

export const TableCcMabt = ({ datos, alarmComunication, loading }) => {
    const colorTension = (valor) => {
        const min = 230 - 230 * 0.07
        const max = 230 + 230 * 0.07
        return valor === 0 || min > valor || max < valor ? 'color-red' : 'color-gray'
    }

    return (
        <Grid item xs={12} md={12} lg={12} xl={12} className='pt-2 pb-2'>
            <Grid container className='flex-center justify-center'>
                {loading ? (
                    <BeatLoader color='#ea5e0b' size={5} />
                ) : (
                    <>
                        {alarmComunication && (
                            <Grid item xs={12} md={12} lg={12} xl={12} className='flex-center justify-center pt-1'>
                                <small className='flex font-size-small p-0 color-red text-align-center'>Fallo de comunicación:</small>
                            </Grid>
                        )}
                        {(alarmComunication || !esHoy(datos?.date)) && (
                            <Grid item xs={12} md={12} lg={12} xl={12} className='flex-center justify-center pb-1'>
                                <small className={`flex font-size-x-small p-0 ${alarmComunication ? 'color-red' : 'color-black'}`}>
                                    {alarmComunication ? alarmComunication?.datetime_active : showDate(datos?.date)}
                                </small>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            <Table>
                <thead className='border-botom-1px-black'>
                    <tr className='color-black'>
                        <th className='flex p-0 width-40-percent'>
                            <div className='flex'>
                                {!alarmComunication && esHoy(datos?.date) && (
                                    <small className='flex font-size-x-small p-0 color-black mr-1'>{showDate(datos?.date)}</small>
                                )}
                                <HtmlTooltip className='flex' title='Energía importada y exportada'>
                                    <IconImportAndExport />
                                </HtmlTooltip>
                            </div>
                        </th>
                        <th className='p-0 font-size-x-small text-align-center'>R</th>
                        <th className='p-0 font-size-x-small text-align-center'>S</th>
                        <th className='p-0 font-size-x-small text-align-center'>T</th>
                    </tr>
                </thead>
                <tbody className='font-size-small'>
                    <tr className='row-gary-table'>
                        <td className='color-black font-weight-bold font-size-x-small text-align-start pt-1 pb-1 width-40-percent'>
                            <HtmlTooltip className='flex' title='Potencia aparente'>
                                S (kVA)
                            </HtmlTooltip>
                        </td>
                        <td className='text-align-center font-size-x-small'>
                            {alarmComunication ? '-' : datoNullPercent(datos?.s1) + (datoNullPercent(datos?.s1) !== '-' ? '%' : '')}
                        </td>
                        <td className='text-align-center font-size-x-small'>
                            {alarmComunication ? '-' : datoNullPercent(datos?.s2) + (datoNullPercent(datos?.s2) !== '-' ? '%' : '')}
                        </td>
                        <td className='text-align-center font-size-x-small'>
                            {alarmComunication ? '-' : datoNullPercent(datos?.s3) + (datoNullPercent(datos?.s3) !== '-' ? '%' : '')}
                        </td>
                    </tr>
                    <tr>
                        <td className='color-black font-weight-bold font-size-x-small text-align-start pt-1 pb-1 width-40-percent'>
                            Tensión (V)
                        </td>
                        <td className={`${colorTension(datos?.v1)} text-align-center font-size-x-small`}>
                            {alarmComunication ? '-' : datoNull(datos?.v1)}
                        </td>
                        <td className={`${colorTension(datos?.v2)} text-align-center font-size-x-small`}>
                            {alarmComunication ? '-' : datoNull(datos?.v2)}
                        </td>
                        <td className={`${colorTension(datos?.v3)} text-align-center font-size-x-small`}>
                            {alarmComunication ? '-' : datoNull(datos?.v3)}
                        </td>
                    </tr>
                    <tr className='row-gary-table'>
                        <td className='color-black font-weight-bold font-size-x-small text-align-start pt-1 pb-1 width-40-percent'>
                            Corriente (A)
                        </td>
                        <td className='text-align-center font-size-x-small'>{alarmComunication ? '-' : datoNull(datos?.t1)}</td>
                        <td className='text-align-center font-size-x-small'>{alarmComunication ? '-' : datoNull(datos?.t2)}</td>
                        <td className='text-align-center font-size-x-small'>{alarmComunication ? '-' : datoNull(datos?.t3)}</td>
                    </tr>
                </tbody>
            </Table>
        </Grid>
    )
}
