import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { apiScada } from 'services/api'

export const ModalSaveLayout = ({ open, setOpen, layoutData, numserie, setLayoutData, setIsLoading, isLoading, configuration }) => {
    const dispatch = useDispatch()

    const editLayout = async ({ numserie, configuration }) => {
        try {
            const response = await apiScada.patch(`distributionPanel/${numserie}`, {
                confPanel: configuration
            })

            if (response?.status === 200) {
                dispatch(createToast({ status: response?.status, message: 'Configuración guardada', reload: false }))

                const parsedConfiguration = JSON.parse(response?.data?.confPanel)
                setLayoutData(parsedConfiguration)
            }
        } catch (error) {
            dispatch(createToast({ status: 400, message: 'Error en la solicitud', reload: false }))
            console.error('Error en la solicitud:', error)
        } finally {
            setIsLoading(false)
            setOpen(!open)
        }
    }

    const createLayout = async ({ numserie, configuration }) => {
        try {
            const response = await apiScada.post(`distributionPanel/${numserie}`, {
                confPanel: configuration
            })

            if (response?.status === 200) {
                dispatch(createToast({ status: response?.status, message: 'Configuración guardada', reload: false }))

                const parsedConfiguration = JSON.parse(response?.data?.confPanel)
                setLayoutData(parsedConfiguration)
            }
        } catch (error) {
            dispatch(createToast({ status: 400, message: 'Error en la solicitud', reload: false }))
            console.error('Error en la solicitud:', error)
        } finally {
            setIsLoading(false)
            setOpen(!open)
        }
    }

    const handleSave = () => {
        if (layoutData) {
            editLayout({ numserie: numserie, configuration: configuration })
        } else {
            createLayout({ numserie: numserie, configuration: configuration })
        }
        setIsLoading(true)
    }

    return (
        <Modal open={open} setOpen={setOpen} title='Guardar configuración' functSave={handleSave} loading={isLoading} disabled={isLoading}>
            <Grid container className='text-center mt-2'>
                <h4 className='font-weight-lighter modal-font'>¿Deseas guardar esta configuración?</h4>
            </Grid>
        </Modal>
    )
}
