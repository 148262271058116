import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { FormControl, Grid, MenuItem, TextField } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { SelectStyled } from 'components/Select/SelectStyled'
import { GlobalContext } from 'context/GlobalContext'
import { updateLoading } from 'reduxSlice/loadingSlice'
import { createToast } from 'reduxSlice/toastSlice'
import { apiCentroControl, apiScada } from 'services/api'
import { convertDatetime } from 'utils/Utils'
import { validatorNumber, validatorInput } from 'utils/ValidatorForm'

export const ModalInfoCCM = ({ modal, setModal, ...props }) => {
    const { setUpdateDevice } = useContext(GlobalContext)
    const [power, setPower] = useState('')
    const [intMag, setIntMag] = useState('')
    const [notes, setNotes] = useState('')
    const [loading, setLoading] = useState(false)
    const [alias, setAlias] = useState('')
    const [numSerie, setNumSerie] = useState('')
    const [firmware, setFirmware] = useState('')
    const [date, setDate] = useState('')
    const [body, setBody] = useState()
    const [measure, setMeasure] = useState('Potencia_maxima')
    const [isValidNick, setIsValidNick] = useState(false)
    const [validNotes, setValidNotes] = useState(false)
    const [isvalidMeasure, setIsvalidMeasure] = useState(false)
    const [isValid, setIsValid] = useState(true)
    const [originalData, setOriginalData] = useState({})
    const dispatch = useDispatch()

    const patchData = async () => {
        setLoading(true)
        let url = `updateDeviceInfo/${props?.device?.[0]?.numserie}`

        if (props?.device?.[0].cloud === true) {
        } else if (props?.device[0]?.master_serial) {
            url += `?idCCMaster=${props?.device[0]?.master_serial}`
        } else if (props.device[0]?.id_padre) {
            url += `?idCCMaster=${props?.device[0]?.id_padre}`
        } else if (props?.instalacion?.numserie === props?.device?.[0]?.numserie) {
            url += `?idCCMaster=${props?.device?.[0]?.numserie}`
            body.kit = true
        }

        try {
            const response = await apiScada.patch(url, body)

            if (response?.status === 200 && body?.potencia_contratada !== undefined) {
                dispatch(updateLoading())
                setUpdateDevice((current) => !current)
                dispatch(createToast({ status: response.status, message: response.data.message, reload: true }))
                setModal(!modal)
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
            dispatch(createToast({ status: 400, message: 'Error al actualizar los datos', reload: false }))
        } finally {
            setLoading(false)
        }
    }

    const handleUpdate = () => {
        patchData()
    }

    const handleChangeNick = (e) => {
        setAlias(e)
        setIsValidNick(validatorInput(e))
    }

    const handleChangeNotes = (e) => {
        setNotes(e === '' ? null : e)
        setValidNotes(validatorInput(e))
    }

    const handleChangeMeasure = (e) => {
        if (measure === 'Potencia_maxima') {
            setPower(e)
        } else if (measure === 'Corriente_maxima') {
            setIntMag(e)
        }
        setIsvalidMeasure(validatorNumber(e))
    }

    useEffect(() => {
        const getData = async ({ numserie, id_padre, master_serial }) => {
            let url

            if (master_serial) {
                url = `userInfoDeviceChildren/${numserie}/${master_serial}`
            } else if (id_padre) {
                url = `userInfoDeviceChildren/${numserie}/${id_padre}`
            } else if (numserie) {
                url = `userInfoDeviceChildren/${numserie}/${numserie}`
            } else {
                console.error('No se proporcionaron las variables necesarias.')
                return null
            }

            try {
                const response = await apiCentroControl.get(url)

                if (response?.status === 200) {
                    if (response?.data) {
                        setNumSerie(response?.data?.numserie)
                        setDate(convertDatetime(response?.data?.fecha_sync))
                        setFirmware(response?.data?.FW_version)
                        setAlias(response?.data?.nick)
                        setNotes(response?.data?.notes)
                        setPower(response?.data?.potencia_contratada)
                        setIntMag(response?.data?.intensidad_magneto)
                        setOriginalData({ ...response?.data })
                    }
                }
            } catch (error) {
                console.error('Error en la solicitud', error)
            }
        }

        if (modal) {
            getData({
                numserie: props?.device?.[0]?.numserie,
                id_padre: props?.device?.[0]?.id_padre,
                master_serial: props?.device?.[0]?.master_serial
            })
        }
    }, [modal])

    useEffect(() => {
        if (measure === 'Potencia_maxima') {
            setBody({
                nick: alias,
                notes: notes,
                potencia_contratada: power ? power : null
            })
        } else if (measure === 'Corriente_maxima') {
            setBody({
                nick: alias,
                notes: notes,
                intensidad_magneto: intMag ? intMag : null
            })
        } else {
            setBody({
                nick: alias,
                notes: notes
            })
        }
    }, [props?.devices, power, measure, alias, notes, intMag])

    useEffect(() => {
        setIsValidNick(false)
        setValidNotes(false)
        setIsvalidMeasure(false)
    }, [modal])

    const original = {
        alias: originalData?.nick,
        power: originalData?.potencia_contratada,
        intensity: originalData?.intensidad_magneto,
        notes: originalData?.notes
    }

    useEffect(() => {
        const OriginalNotesValue = original?.notes === '' ? null : original?.notes
        const notesValue = notes === '' ? null : notes
        const powerValue = power === '' ? null : Number(power)
        const intensityValue = intMag === '' ? null : Number(intMag)

        if (
            originalData &&
            alias &&
            !isValidNick &&
            !validNotes &&
            !isvalidMeasure &&
            (original?.alias !== alias ||
                (power !== null && powerValue !== 0 && original?.power !== powerValue) ||
                (intMag !== null && intensityValue !== 0 && original?.intensity !== intensityValue) ||
                OriginalNotesValue !== notesValue)
        ) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    }, [alias, notes, measure, power, intMag, original, originalData])

    return (
        <>
            <Modal
                onClick={(e) => e.stopPropagation()}
                maxWidth='sm'
                open={modal}
                setOpen={setModal}
                title={`Información del dispositivo "${originalData?.nick}"`}
                functSave={handleUpdate}
                loading={loading}
                disabled={isValid}
            >
                <Grid container className='pr-5 pl-5 mt-3'>
                    <Grid className='pr-2 pb-3' item xs={12} md={4}>
                        <div className='pb-1'>
                            <small className='color-black'>Número de serie:</small>
                        </div>
                        <InputStyled value={numSerie} disabled={true} fullWidth={true} />
                    </Grid>
                    <Grid className='pr-2' item xs={12} md={4} lg={4} xl={4}>
                        <div className='pb-1'>
                            <small className='color-black'>Fecha de registro:</small>
                        </div>
                        <InputStyled value={date} disabled={true} fullWidth={true} />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <div className='pb-1'>
                            <small className='color-black'>Firmware de CCM:</small>
                        </div>
                        <InputStyled value={firmware ? firmware : '-'} disabled={true} fullWidth={true} />
                    </Grid>
                    <Grid className='pb-3' item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className='color-black'>Nombre del dispositivo:</small>
                        </div>
                        <InputStyled value={alias} onChange={handleChangeNick} fullWidth={true} error={isValidNick} maxLength={100} />
                    </Grid>
                    <Grid className='pb-3' item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className='color-black'>Notas:</small>
                        </div>
                        <TextField
                            fullWidth
                            className='style-textfield-text'
                            id='outlined-multiline-static'
                            multiline
                            rows='4'
                            value={notes}
                            error={validNotes}
                            helperText={validNotes ? 'Caracteres no permitidos' : ''}
                            onChange={(e) => handleChangeNotes(e.target.value)}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} xl={4}>
                        <div className='pb-1'>
                            <small className='color-black'>Potencia contratada:</small>
                        </div>
                        <FormControl className='width-100 pb-2' size='small'>
                            <SelectStyled onChange={(e) => setMeasure(e.target.value)} value={measure}>
                                <MenuItem key='1' value='Potencia_maxima'>
                                    Potencia Máxima
                                </MenuItem>
                                <MenuItem key='2' value='Corriente_maxima'>
                                    Corriente Máxima
                                </MenuItem>
                            </SelectStyled>
                        </FormControl>
                    </Grid>
                    <Grid className='pl-2' item xs={8} md={8} lg={8} xl={8}>
                        <div className='pb-1'>
                            <small className='color-black'>{measure === 'Corriente_maxima' ? 'Valor (A)' : 'Valor (W)'}</small>
                        </div>
                        {measure === 'Potencia_maxima' && (
                            <InputStyled
                                disabled={measure !== undefined ? false : true}
                                value={power}
                                onChange={handleChangeMeasure}
                                fullWidth={true}
                                error={isvalidMeasure}
                            />
                        )}
                        {measure === 'Corriente_maxima' && (
                            <InputStyled
                                disabled={measure !== undefined ? false : true}
                                value={intMag}
                                onChange={handleChangeMeasure}
                                fullWidth={true}
                                error={isvalidMeasure}
                            />
                        )}
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
