// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titulo {
  border-bottom: 1px solid rgb(109, 106, 106);
  display: inline-block;
  width: -webkit-max-content;
  width: max-content;
}`, "",{"version":3,"sources":["webpack://./src/views/Scada/MABT/SensorLine/SensorLine.scss"],"names":[],"mappings":"AAAA;EACI,2CAAA;EACA,qBAAA;EACA,0BAAA;EAAA,kBAAA;AACJ","sourcesContent":[".titulo {\n    border-bottom: 1px solid rgb(109, 106, 106);\n    display: inline-block;\n    width: max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
