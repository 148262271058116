import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import './StyleModal.scss'

export const ModalOnOffManualControl = ({
    data,
    dataRelay,
    loadingOn,
    setLoadingOn,
    pulseAction,
    modal,
    setModal,
    idCcMaster,
    centroControl,
    setKeySwitch
}) => {
    const token = sessionStorage?.token
    const [body2, setBody2] = useState([])
    const [prevMode, setPrevMode] = useState()
    const dispatch = useDispatch()

    const handleInternalMode = (mode) => {
        setPrevMode(mode)
        if (mode === 100 || !mode) {
            return 103
        } else if (mode === 103 || mode === 0) {
            return 100
        } else {
            return mode
        }
    }
    useEffect(() => {
        setBody2({
            numserie: idCcMaster,
            state: 1,
            ID_entry: data?.ID_entry,
            int_mode: Number(handleInternalMode(data?.internal_mode))
        })
    }, [data, loadingOn])

    const asyncCalendar = () => {
        setKeySwitch(Date.now())
        setLoadingOn(true)
        let status
        if (data?.internal_mode === 101 || data?.internal_mode === 102) {
            pulseAction()
            setModal(false)
        }
        fetch(urls.scada + `calendarRelay`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body2) })
            .then((res) => {
                status = res.status
                if (res.status === 400) {
                    data.internal_mode = prevMode
                } else {
                    data.internal_mode = handleInternalMode(data?.internal_mode)
                    if (centroControl && data.ID_entry === 1) {
                        dataRelay.out1_mode = data?.internal_mode.toString()
                    } else if (centroControl && data.ID_entry === 2) {
                        dataRelay.out2_mode = data?.internal_mode.toString()
                    }
                }
                return res.json()
            })
            .then((data) => {
                dispatch(createToast({ status: status, message: data.message, reload: false }))
            })
            .catch((err) => console.error('ERROR. API Scada', err))
            .finally(() => {
                setModal(false)
                setLoadingOn(false)
            })
    }

    return (
        <Modal
            onClick={(e) => e.stopPropagation()}
            functSave={asyncCalendar}
            disabled={loadingOn}
            loading={loadingOn}
            open={modal}
            buttonAction='Aplicar'
            setOpen={setModal}
            title='Confirmación sobre el calendario'
        >
            <span style={{ margin: '0 auto' }}>
                <p>
                    ¿Quieres {data?.internal_mode === 100 || data?.internal_mode === 102 ? 'desconectar' : 'conectar'}{' '}
                    {data.prevName ? data.prevName : data.name}?
                </p>
            </span>
        </Modal>
    )
}
