import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { updateRefresh } from 'reduxSlice/refreshNavFooterSlice'
import { createToast } from 'reduxSlice/toastSlice'
import { headersCentroControl, headersCentroControlImagen } from 'services/headers'
import { urls } from 'utils/constant'
import { NewLogo } from './CreateLogo/NewLogo'
import { DeleteLogo } from './DeleteLogo/DeleteLogo'
import { EditLogo } from './EditLogo/EditLogo'
import { PreviewLogoProfile } from './ViewLogo/PreviewLogoProfile'

export const ProfileLogos = ({ idProject }) => {
    const token = sessionStorage?.token
    const [logos, setLogos] = useState({})
    const [open, setOpen] = useState(false)
    const [openEliminar, setOpenEliminar] = useState(false)
    const [activateDrop, setActivateDrop] = useState(false)
    const [position, setPosition] = useState(-1)
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState([])
    const [name, setName] = useState([])
    const [positionOrigin, setPositionOrigin] = useState([])
    const [fileEdit, setFileEdit] = useState([])

    const dispatch = useDispatch()

    const handleFiles = (file) => {
        setFiles(file)
    }

    const handleEditFiles = (file) => {
        setFileEdit(file[0])
    }

    const activateDropZone = (name, position) => {
        setFileEdit([])
        setName(name)
        setPositionOrigin(Number(position))
        setPosition(Number(position))
        setActivateDrop(true)
    }

    const activateDelete = (name) => {
        setName(name)
        setOpenEliminar(true)
    }

    const handleCloseMensaje = () => {
        setPosition(-1)
        setFileEdit([])
        setFiles([])

        setOpen(false)
        setOpenEliminar(false)
        setActivateDrop(false)
        dispatch(updateRefresh())
    }

    const fetchGetLogo = async () => {
        setLoading(true)
        try {
            const res = await fetch(urls.ccontrol + `logo/${idProject ? idProject : 0}`, {
                method: 'GET',
                headers: headersCentroControl(token)
            })
            if (res.status === 200) {
                let json = await res.json()
                setLogos(json)
            }
            setLoading(false)
        } catch (err) {
            console.error('ERROR. API Centro de Control', err)
        }
    }

    const fetchAddLogo = async () => {
        if (files.length > 0) {
            setLoading(true)
            let status
            const formData = new FormData()
            let nameLogo = !logos?.logo_1 ? 'logo1' : !logos?.logo_2 ? 'logo2' : 'logo3'
            files.forEach((element) => {
                formData.append('logos', element)
                formData.append(nameLogo, position)
                nameLogo = nameLogo === 'logo2' ? 'logo3' : 'logo2'
            })
            formData.append('id_project', idProject ? idProject : 0)
            try {
                const res = await fetch(urls.ccontrol + 'logo', {
                    method: 'POST',
                    headers: headersCentroControlImagen(token),
                    body: formData
                })
                setLoading(false)
                if (res.status === 200) {
                    let json = await res.json()
                    setLogos(json)
                    fetchGetLogo()
                    dispatch(createToast({ status: status, message: 'Logo subido correctamente', reload: true }))
                    handleCloseMensaje()
                } else {
                    dispatch(createToast({ status: status, message: 'Error al subir logo', reload: true }))
                    handleCloseMensaje()
                    return false
                }
            } catch (err) {
                console.error('ERROR. API Centro de Control', err)
            }
        } else {
            setOpen(false)
        }
    }

    const fetchDeleteLogo = async () => {
        setLoading(true)
        let status
        try {
            const res = await fetch(urls.ccontrol + 'logo', {
                method: 'DELETE',
                headers: headersCentroControl(token),
                body: JSON.stringify({ delete: name, id_project: idProject ? idProject : 0 })
            })
            setLoading(false)
            if (res.status === 200) {
                let json = await res.json()
                setLogos(json)
                dispatch(createToast({ status: status, message: 'Logo eliminado correctamente', reload: true }))
                handleCloseMensaje()
                return true
            } else {
                dispatch(createToast({ status: status, message: 'Error al eliminar el logo', reload: true }))
                handleCloseMensaje()
                return false
            }
        } catch (err) {
            console.error('ERROR. API Centro de Control', err)
        }
    }

    const fetchEditLogo = async () => {
        if (fileEdit) {
            let nameEdit = name.split('_')
            setLoading(true)
            let status
            const formData = new FormData()
            if (fileEdit?.length === undefined) {
                formData.append(nameEdit[0] + 's' + nameEdit[1], fileEdit)
            }
            formData.append('id_project', idProject ? idProject : 0)
            formData.append(nameEdit[0] + nameEdit[1], position)
            try {
                const res = await fetch(urls.ccontrol + 'logo', {
                    method: 'PATCH',
                    headers: headersCentroControlImagen(token),
                    body: formData
                })
                let json = await res.json()
                setName()
                setFileEdit([])
                setLoading(false)
                if (res.status === 200) {
                    setLogos(json?.body)
                    dispatch(createToast({ status: status, message: 'Logo editado correctamente', reload: true }))
                    handleCloseMensaje()
                    return true
                } else {
                    dispatch(createToast({ status: status, message: 'Error al editar el logo', reload: true }))
                    handleCloseMensaje()
                    return false
                }
            } catch (err) {
                console.error('ERROR. API Centro de Control', err)
            }
        }
    }

    useEffect(() => {
        fetchGetLogo()
    }, [])

    return (
        <Grid container>
            <Grid items xs={12} className='flex flex-column justify-content-center align-items-center'>
                <PreviewLogoProfile
                    idProject={idProject}
                    setOpen={setOpen}
                    logos={logos}
                    activateDropZone={activateDropZone}
                    activateDelete={activateDelete}
                />
                <EditLogo
                    open={activateDrop}
                    setOpen={setActivateDrop}
                    fetchEditLogo={fetchEditLogo}
                    loading={loading}
                    fileEdit={fileEdit}
                    logos={logos}
                    position={position}
                    setPosition={setPosition}
                    handleEditFiles={handleEditFiles}
                    positionOrigin={positionOrigin}
                    name={name}
                />
                <DeleteLogo open={openEliminar} setOpen={setOpenEliminar} fetchDeleteLogo={fetchDeleteLogo} loading={loading} />
                <NewLogo
                    open={open}
                    setOpen={setOpen}
                    fetchAddLogo={fetchAddLogo}
                    loading={loading}
                    files={files}
                    position={position}
                    setPosition={setPosition}
                    logos={logos}
                    handleFiles={handleFiles}
                />
            </Grid>
        </Grid>
    )
}
