import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconAdmin, IconProject, IconTableDevice } from 'utils/icon-centrocontrol'
import { ModalProjectList } from '../Project/ModalProjectList/ModalProjectList'
import './StyleItemNav.scss'

export const ItemNavCc = ({ verify, navegation }) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [isShow1, setIsShow1] = useState(false)
    const [isShow2, setIsShow2] = useState(false)

    const toggle = () => {
        setOpen(!open)
        setIsShow(false)
    }

    const adminVision = () => navigate(`/admin/users`)

    const IPMeterListDevices = () => navigate(`/admin/devices`)

    return (
        <>
            <a
                className='itemNavCC-icon'
                onClick={IPMeterListDevices}
                onMouseEnter={() => setIsShow2(true)}
                onMouseLeave={() => setIsShow2(false)}
                title='Mis dispositivos'
            >
                <IconTableDevice fill={isShow2 ? '#FF8A1F' : ''} height='26' width='26' />
            </a>
            {navegation !== 'projects' && (
                <a
                    onClick={() => toggle()}
                    target='_blank'
                    onMouseEnter={() => setIsShow(true)}
                    onMouseLeave={() => setIsShow(false)}
                    title='Listado de proyectos'
                    className='itemNavCC-icon'
                >
                    <IconProject height='25' width='25' fill={isShow ? '#FF8A1F' : '#545454'} />
                    <ModalProjectList open={open} setOpen={setOpen} setIsShow={setIsShow} />
                </a>
            )}
            {verify && (
                <a
                    onClick={adminVision}
                    className='icon-list-projects'
                    onMouseEnter={() => setIsShow1(true)}
                    onMouseLeave={() => setIsShow1(false)}
                    title='Listado de administrador'
                >
                    <IconAdmin height='25' width='25' fill={isShow1 ? '#FF8A1F' : '#545454'} />
                </a>
            )}
        </>
    )
}
