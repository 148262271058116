import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import moment from 'moment'
import { Loader } from 'components/Loader/Loader'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { isToday } from 'utils/Utils'
import { PrincipalGraphsMonophasic } from './PrincipalGraphsMonophasic'
import { PrincipalGraphsTriphasic } from './PrincipalGraphsTriphasic'
import './StyleLighting.scss'

export const ScadaPrincipalGraphs = ({ data, currentDevice, activeTab, triphasic, loading, setLoading, loadingCard, loose }) => {
    const location = useLocation()
    const yesterday = moment().subtract(1, 'days').format('DD-MM-YYYY')
    const now = moment().format('DD-MM-YYYY')
    const token = sessionStorage?.token
    const idCcMaster = location.pathname.split('/')[4]
    const [dataBar, setDataBar] = useState()
    const [eacons, setEacons] = useState([])
    const [eagen, setEagen] = useState([])
    const day = moment(currentDevice?.datetime)
    const noConect = !(moment().isSame(day, 'day') && moment().isSame(day, 'year') && moment().isSame(day, 'month'))
    const today = isToday(currentDevice?.currentData?.datetime, true)
    const labels = [yesterday, now]

    const fetchData = () => {
        setLoading(true)
        let url
        if (loose === 0) {
            url = `getDailyEnergyData/${data?.numserie}?idCCMaster=${idCcMaster}&iniDate=${moment().subtract(1, 'days').format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`
        } else {
            url = `getDailyEnergyData/${data?.numserie}?iniDate=${moment().subtract(1, 'days').format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`
        }
        if (url) {
            fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    let newOBject = { data: {}, nick: data?.nick, numserie: data?.numserie }
                    newOBject.data[yesterday] = data?.data?.[yesterday]
                        ? data?.data?.[yesterday]
                        : data?.[yesterday]
                          ? data?.[yesterday]
                          : { fecha: yesterday, eacons: 0, eagen: 0 }
                    newOBject.data[now] = data?.data?.[now]
                        ? data?.data?.[now]
                        : data?.[now]
                          ? data?.[now]
                          : { fecha: now, eacons: 0, eagen: 0 }
                    setDataBar(newOBject)
                })
                .catch((err) => console.error('ERROR. API Scada', err))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        if (data) {
            fetchData()
        }
    }, [data])

    useEffect(() => {
        if (dataBar) {
            const eaconsData = [dataBar.data[yesterday].eacons, dataBar.data[now].eacons]
            const eagenData = [dataBar.data[yesterday].eagen, dataBar.data[now].eagen]
            setEagen(eagenData)
            setEacons(eaconsData)
        }
    }, [dataBar])

    return (
        <Grid container columns={13}>
            {loading || loadingCard ? (
                <Grid item xs={13} className='containter-scada-graphs-center '>
                    <Loader />
                </Grid>
            ) : (
                <>
                    {triphasic && data && data !== null && (
                        <PrincipalGraphsTriphasic
                            activeTab={activeTab}
                            currentDevice={currentDevice}
                            data={data}
                            triphasic={triphasic}
                            eacons={eacons}
                            eagen={eagen}
                            labels={labels}
                            noConect={noConect}
                        />
                    )}
                    {!triphasic && data && data !== null && (
                        <PrincipalGraphsMonophasic
                            eacons={eacons}
                            eagen={eagen}
                            labels={labels}
                            noConect={noConect}
                            currentDevice={currentDevice}
                            today={today}
                        />
                    )}
                </>
            )}
        </Grid>
    )
}
