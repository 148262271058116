import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import moment from 'moment'
import CalendarComponent from 'components/Calendar/CalendarComponent'
import { GraphsBar } from 'components/Graph/GraphsBar'
import { GraphsLine } from 'components/Graph/GraphsLine'
import { Loader } from 'components/Loader/Loader'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { cambiarCabecera } from 'utils/funcionesCsv'
import { IconDownloadFile, OjoConsumo } from 'utils/icon-centrocontrol'
import { esHoy, compararFecha } from 'utils/Utils'
import { SelectorFecha } from 'views/SelectorCalendar/SelectorFecha'
import ConsuptionTable from '../Consumption/GraphConsumption/ConsuptionTable'
import { MenuInformesConsumo } from '../Consumption/GraphConsumption/MenuInformesConsumo'
import { formatLabel } from '../MABT/utils'
import { fieldIntensity, fieldPower, fieldTension } from './UtilsConsumptions'
import './StyleConsumption.scss'

export const ReportScada = ({ data, energyData, start, end, setStart, setEnd, daily, setDaily, resumeView, loose, loadingCard }) => {
    const token = sessionStorage?.token
    const location = useLocation()
    const idCcMaster = location.pathname.split('/')[4]
    const [loading, setLoading] = useState(true)
    const [graphData, setGraphData] = useState([])
    const [activeTabGraph, setActiveTabGraph] = useState(0)
    const [oneDay, setOneDay] = useState(true)
    const [graphVisibility, setGraphVisibility] = useState(true)
    const [dataSets, setDataSets] = useState([])
    const [eacons, setEacons] = useState([])
    const [eagen, setEagen] = useState([])
    const [labelEnergy, setLabelEnergy] = useState([])
    const [graphVisible, setGraphVisible] = useState(true)
    const [selectDaily, setSelectDaily] = useState('day')
    const valuesToCheck = [0, 1, 3]
    const namesPact = ['Potencia Total', 'Potencia Activa - L1', 'Potencia Activa - L2', 'Potencia Activa - L3']
    const colorPower = ['#078307', '#800080', '#ffa500', '#a52a2a']
    const colorTension = ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 206, 86)']
    const labels = graphData && graphData?.categories

    const fetchDataGraph = () => {
        setLoading(true)
        let url
        if (loose === 0) {
            url = `chart/getDataChartsCurves/${data?.numserie}?idCCMaster=${idCcMaster}`
        } else {
            url = `chart/getDataChartsCurves/${data?.numserie}`
        }
        const auxToday = start.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? true : false
        const powerField = fieldPower(data?.id_tipo_dispositivo)
        const intensityField = fieldIntensity(data?.id_tipo_dispositivo)
        const tensionField = fieldTension(data?.id_tipo_dispositivo)
        if (data) {
            fetch(urls.scada + url, {
                method: 'POST',
                redirect: 'follow',
                headers: headersScada(token),
                body: JSON.stringify({
                    iniDate: `${start.format('YYYY-MM-DD')}`,
                    endDate: `${end.format('YYYY-MM-DD')}`,
                    iniTime: `00:00`,
                    endTime: auxToday ? `${moment().format('HH:mm')}` : `23:59`,
                    interval: '5',
                    axis: [
                        {
                            idDeviceType: Number(data?.id_tipo_dispositivo),
                            fields: powerField,
                            numseries: [`${data?.numserie}`],
                            unit: 'kW'
                        },
                        {
                            idDeviceType: Number(data?.id_tipo_dispositivo),
                            fields: intensityField,
                            numseries: [`${data?.numserie}`],
                            unit: 'kW'
                        },
                        {
                            idDeviceType: Number(data?.id_tipo_dispositivo),
                            fields: tensionField,
                            numseries: [`${data?.numserie}`],
                            unit: 'kW'
                        }
                    ]
                })
            })
                .then((res) => res.json())
                .then((data) => {
                    setGraphData(data)
                })
                .catch((err) => {
                    console.error('ERROR. API Scada', err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const dataGraphs = (field) => {
        setDataSets([])
        let dataPact = graphData && graphData?.data?.filter((data) => data.name.includes(field))
        dataPact &&
            dataPact.forEach((datos, i) => {
                setDataSets((prev) => [
                    ...prev,
                    {
                        label:
                            field === 'pac'
                                ? namesPact[i]
                                : field === 'i'
                                  ? `Intensidad - L${i + 1}`
                                  : field === 'v'
                                    ? `Tensión - L${i + 1}`
                                    : datos.name,
                        data: datos.data,
                        borderColor: field === 'pac' ? colorPower[i] : field === 'v' ? colorTension[i] : colorPower[i + 1],
                        pointBackgroundColor: field === 'pac' ? colorPower[i] : field === 'v' ? colorTension[i] : colorPower[i + 1],
                        fill: false,
                        id: i + 5,
                        pointRadius: 0,
                        tension: 0.1,
                        numserie: `${datos.numserie}`
                    }
                ])
            })
    }

    const EnergyConsumption = () => {
        setEagen([])
        setEacons([])
        setLabelEnergy([])
        energyData &&
            energyData.forEach((dev) => {
                setEagen((prev) => [...prev, dev.eagendia])
                setEacons((prev) => [...prev, dev.eaconsdia])
                setLabelEnergy((prev) => [...prev, dev.datetime])
            })
    }

    useEffect(() => {
        if (start?.format('DD-MM-YYYY') !== end?.format('DD-MM-YYYY')) {
            setOneDay(false)
        } else {
            setOneDay(true)
        }
    }, [start, end])

    useEffect(() => {
        if (start?.format('DD-MM-YYYY') === end?.format('DD-MM-YYYY')) {
            fetchDataGraph()
        }
    }, [start, end, data])

    useEffect(() => {
        if (loadingCard && start?.format('DD-MM-YYYY') === end?.format('DD-MM-YYYY')) {
            setLoading(true)
        }
    }, [loadingCard])

    useEffect(() => {
        switch (activeTabGraph) {
            case 0:
                dataGraphs('pac')
                break
            case 1:
                dataGraphs('i')
                break
            case 3:
                dataGraphs('v')
                break
            default:
                break
        }
    }, [activeTabGraph, graphData])

    useEffect(() => {
        if (activeTabGraph === 2 && energyData) {
            EnergyConsumption()
        }
    }, [energyData, activeTabGraph])

    useEffect(() => {
        if (data && !resumeView) {
            let visible = compararFecha(start, end)
            setGraphVisible(visible)
            if (!visible) {
                setActiveTabGraph(2)
            }
        }
    }, [end, start])

    return (
        <Grid container>
            <Grid item xs={12}>
                <ul className='nav nav-tabs list-general mb-5'>
                    <li className='nav-item' button selected={true}>
                        <span className='nav-link active list-p'>Informes</span>
                    </li>
                </ul>
            </Grid>
            <Grid item xs={12} md={12} className={`tab-item-wrapper active`} index={0}>
                <Grid container className='report-scada-menuconsumo-calendar'>
                    <Grid item xs={12} md={8}>
                        <MenuInformesConsumo
                            id_tipo_dispositivo={data?.id_tipo_dispositivo}
                            dataGraphs={dataGraphs}
                            activeTab={activeTabGraph}
                            seleccionarTab={setActiveTabGraph}
                            graphVisible={graphVisible}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className='report-scada-select-calendar'>
                        <div>
                            <CalendarComponent
                                maxDate={end}
                                setStart={setStart}
                                setEnd={setEnd}
                                setDaily={setDaily}
                                setSelectDaily={setSelectDaily}
                            />
                        </div>
                        <div className='ml-3'>
                            <SelectorFecha daily={daily} setDaily={setDaily} tipo={daily} selectDaily={selectDaily} />
                        </div>
                        {energyData && (
                            <CSVLink
                                data={energyData ? cambiarCabecera(energyData, false, false, false, esHoy(start)) : []}
                                separator={';'}
                                style={{ color: 'white', marginTop: '3px' }}
                                filename={`${data?.nick}._${start ? start?.format('DD-MM-YYYY') : ' '}_${end ? end?.format('DD-MM-YYYY') : ' '}.csv`}
                            >
                                <HtmlTooltip title={<span>Descargar datos</span>}>
                                    <IconDownloadFile />
                                </HtmlTooltip>
                            </CSVLink>
                        )}
                    </Grid>
                </Grid>
                {loading || loadingCard ? (
                    <Loader />
                ) : (
                    <>
                        <Grid item xs={12} md={12} className='report-scada-viewgraph-date'>
                            <span className='report-scada-icon-view' onClick={() => setGraphVisibility(!graphVisibility)}>
                                <OjoConsumo onClick={() => setGraphVisibility(!graphVisibility)} />
                            </span>
                            {!oneDay && <h3 className='report-scada-date'>Desde</h3>}
                            <h3 className='report-scada-date'>{start?.format('DD-MM-YYYY')}</h3>
                            {!oneDay && <h3>hasta</h3>}
                            <h3 className='pl-2'>
                                {start?.format('DD-MM-YYYY') !== end?.format('DD-MM-YYYY') && end?.format('DD-MM-YYYY')}{' '}
                            </h3>
                        </Grid>
                        {graphVisibility && (
                            <Grid item xs={12} md={12} className={'powerMargins'}>
                                {valuesToCheck?.includes(activeTabGraph) && <GraphsLine data={dataSets} labels={formatLabel(labels)} />}
                                {activeTabGraph === 2 && <GraphsBar eacons={eacons} eagen={eagen} labels={labelEnergy} />}
                            </Grid>
                        )}
                        <Grid className='report-scada-table'>
                            <ConsuptionTable energyData={energyData} daily={daily} data={data} start={start} hoy={esHoy(start)} />
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    )
}
