import { createSlice } from '@reduxjs/toolkit'

export const configInstallSlice = createSlice({
    name: 'configInstall',
    initialState: true,
    reducers: {
        configInstall: (state, action) => {
            return !state
        }
    }
})

export const { configInstall } = configInstallSlice.actions

export default configInstallSlice.reducer
