import React, { useEffect, useState } from 'react'
import { BeatLoader } from 'react-spinners'
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { UserPermission } from 'views/User/Functions/userPermission'
import { obtenerNumeroTipoDispositivo, ordenarDatos } from 'utils/Utils'
import { IconTableDevice, IconShared } from 'utils/icon-centrocontrol'
import { ImageDevice } from 'utils/ImageDevice/ImageDevice'
import { urls } from 'utils/constant'
import { headersCentroControl } from 'services/headers'
import { Loader } from 'components/Loader/Loader'
import './principalIpMeter.scss'
import { IconDownloadFile } from 'utils/icon-centrocontrol'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { ModalTableDeviceDownload } from './ModalTableDeviceDownload'

export const PrincipalIpMeter = () => {
    const token = sessionStorage?.token
    const [devices, setDevices] = useState([])
    const [downDevices, setDownDevices] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [openRows, setOpenRows] = useState([])
    const [sortConfig, setSortConfig] = useState({ key: 'device_name', direction: 'asc' })
    const [childrenDevices, setchildrenDevices] = useState({})
    const [loadingChildren, setloadingChildren] = useState({})
    const [loadingDownload, setLoadingDownload] = useState(true)
    const [openModal, setOpenModal] = useState(false)

    const columns = [
        { label: 'Nombre', key: 'device_name' },
        { label: 'Tipo de dispositivo', key: 'tipo_de_dispositivo' },
        { label: 'Número de serie', key: 'numserie' },
        { label: 'Proyecto', key: 'project_name' },
        { label: 'Rol', key: 'permissions' },
        { label: 'Compartido', key: 'shared' }
    ]

    const columnsCSV = [
        { label: 'Nombre', key: 'device_name' },
        { label: 'Tipo de dispositivo', key: 'tipo_de_dispositivo' },
        { label: 'Número de serie', key: 'numserie' },
        { label: 'Proyecto', key: 'project_name' },
        { label: 'Master', key: 'nick_parent' },
        { label: 'Rol', key: 'permissions' },
        { label: 'Compartido', key: 'shared' }
    ]

    const fetchDevices = async () => {
        setIsLoading(true)
        try {
            const res = await fetch(urls.ccontrol + 'deviceInfo', {
                method: 'GET',
                headers: headersCentroControl('bearer' + token)
            })
            if (res.ok) {
                const data = await res.json()
                if (data) {
                    setDevices([...data])
                }
            }
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        } finally {
            setIsLoading(false)
        }
    }

    const fetchChildrenDevices = async (num_serie) => {
        setloadingChildren((prev) => ({
            ...prev,
            [num_serie]: true
        }))
        try {
            const res = await fetch(urls.ccontrol + `deviceInfoChildren/${num_serie}`, {
                method: 'GET',
                headers: headersCentroControl('bearer' + token)
            })
            if (res.ok) {
                const children = await res.json()
                if (children) {
                    setchildrenDevices((prevMap) => ({
                        ...prevMap,
                        [num_serie]: children
                    }))
                }
            }
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        } finally {
            setloadingChildren((prev) => ({
                ...prev,
                [num_serie]: false
            }))
        }
    }

    const handleClick = ({ num_serie, index }) => {
        setOpenRows((prev) => {
            if (prev.includes(index)) {
                return prev.filter((row) => row !== index)
            } else {
                fetchChildrenDevices(num_serie, index)
                return [...prev, index]
            }
        })
    }

    const fetchChildren = async (numserie) => {
        try {
            const res = await fetch(urls.ccontrol + `deviceInfoChildren/${numserie}`, {
                method: 'GET',
                headers: headersCentroControl('bearer' + token)
            })
            if (res.ok) {
                const children = await res.json()
                return children
            }
        } catch (error) {
            console.error('ERROR. API Centro Control', error)
        }
    }

    const handleSort = (sortColumn) => {
        const sortedDevices = [...devices]
        ordenarDatos(sortedDevices, sortConfig, setSortConfig, sortColumn)
        setDevices(sortedDevices)
        setOpenRows([])
    }

    const handleButtonDownload = async () => {
        setLoadingDownload(true)
        setDownDevices([])
        if (devices && devices.length > 0) {
            const allResults = await Promise.all(
                devices.map(async (dev) => {
                    setDownDevices((prev) => [...prev, dev])
                    const children = await fetchChildren(dev.numserie)
                    const formattedChildren = children.map((chlData) => ({
                        ...chlData,
                        nick_parent: dev?.device_name,
                        project_name: dev?.project_name
                    }))
                    return formattedChildren
                })
            )
            const flatResults = allResults.flat()
            setDownDevices((prev) => [...prev, ...flatResults])
        }
        setLoadingDownload(false)
    }

    useEffect(() => {
        fetchDevices()
    }, [])

    useEffect(() => {
        if (openModal) {
            handleButtonDownload()
        }
    }, [openModal])

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className='administration-box pt-2'>
                        <span className='header-projectList'>
                            <div className='header-title'>
                                <IconTableDevice height='28' width='28' />
                                <h2 className='title-section'>Mis dispositivos</h2>
                            </div>
                        </span>
                    </div>
                    <div className='button-table-device'>
                        <ButtonStyled className='button-with-text' onClick={() => setOpenModal(!openModal)}>
                            <IconDownloadFile className='icon-hover-with-text mr-1' width='20' height='20' /> Descargar Tabla dispositivos
                        </ButtonStyled>
                    </div>
                    <div className='table-container mb-4'>
                        <table className='custom-table'>
                            <thead className='thead-table'>
                                <tr>
                                    <th scope='col' className='p-4'></th>
                                    {columns.map((column) => (
                                        <th scope='col' className='pl-5 py-2' key={column.key} onClick={() => handleSort(column.key)}>
                                            {column.label}
                                            {sortConfig.key === column.key && (
                                                <FontAwesomeIcon
                                                    icon={sortConfig.direction === 'asc' ? faSortAmountDown : faSortAmountUp}
                                                    size='sm'
                                                    color='gray'
                                                    className='pl-2'
                                                />
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {devices &&
                                    devices.map((item, index) => (
                                        <>
                                            <tr className={`${index % 2 === 0 ? 'color-even' : 'color-odd'} tr-text`} key={index}>
                                                <td className='pl-5 row-width'>
                                                    <div className='icon-device-container'>
                                                        <ImageDevice
                                                            id_type_device={obtenerNumeroTipoDispositivo(item.tipo_de_dispositivo)}
                                                        />
                                                    </div>
                                                </td>
                                                <td className='pl-5 py-4 flex-space-between-center'>
                                                    {item.device_name ? item.device_name : <div></div>}
                                                    {loadingChildren[item.numserie] && <BeatLoader color='#ea5e0b' size={5} />}
                                                    {item.tipo_de_dispositivo !== 'ipMeter' &&
                                                        (openRows.includes(index) ? (
                                                            <ExpandLessIcon
                                                                className='cursor-pointer'
                                                                onClick={() => handleClick({ num_serie: item.numserie, index })}
                                                            />
                                                        ) : (
                                                            <ExpandMoreIcon
                                                                className='cursor-pointer'
                                                                onClick={() => handleClick({ num_serie: item.numserie, index })}
                                                            />
                                                        ))}
                                                </td>
                                                <td className='pl-5 py-4'>{item.tipo_de_dispositivo}</td>
                                                <td className='pl-5 py-4'>{item.numserie}</td>
                                                <td className='pl-5 py-4'>{item.project_name}</td>
                                                <td className='pl-5'>
                                                    <UserPermission project={item} />
                                                </td>
                                                <td className='flex items-center pl-5'>{item.shared === 0 ? '' : <IconShared />}</td>
                                            </tr>
                                            {openRows.includes(index) &&
                                                !loadingChildren?.[item.numserie] &&
                                                (childrenDevices[item.numserie]?.length === 0 ? (
                                                    <tr className='tr-text'>
                                                        <td className='row-width p-4'></td>
                                                        <td className='pl-5 py-4' key={index}>
                                                            No hay dispositivos conectados
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    childrenDevices[item.numserie]?.map((item, index) => (
                                                        <tr className='tr-text' key={index}>
                                                            <td className='pl-5 row-width'>
                                                                <div className='icon-device-container'>
                                                                    <ImageDevice
                                                                        id_type_device={obtenerNumeroTipoDispositivo(
                                                                            item.tipo_de_dispositivo
                                                                        )}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className='pl-5 py-4'>{item.device_name}</td>
                                                            <td className='pl-5 py-4'>{item.tipo_de_dispositivo}</td>
                                                            <td className='px-5 py-4'>{item.numserie}</td>
                                                            <td className='px-5 py-4'></td>
                                                            <td className='px-5 py-4'></td>
                                                        </tr>
                                                    ))
                                                ))}
                                        </>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            {openModal && (
                <ModalTableDeviceDownload
                    open={openModal}
                    setOpen={setOpenModal}
                    data={downDevices}
                    loading={loadingDownload}
                    columns={columnsCSV}
                />
            )}
        </>
    )
}
