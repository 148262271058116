import React, { useState, useEffect } from 'react'
import { Button, Dialog, Grid, DialogActions, Card } from '@mui/material'
import moment from 'moment'
import TimeWeather from './TimeWeather'
import { WeatherIcon } from './WeatherIcon'

export const ModalWeather = ({ weather, fullWeather, toggle, modal }) => {
    const [width, setWidth] = useState(window.innerWidth)
    const handleWindowSizeChange = () => setWidth(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    let isMobile = width <= 768
    const dateTime = new Date()
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    let lat, lon, cnt

    const [forecast, setForecast] = useState()

    useEffect(() => {
        lat = fullWeather?.coord?.lat
        lon = fullWeather?.coord?.lon
        cnt = 5
        getWeather(lat, lon, cnt)
    }, [])

    const getWeather = async (lat, lon, cnt) => {
        const API_KEY = 'e29c2dc6685d7a8a6313b05f5637c260'
        const API_URL = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&cnt=${cnt}&appid=${API_KEY}&lang=es`
        const response = await fetch(API_URL)
        const data = await response.json()
        setForecast(data)
    }

    return (
        <div>
            <Dialog scroll='body' maxWidth='sm' open={modal} classes={{ paper: 'modal-content border-0 text-center' }}>
                <div>
                    <Grid container spacing={0}>
                        <Grid
                            item
                            lg={4}
                            className='flex bg-gradient-danger p-2'
                            style={{ borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}
                        >
                            <div onClick={(e) => e.preventDefault()} className='card-img-wrapper rounded text-center mt-2'>
                                <h2 className='text-white'>{`${dateTime.toLocaleDateString('es-ES', options)}`}</h2>
                                <TimeWeather />
                                <WeatherIcon weather={weather} size={130} />
                                <h2 className='card__weather__temp text-white m-0 p-0'>{weather.temp_current}° C</h2>
                            </div>
                        </Grid>
                        <Grid item lg={isMobile ? 12 : 8} className='today-info'>
                            <div className='title'>
                                <a href onClick={(e) => e.preventDefault()} className='font-size-xxl d-block mb-4'>
                                    Previsión del tiempo en <b> {weather.city}</b>
                                </a>
                            </div>
                            <hr />
                            <div>
                                <span className='title'>Humedad:</span>
                                <span className='value'>{weather.humidity}%</span>
                                <div className='clear'></div>
                            </div>
                            <div>
                                <span className='title'>Viento:</span>
                                <span className='value'>{weather.wind} km/h</span>
                                <div className='clear'></div>
                            </div>
                            <div className='flex'>
                                {forecast &&
                                    forecast.list?.map((forecasts, f) => {
                                        moment.locale('es')

                                        const time = moment(forecasts.dt_txt).format('HH:mm')
                                        const currentTemp = (forecasts.main.temp - 273.15).toFixed(0)
                                        return (
                                            <Card key={f} className='flex align-items-center mt-4 mr-1 p-2 mb-4'>
                                                <div className='avatar-icon-wrapper avatar-icon-lg text-center'>
                                                    <div className='avatar-icon' style={{ marginLeft: 'auto' }}>
                                                        <WeatherIcon weather={forecasts.weather[0]} size={150} />
                                                    </div>
                                                    <h5>{currentTemp}° C</h5>
                                                    <small>{time}</small>
                                                </div>
                                            </Card>
                                        )
                                    })}
                            </div>
                            <DialogActions>
                                <Button onClick={toggle} size='small' className='btn-outline-dark mr-3'>
                                    Cerrar
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </div>
    )
}
