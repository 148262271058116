import React from 'react'
import { FormControl, Grid, MenuItem } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import Map from 'components/Mapa/Map'
import { SelectStyled } from 'components/Select/SelectStyled'
import { convertDatetime } from 'utils/Utils'

export const FormEditSensor = ({ body, setBody, errors, medidores, positions, fetchGetAllPositionsMed }) => {
    const onSelectMarker = (lat, lng, adres) => {
        const bodyObject = { ...body }
        bodyObject.lat = lat === null ? '' : lat
        bodyObject.lng = lng === null ? '' : lng
        bodyObject.address = adres
        setBody(bodyObject)
    }

    const handleChange = (e) => {
        if (e.target.name === 'medidor_asociado') {
            fetchGetAllPositionsMed(e.target.value)
            setBody({ ...body, position: -1, [e.target.name]: e.target.value })
        } else {
            setBody({ ...body, [e.target.name]: e.target.value })
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} className='flex justify-content-center align-items-center'>
                <Grid item xs={12} lg={12} className='m-1'>
                    <div className='pb-1'>
                        <small className='color-black'>Nombre:</small>
                    </div>
                    <InputStyled
                        error={errors?.nick?.length > 0}
                        errorMessage={errors?.nick}
                        maxLength={100}
                        fullWidth
                        name='nick'
                        value={body?.nick}
                        eventComplete={true}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className='flex justify-content-center align-items-center'>
                <Grid item xs={12} lg={6} className='m-1'>
                    <div className='pb-1'>
                        <small className='color-black'>Número de serie:</small>
                    </div>
                    <InputStyled disabled fullWidth value={body?.numserie} />
                </Grid>
                <Grid item xs={12} lg={6} className='m-1'>
                    <div className='pb-1'>
                        <small className='color-black'>Fecha de Registro:</small>
                    </div>
                    <InputStyled disabled fullWidth value={convertDatetime(body?.fecha_sync)} />
                </Grid>
            </Grid>
            <Grid item xs={12} className='flex justify-content-center align-items-center'>
                <Grid item xs={12} lg={12} className='m-1'>
                    <FormControl className='width-100' size='small'>
                        <div className='pb-1'>
                            <small className='color-black'>Línea Asociada:</small>
                        </div>
                        <SelectStyled value={body?.medidor_asociado} onChange={handleChange} id='medidor_asociado' name='medidor_asociado'>
                            {medidores
                                ?.filter((device) => device?.id_tipo_dispositivo === 21)
                                ?.map((medidor) => {
                                    return (
                                        <MenuItem value={medidor?.numserie}>{medidor?.nick + ' - Línea ' + medidor?.mabt_output}</MenuItem>
                                    )
                                })}
                        </SelectStyled>
                        {errors?.medidor_asociado && <p className='color-red-error'>{errors?.medidor_asociado}</p>}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12} className='flex justify-content-center align-items-center'>
                <Grid item xs={12} lg={12} className='m-1'>
                    <FormControl className='width-100' size='small'>
                        <div className='pb-1'>
                            <small className='color-black'>Orden Dentro de la Línea*:</small>
                        </div>
                        <SelectStyled value={body?.position} onChange={handleChange} id='position' name='position'>
                            {positions?.map((pos) => {
                                return <MenuItem value={pos}>{pos}</MenuItem>
                            })}
                        </SelectStyled>
                        {errors?.position && <p className='color-red-error'>{errors?.position}</p>}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12} className='flex justify-content-center align-items-center'>
                <Grid item xs={12} lg={12} className='m-1'>
                    <div className='pb-1'>
                        <small className='color-black'>Localización:</small>
                    </div>
                    <Map
                        center={{ lat: Number(body?.lat) ?? 36, lng: Number(body?.lng) ?? 10 }}
                        onSelectMarker={onSelectMarker}
                        zoom={5}
                        address={body?.address}
                        messageError={errors?.address && <p className='color-red-error'>{errors?.address}</p>}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
