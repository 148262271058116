import { configureStore } from '@reduxjs/toolkit'
import alarmaReducer from './alarmaSlice'
import configInstallReducer from './configInstallSlice'
import dataSensorReducer from './dataSensorSlice'
import filterAlarmReducer from './filterAlarmSlice'
import infoInstalReducer from './infoInstalSlice'
import instalacionReducer from './instalacionSlice'
import loadingReducer from './loadingSlice'
import logosReducer from './logosSlice'
import mapReducer from './mapSlice'
import permissionReducer from './permissionSlice'
import refreshDeviceReducer from './refreshDeviceSlice'
import refreshNavFooterReducer from './refreshNavFooterSlice'
import sensorReducer from './sensorSlice'
import toastReducer from './toastSlice'
import treeDevicesReducer from './treeDevicesSlice'

export default configureStore({
    reducer: {
        alarma: alarmaReducer,
        instalacion: instalacionReducer,
        refreshNavFooter: refreshNavFooterReducer,
        permission: permissionReducer,
        loading: loadingReducer,
        toast: toastReducer,
        treeDevices: treeDevicesReducer,
        infoInstal: infoInstalReducer,
        filterAlarm: filterAlarmReducer,
        refreshDevice: refreshDeviceReducer,
        dataSensor: dataSensorReducer,
        sensor: sensorReducer,
        map: mapReducer,
        configInstall: configInstallReducer,
        logos: logosReducer
    }
})
