import React from 'react'
import { BeatLoader } from 'react-spinners'
import { Card, Grid, Table } from '@mui/material'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { dispositivos } from 'utils/constant'
import { DeviceDate } from 'utils/DeviceDate/DeviceDate'
import { IconAlarmWith } from 'utils/icon-centrocontrol'
import { ImageDevice } from 'utils/ImageDevice/ImageDevice'
import { DropDownDevices } from 'views/Devices/MenuDevices/DropDownDevices'
import { datoNull } from './utils'

export const MabtHeaderCard = ({ device, masterPermission, alarmas, fecha, selectMedidor, scada, data, seleccionado, loadingData }) => {
    const numAlarm = alarmas.filter((element) => Number(element?.numserie) === device?.numserie)
    const vtn = device?.id_tipo_dispositivo === dispositivos.ccmabtv
    let masterPermissionCards = masterPermission[device?.mabt_serial] ? masterPermission[device?.mabt_serial] : masterPermission
    masterPermissionCards.alarms = alarmas
    let objFecha = { datetime: fecha }

    return (
        <Card
            className='card-mabt mt-2 ml-4 mr-4'
            style={{
                borderLeft: seleccionado === device?.numserie ? '5px solid #FF8A1F' : '1px solid #eee',
                borderRight: seleccionado === device?.numserie ? '5px solid #FF8A1F' : '1px solid #eee'
            }}
        >
            <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12} className='pr-2 pl-2'>
                    <Grid container>
                        <Grid item xs={vtn ? 8 : 11} md={vtn ? 8 : 11} lg={vtn ? 8 : 11} xl={vtn ? 8 : 11} className='flex-center mt-2'>
                            {!scada && <DropDownDevices device={device} masterPermission={masterPermission} />}
                            <ImageDevice className='pl-1' id_type_device={device.id_tipo_dispositivo} />
                            <h3 className='font-size-lg font-weight-bold m-0 ml-2'>
                                <a className='p-0' rel='noopener noreferrer' onClick={() => selectMedidor(device)}>
                                    {device?.nick ? device?.nick : vtn ? 'VTN' : 'Sin nombre ' + device.mabt_output}
                                </a>
                            </h3>
                        </Grid>
                        {vtn && (
                            <Grid
                                item
                                xs={numAlarm?.length > 0 ? 3 : 4}
                                md={numAlarm?.length > 0 ? 3 : 4}
                                lg={numAlarm?.length > 0 ? 3 : 4}
                                xl={numAlarm?.length > 0 ? 3 : 4}
                                className='content-center-groups accordion-icon position-relative flex-end mt-2'
                            >
                                <h4 className='m-0'>{datoNull(data?.currentData?.vtn)} V</h4>
                            </Grid>
                        )}
                        {numAlarm?.length > 0 && (
                            <Grid
                                item
                                xs={1}
                                md={1}
                                lg={1}
                                xl={1}
                                className='content-center-groups accordion-icon position-relative flex-end mt-2'
                            >
                                <HtmlTooltip title={<span>{numAlarm?.length}</span>}>
                                    <IconAlarmWith color='black' />
                                </HtmlTooltip>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className='pl-2 pr-2'>
                    <hr className='header-divisor m-0' />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className='flex justify-content-end pl-2 pr-2'>
                    <DeviceDate device={device} masterPermission={masterPermissionCards} data={objFecha} fontSize={12} />
                </Grid>
                {scada && seleccionado === device.numserie && (
                    <Grid container className='p-2' style={{ textAlign: 'start' }}>
                        {!vtn && (
                            <>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    <h4>
                                        Potencia total :{' '}
                                        {loadingData ? (
                                            <BeatLoader color='#ea5e0b' size={5} />
                                        ) : [data?.currentData?.pac1, data?.currentData?.pac2, data?.currentData?.pac3].every(
                                              (value) => value === null
                                          ) ? (
                                            '-'
                                        ) : (
                                            datoNull(data?.currentData?.pac1 + data?.currentData?.pac2 + data?.currentData?.pac3)
                                        )}{' '}
                                        kW
                                    </h4>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    <h4>
                                        Corriente total :{' '}
                                        {loadingData ? (
                                            <BeatLoader color='#ea5e0b' size={5} />
                                        ) : [data?.currentData?.i1, data?.currentData?.i2, data?.currentData?.i3].every(
                                              (value) => value === null
                                          ) ? (
                                            '-'
                                        ) : (
                                            datoNull(data?.currentData?.i1 + data?.currentData?.i2 + data?.currentData?.i3)
                                        )}{' '}
                                        A
                                    </h4>
                                </Grid>
                                <Table style={{ textAlign: 'center', padding: '0px', marginTop: '10px' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }} className='p-0'>
                                                {' '}
                                            </th>
                                            <th style={{ width: '22%' }} className='p-0'>
                                                P(kW)
                                            </th>
                                            <th style={{ width: '22%' }} className='p-0'>
                                                I(A)
                                            </th>
                                            <th style={{ width: '22%' }} className='p-0'>
                                                V(V)
                                            </th>
                                            <th style={{ width: '22%' }} className='p-0'>
                                                PF
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize: '13px' }}>
                                        <tr style={{ backgroundColor: '#EDEDED', borderTop: '1px solid black' }}>
                                            <td style={{ width: '10%', textAlign: 'start', color: '#FF8A1F', fontWeight: 'bold' }}>R</td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.pac1)}
                                            </td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.i1)}
                                            </td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.v1)}
                                            </td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.pf1)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '10%', textAlign: 'start', color: '#FF8A1F', fontWeight: 'bold' }}>S</td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.pac2)}
                                            </td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.i2)}
                                            </td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.v2)}
                                            </td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.pf2)}
                                            </td>
                                        </tr>
                                        <tr style={{ backgroundColor: '#EDEDED' }}>
                                            <td style={{ width: '10%', textAlign: 'start', color: '#FF8A1F', fontWeight: 'bold' }}>T</td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.pac3)}
                                            </td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.i3)}
                                            </td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.v3)}
                                            </td>
                                            <td style={{ width: '22%' }}>
                                                {loadingData ? <BeatLoader color='#ea5e0b' size={5} /> : datoNull(data?.currentData?.pf3)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </>
                        )}
                    </Grid>
                )}
            </Grid>
        </Card>
    )
}
