import React, { useEffect, useState } from 'react'
import { Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import { adjustEventTimes, hours, convertWeekDay, formatTime } from 'utils/Utils'
import { styles } from './TableDataCalendarCCMasterStyle'

export const DownloadTablePDF = ({ master, title, dates, data }) => {
    const calendarEvents = adjustEventTimes(data)
    const [isLightPreviouslyOn, setIsLightPreviouslyOn] = useState(false)
    const [calendarEventsToDownload, setCalendarEventsToDownload] = useState([])

    let isDrawingLine = false
    let firstEvent = null

    // Función para filtrar los eventos diarios según las fechas
    const filterDailyEvents = (data, weekDay, formattedDate) => {
        const isUndefinedRangeDates =
            (data?.start_date === '' && data?.end_date === '') || (data?.start_date === undefined && data?.end_date === undefined)
        const isUndefinedStartDate = data?.start_date === '' || data?.start_date === undefined
        const isWeeklyEvent = data?.week?.length > 0 && data?.week?.includes(convertWeekDay(weekDay))
        const isSpecificDayEvent = data?.week?.length === 0 && data?.day === formattedDate
        const eventStartDateFormatted = moment(data?.start_date)?.format('YYYY-MM-DD')
        const eventEndDateFormatted = moment(data?.end_date)?.format('YYYY-MM-DD')

        if (data?.enable) {
            if (isUndefinedRangeDates && isSpecificDayEvent) return true
            if (isUndefinedRangeDates && isWeeklyEvent) return true
            if (!isUndefinedRangeDates) {
                if (isUndefinedStartDate && formattedDate <= eventEndDateFormatted && isWeeklyEvent) return true
                if (
                    formattedDate >= eventStartDateFormatted &&
                    formattedDate <= eventEndDateFormatted &&
                    (isWeeklyEvent || isSpecificDayEvent)
                )
                    return true
            }
        }
        return false
    }

    // Función para manejar el estado de la luz y los timestamps
    const handleLightAndTimestamps = (event) => {
        if (Number(event?.internal_mode) === 100 && !firstEvent) {
            isDrawingLine = true
            firstEvent = event
            return true
        } else if (Number(event?.internal_mode) === 103 && isDrawingLine) {
            isDrawingLine = false
            firstEvent = null
            return true
        }
        return false
    }

    useEffect(() => {
        const tempEventsToShow = []
        const tempEventsToShowLastMonth = []

        // Obtener la fecha inicial seleccionada y el mes anterior
        const firstSelectedDate = moment(dates[0], 'DD/MM/YYYY')?.format('YYYY-MM-DD')
        const currentMonth = moment(firstSelectedDate)?.month()
        const monthBeforeDate = moment(firstSelectedDate)?.subtract(1, 'month')

        // Generar las fechas del mes anterior
        const lastMonthDates = []
        for (let m = monthBeforeDate?.clone().startOf('month'); m?.isBefore(monthBeforeDate?.endOf('month')); m?.add(1, 'days')) {
            lastMonthDates?.push(m?.clone()?.format('YYYY-MM-DD'))
        }

        // Filtrar los eventos del mes anterior
        lastMonthDates?.forEach((date) => {
            const formattedDate = moment(date, 'YYYY-MM-DD')?.format('YYYY-MM-DD')
            const weekDay = moment(formattedDate)?.format('dddd')?.toLowerCase()
            const lastMonthDailyEvents = calendarEvents?.filter((data) => filterDailyEvents(data, weekDay, formattedDate))
            tempEventsToShowLastMonth?.push({ date, events: lastMonthDailyEvents })
        })

        const getLastEventFromPreviousMonth = (previousMonthEvents) => {
            let lastMonthEvent = null

            for (let i = previousMonthEvents?.length - 1; i >= 0; i--) {
                const dailyEvents = previousMonthEvents[i]?.events

                if (dailyEvents?.length > 0) {
                    lastMonthEvent = dailyEvents?.reduce((lastEvent, currentEvent) => {
                        const lastEventTime = formatTime(lastEvent)
                        const currentEventTime = formatTime(currentEvent)
                        return currentEventTime > lastEventTime ? currentEvent : lastEvent
                    }, dailyEvents[0])

                    break
                }
            }
            return lastMonthEvent
        }

        // Buscar el último evento habilitado del mes anterior o de los eventos programados
        if (tempEventsToShowLastMonth?.every((day) => day?.events?.length === 0)) {
            firstEvent = calendarEvents?.find((event) => event?.enable)
            const eventMonth = moment(firstEvent?.start_date || firstEvent?.day, 'YYYY-MM-DD').month()
            if (eventMonth === currentMonth) {
                firstEvent = null
            }
        } else {
            firstEvent = getLastEventFromPreviousMonth(tempEventsToShowLastMonth)
        }

        // Si el primer evento es `internal_mode 100`, marcar que la luz estaba encendida previamente
        if (firstEvent?.internal_mode === 100) {
            setIsLightPreviouslyOn(true)
        }

        // Iterar las fechas seleccionadas
        dates?.forEach((date) => {
            const formattedDate = moment(date, 'DD/MM/YYYY')?.format('YYYY-MM-DD')
            const weekDay = moment(formattedDate)?.format('dddd')?.toLowerCase()
            const dailyEvents = calendarEvents?.filter((data) => filterDailyEvents(data, weekDay, formattedDate))

            dailyEvents?.forEach((event) => {
                handleLightAndTimestamps(event)
            })

            tempEventsToShow?.push({ date, events: dailyEvents })
        })

        setCalendarEventsToDownload(tempEventsToShow)
    }, [dates])

    return (
        <View style={styles.tableContainer}>
            <Text style={styles.tableTitleMaster}>{master}</Text>
            <Text style={styles.tableTitle}>{title}</Text>
            <View style={styles.table}>
                <View style={styles.tableHeader}>
                    <View style={styles.dateCol}>
                        <Text style={styles.tableCell}>Horas / Fechas</Text>
                    </View>
                    {hours?.map((hour, index) => (
                        <View style={styles.tableColHeader} key={index}>
                            <Text style={styles.tableCell}>{hour}</Text>
                        </View>
                    ))}
                </View>

                {calendarEventsToDownload?.map(({ date, events }, dateIndex) => {
                    let prevStyle = false
                    return (
                        <View style={styles.tableRow} key={dateIndex}>
                            <View style={styles.dateCol}>
                                <Text style={styles.tableCell}>{date}</Text>
                            </View>
                            {hours?.map((_, hourIndex) => {
                                const filteredEvents = events?.filter(
                                    (event) =>
                                        event?.hour === hourIndex &&
                                        (event?.priority || !events?.some((e) => e?.hour === hourIndex && e?.priority))
                                )
                                let cellContent = null
                                let cellStyle = null

                                if (isLightPreviouslyOn && !isDrawingLine && dateIndex === 0 && hourIndex === 0) {
                                    isDrawingLine = true
                                }

                                filteredEvents?.forEach((event) => {
                                    const shouldShowTimestamp = handleLightAndTimestamps(event)
                                    if (shouldShowTimestamp) {
                                        cellContent = `${event.hour}:${event.min.toString().padStart(2, '0')}`
                                    }
                                })

                                cellStyle = isDrawingLine
                                    ? styles.greenLine
                                    : filteredEvents?.length > 0 && Number(filteredEvents[0]?.internal_mode) === 103 && cellContent
                                      ? styles.redEvent
                                      : null
                                if (prevStyle === cellStyle) {
                                    cellContent = ''
                                }
                                prevStyle = cellStyle

                                return (
                                    <View style={styles.tableCol} key={hourIndex}>
                                        <Text style={[styles.tableCell, cellStyle]}>{cellContent}</Text>
                                    </View>
                                )
                            })}
                        </View>
                    )
                })}
            </View>
        </View>
    )
}
