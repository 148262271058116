import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersCentroControl, headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { IconReplace } from 'utils/icon-centrocontrol'
import { validatorNumber } from 'utils/ValidatorForm'

export const ModalReplaceDevice = ({ modal, setModal, device, token }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)

    const [numserie, setNumserie] = useState('')
    const [invalidNumSerie, setInvalidNumSerie] = useState(false)
    const [messageError, setMessageError] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)

    const handleChangeNumserie = (valor) => {
        setNumserie(valor)
        setInvalidNumSerie(validatorNumber(valor))
        setMessageError('')
    }

    const functReplace = () => {
        fecthSearchExistDevice()
    }

    const fecthSearchExistDevice = async () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + 'canInstall/', {
            method: 'POST',
            headers: headersCentroControl(token),
            body: JSON.stringify({ numserie: numserie })
        })
            .then((res) => {
                status = res.status
                return res.json()
            })
            .then(async (data) => {
                if (data) {
                    await fecthReplaceDevice()
                } else {
                    setMessageError('Compruebe el número de serie introducido.')
                    setLoading(false)
                    setInvalidNumSerie(true)
                }
                dispatch(createToast({ status: status, message: data.message, reload: true }))
            })
            .catch((err) => console.error('ERROR. API Centro de Control', err))
    }

    const fecthReplaceDevice = async () => {
        let status
        let message
        fetch(urls.scada + `replace/mabt/${device.numserie}?idCCMaster=${device.numserie}`, {
            method: 'PATCH',
            headers: headersScada(token),
            body: JSON.stringify({ new: Number(numserie) })
        })
            .then((res) => {
                status = res.status
                if (res.status === 200) {
                    message = 'Dispositivo reemplazado correctamente'
                } else {
                    message = 'Error al reemplazar el dispositivo'
                }
                setLoading(false)
                dispatch(createToast({ status: status, message: message, reload: true }))
                setNumserie('')
                setInvalidNumSerie(false)
            })
            .catch((err) => console.error('ERROR. API Scada', err))
    }

    useEffect(() => {
        if (numserie === '' || invalidNumSerie || numserie.length < 10) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [invalidNumSerie, numserie])

    useEffect(() => {
        setNumserie('')

        setInvalidNumSerie(false)
        setIsDisabled(true)
    }, [modal])

    return (
        <>
            <Modal
                open={modal}
                title={`Reemplazar MABT "${device?.nick}"`}
                iconTitle={<IconReplace className='mr-1' />}
                setOpen={setModal}
                onClick={(e) => e.stopPropagation()}
                buttonAction='Guardar'
                functSave={functReplace}
                disabled={isDisabled}
                loading={loading}
            >
                <Grid item xs={12} md={12} lg={12} xl={12} className='pr-5 pl-5'>
                    <small className='font-weight-lighter color-black'>Número de serie:</small>
                    <InputStyled
                        fullWidth={true}
                        value={numserie}
                        onChange={handleChangeNumserie}
                        maxLength={10}
                        error={invalidNumSerie}
                        errorMessage={messageError}
                    />
                </Grid>
            </Modal>
        </>
    )
}
