import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { headersCentroControl } from 'services/headers'
import { urls } from 'utils/constant.jsx'
import { VerificationCodeMaster } from '../VerificationCodeMaster/VerificationCodeMaster'

export const ModalDeleteMaster = ({ modal, setModal, ...props }) => {
    const [secondModal, setSecondModal] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const token = sessionStorage?.token
    const toggle = () => setModal(!modal)
    const toggle2 = () => setSecondModal(!secondModal)
    let message = localStorage.getItem('email')

    const sendCode = () => {
        setLoading(true)
        fetch(urls.ccontrol + `validateAction/device`, {
            method: 'PATCH',
            redirect: 'follow',
            headers: headersCentroControl(token),
            body: JSON.stringify({
                numserie: props.master.numserie,
                address: message,
                nick: props?.master?.nick,
                action: 'delete',
                idccmaster: props.master.numserie
            })
        })
            .then((res) => {
                if (res.ok) {
                    setLoading(false)
                    toggle2()
                    setLoading(false)
                } else {
                    setLoading(false)
                    setErrorModal(true)
                }
            })
            .catch((err) => console.error('ERROR. API Centro de Control', err))
    }

    return (
        <>
            <Modal
                open={modal}
                title={`Eliminar instalación "${props?.master?.nick}"`}
                setOpen={setModal}
                onClick={(e) => e.stopPropagation()}
                buttonAction='Eliminar'
                functSave={sendCode}
                loading={loading}
            >
                <Grid item xs={12} className='font-weight-lighter text-center mt-2'>
                    <h3 style={{ fontSize: '1.2rem' }} className='font-weight-lighter'>
                        ¿Deseas eliminar la instalación?
                    </h3>
                    {errorModal && <p className='color-red'>Ha ocurrido un error, intentelo de nuevo más tarde </p>}
                </Grid>
            </Modal>
            {secondModal && (
                <VerificationCodeMaster
                    toggle={toggle}
                    toggle2={toggle2}
                    secondModal={secondModal}
                    numserie={props.master.numserie}
                    setSecondModal={setSecondModal}
                    method='DELETE'
                    order='delete'
                />
            )}
        </>
    )
}
