import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { apiCentroControl } from 'services/api'
import { IconOnline, EnerSim } from 'utils/icon-centrocontrol'
import { validatorInput, validatorNumber } from 'utils/ValidatorForm'
import './modalSim.scss'

export const ModalAddNewSim = ({ open, setOpen }) => {
    const dispatch = useDispatch()

    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [imei, setImei] = useState()
    const [validImei, setValidImei] = useState(false)
    const [name, setName] = useState()
    const [loading, setLoading] = useState(false)
    const [validName, setValidName] = useState(false)

    const updateSimData = async ({ serialNumber, name }) => {
        setLoading(true)
        try {
            const response = await apiCentroControl.patch('siminfo', {
                numserie_sim: serialNumber,
                plantName: name
            })

            if (response?.status === 200) {
                dispatch(createToast({ status: response?.status, message: 'Tarjeta activada correctamente', reload: false }))
            }
        } catch (err) {
            dispatch(createToast({ status: 400, message: 'Error al activar la tarjeta', reload: false }))
            console.error('ERROR. API Centro Control', err)
        } finally {
            setLoading(false)
            setOpen((current) => !current)
        }
    }

    const handleUpdateSimData = () => {
        updateSimData({
            serialNumber: imei,
            name: name
        })
    }

    const handleNameChange = (e) => {
        setName(e)
        setValidName(validatorInput(e))
    }

    const handleImeiChange = (e) => {
        setImei(e)
        setValidImei(validatorNumber(imei))
    }

    useEffect(() => {
        setName(null)
        setImei(null)
        setValidName(false)
        setValidImei(false)
    }, [open])

    useEffect(() => {
        if (name && !validName && !validImei && imei && imei?.length === 20) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }
    }, [name, imei, validName, validImei])

    return (
        <>
            <Modal
                onClick={(e) => e.stopPropagation()}
                open={open}
                setOpen={setOpen}
                title={`Activar enerSim`}
                iconTitle={<EnerSim />}
                functSave={handleUpdateSimData}
                disabled={isButtonDisabled}
                buttonAction='Activar'
                loading={loading}
            >
                <Grid container className='px-5 mt-2'>
                    <Grid className='pb-3' item xs={12}>
                        <small className='color-black'>Nombre:</small>
                        <InputStyled value={name} maxLength={100} onChange={handleNameChange} fullWidth={true} error={validName} />
                    </Grid>
                    <Grid className='pb-3' item xs={12}>
                        <small className='color-black mt-3'>IMEI:</small>
                        <InputStyled value={imei} maxLength={20} onChange={handleImeiChange} fullWidth={true} error={validImei} />
                    </Grid>
                    <div className='small-text'>
                        <span>Debe indicar los códigos de 6 y 14 dígitos asociados a la tarjeta</span>
                    </div>
                    <Grid item xs={12} className='flex-center mt-3'>
                        <IconOnline color='#73BF45' width='17' />
                        <p className='ml-2 activation-text'>La tarjeta se activará en las siguientes 24 horas</p>
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
