import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import MenuOptions from 'components/MenuOptions/MenuOptions'
import Navbar from 'components/Navbar/Navbar'
import { GlobalContext } from 'context/GlobalContext'
import { addLogoUser, addLogoProject, clearLogoProject } from 'reduxSlice/logosSlice'
import { savePermission } from 'reduxSlice/permissionSlice'
import { apiCentroControl, apiCentroControlLogo, apiScada } from 'services/api'
import { IconProfile, UserLogo, Home, Exit, IconCloseAllSessions, LogoMinerva, IconSupport } from 'utils/icon-centrocontrol'
import { jwtDecode } from 'utils/JwtUtils'
import { parseDefaultImg } from 'utils/MiddleWareProjects'
import { ItemNavCc } from 'views/NavBar/ItemNavCC'
import { listUsers } from 'views/User/Functions/Sesion'
import { ModalSoporte } from 'views/User/ImpersonateUser/ModalSoporte'
import { ModalLogOut } from 'views/User/Logout/ModalLogOut'
import { ModalOutAllSessions } from 'views/User/Logout/ModalOutAllSessions'

export const NavBar = ({ scada, centroControl }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { setProjectsCompleto, updateDevice, refreshApp, setLoadingApp, setActualProject } = useContext(GlobalContext)
    const navegation = location?.pathname?.split('/')[1]
    const root = location?.pathname?.split('/')[2]
    const token = sessionStorage?.token
    const idProject = location?.pathname?.split('/')[3]
    const idCcMaster = location?.pathname?.split('/')[4]
    const [openLogOut, setOpenLogOut] = useState(false)
    const [sessionOut, setSessionOut] = useState(false)
    const [loading, setLoading] = useState(false)
    const [verifyToken, setVerifyToken] = useState()
    const licenApp = JSON.parse(localStorage?.getItem(token))
    const [open1, setOpen1] = useState(false)
    const logos = useSelector((state) => state?.logos)
    const changeConfig = useSelector((state) => state?.configInstall)
    const refreshSlice = useSelector((state) => state?.refreshNavFooter?.name)
    const headerLogos = parseDefaultImg(logos?.user, 'Header')
    const projectHeaderLogos = parseDefaultImg(logos?.project, 'Header')
    const dispatch = useDispatch()
    const licence = licenApp?.licence
    const mailUser = listUsers(token)

    const handleLogin = () => {
        navigate('/auth/login')
    }

    const profile = () => {
        navigate('/admin/perfil')
    }

    const handleExitScada = () => {
        window.document.title = 'Dashboard - Enerclic'
        navigate('/projects/inicio')
    }

    const OptionsMenu = [
        navegation !== 'projects' && {
            title: 'Pantalla principal',
            icon: <Home className='h-4 w-4' />,
            onClick: () => handleExitScada()
        },
        {
            title: 'Mi cuenta',
            icon: <IconProfile className='h-3 w-5' />,
            onClick: profile
        },
        sessionStorage?.rol === 'support' &&
            !sessionStorage?.impersonate && {
                title: 'Cuenta cliente',
                icon: <IconSupport className='h-4 w-5' />,
                onClick: () => setOpen1((prev) => !prev)
            },
        {
            title: 'Cerrar sesión',
            icon: <Exit className='h-4 w-5' />,
            onClick: () => setOpenLogOut(!openLogOut)
        },
        {
            title: 'Cerrar sesiones abiertas',
            icon: <IconCloseAllSessions className='h-4 w-5' />,
            onClick: () => setSessionOut(!sessionOut)
        }
    ]?.filter(Boolean)

    const handleButton = () => {
        if (scada) {
            window.location.reload()
            window.scrollTo(0, 0)
        } else if (centroControl) {
            navigate(`/projects/inicio`)
            window.scrollTo(0, 0)
        }
    }

    const fetchGetLogo = async () => {
        try {
            const result = await apiCentroControlLogo.get('logo/0')
            if (result.status === 200) {
                dispatch(addLogoUser(result?.data))
            }
        } catch (err) {
            console.error('ERROR. API Centro de Control Logo', err)
        }
        if (idProject) {
            try {
                const result = await apiCentroControlLogo.get('logo/' + idProject)
                if (result.status === 200) {
                    dispatch(addLogoProject(result?.data))
                }
            } catch (err) {
                console.error('ERROR. API Centro de Control Logo', err)
            }
        } else {
            dispatch(clearLogoProject())
        }
    }

    const fecthProjects = async () => {
        try {
            const result = await apiCentroControl.get('userProjects')
            if (result.status === 200) {
                setVerifyToken(jwtDecode(token))
                setProjectsCompleto(result?.data)
            }
        } catch (err) {
            if (err.status === 400) {
                handleLogin()
            }
            console.error('ERROR. API Centro de Control', err)
        }
    }

    const fecthProjectsOld = async () => {
        try {
            const result = await apiScada.get('user')
            if (result.status === 200) {
                setVerifyToken(jwtDecode(token))
                setProjectsCompleto(result?.data)
            }
        } catch (err) {
            if (err.status === 400) {
                handleLogin()
            }
            console.error('ERROR. API Centro de Control', err)
        }
    }

    const fecthProject = async () => {
        try {
            const result = await apiCentroControl.get(`userInfoAdmin/${idProject}`)
            if (result.status === 200) {
                if (licenApp?.licence === 'premium') {
                    setActualProject(result?.data)
                } else {
                    setProjectsCompleto(result?.data)
                }
            }
        } catch (err) {
            console.error('ERROR. API Centro de Control', err)
        }
    }

    const fecthScada = async () => {
        try {
            const result = await apiCentroControl.get(`getInfoInstallation/${idCcMaster}/${idProject}`)
            if (result.status === 200) {
                setActualProject(result?.data)
                if (licenApp?.licence === 'free') {
                    setProjectsCompleto(result?.data)
                }
            }
        } catch (err) {
            console.error('ERROR. API Centro de Control', err)
        }
    }

    const userPermissions = async () => {
        try {
            const result = await apiCentroControl.get('listaction')
            if (result.status === 200) {
                dispatch(savePermission({ permission: result?.data }))
            }
        } catch (err) {
            console.error('ERROR. API Centro de Control', err)
        }
    }

    useEffect(() => {
        async function fetchData() {
            const licenApp = JSON.parse(localStorage.getItem(token))
            if (navegation === 'projects') {
                setLoading(true)
                setLoadingApp(true)
                setActualProject({})
                if (licenApp?.licence === 'premium') {
                    await fecthProjects()
                } else {
                    await fecthProjectsOld()
                }
                setLoading(false)
                setLoadingApp(false)
            }
            if (navegation === 'admin' && root === 'inicio') {
                setLoading(true)
                setLoadingApp(true)
                await fecthProjects()
                await fecthProject()
                setLoading(false)
                setLoadingApp(false)
            }
            if (navegation === 'view' || navegation === 'scada') {
                setLoading(true)
                setLoadingApp(true)
                await fecthScada()
                if (licenApp?.licence === 'premium') {
                    await fecthProjects()
                }
                setLoading(false)
                setLoadingApp(false)
            }
            await userPermissions()
        }
        fetchData()
    }, [updateDevice, refreshApp, navegation])

    useEffect(() => {
        if (navegation === 'admin' && root === 'inicio') {
            fecthProject()
        }
    }, [changeConfig])

    useEffect(() => {
        fetchGetLogo()
    }, [refreshSlice, root])

    return (
        <Navbar
            companyLogos={[...headerLogos, ...projectHeaderLogos]}
            logo={<LogoMinerva width='168' height='55' className='cursor-pointer' onClick={() => handleButton()} />}
        >
            <span className='icon-mail-user'>
                {licence !== 'free' && <ItemNavCc navegation={navegation} verify={verifyToken} />}
                <span>
                    <MenuOptions selector={<UserLogo className='logo-user' height='25' width='25' />} options={OptionsMenu} />
                    <ModalLogOut open={openLogOut} setOpen={() => setOpenLogOut(!openLogOut)} />
                    <ModalOutAllSessions open={sessionOut} setOpen={() => setSessionOut(!sessionOut)} />
                    <ModalSoporte open1={open1} setOpen1={() => setOpen1(!open1)} />
                </span>
                {!loading && <h3 className='font-bold pl-3'>{sessionStorage?.name ? `Hola, ${sessionStorage.name}` : mailUser}</h3>}
            </span>
        </Navbar>
    )
}
