import React, { useContext, useEffect, useState } from 'react'
import { Grid, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import InputStyled from 'components/Input/InputStyled'
import { Loader } from 'components/Loader/Loader'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { StyledTableCell, StyledTableRow, StyledTableCellHead } from 'components/Table/TableStyle'
import { GlobalContext } from 'context/GlobalContext'
import { IconSave } from 'utils/icon-centrocontrol'
import { ModalChangeAliasCalendar } from 'views/Configuracion/Calendar/Modal/ModalChangeAliasCalendar'
import { ModalOnOffManualControl } from 'views/Configuracion/Calendar/Modal/ModalOnOffManualControl'

export const TableManualControl = ({
    idCcMaster,
    dataRelay,
    loading,
    setLoading,
    data,
    loadingOn,
    setLoadingOn,
    index,
    setIndex,
    checkedInternal,
    pulse,
    setPulse,
    keySwitch,
    setUpdateCalendar,
    centroControl,
    setKeySwitch,
    setUpdateCaledar,
    setLoadingAlias
}) => {
    const { setUpdateDevice } = useContext(GlobalContext)
    const [state, setState] = useState()
    const [open, setOpen] = useState(false)
    const [modal, setModal] = useState(false)
    const [config, setConfig] = useState()
    const [aliasEnter, setAliasEnter] = useState(data?.name)
    const [tableData, setTableData] = useState()

    const internal_calendar = [
        {
            value: 100,
            label: 'Normal'
        },
        {
            value: 101,
            label: 'Pulso'
        }
    ]

    const handleModo = (mode) => {
        if (mode === 100 || mode === 103) {
            return 100
        } else if (mode === 101 || mode === 102) {
            return 101
        } else {
            return 100
        }
    }

    const handleEditModo = (mode) => {
        if (mode === 100 || mode === 103) {
            return 103
        } else if (mode === 101 || mode === 102) return 101
    }

    const handleActivateMode = (mode, value) => {
        if (mode === 100) {
            return value + 1
        } else if (mode === 102) {
            return (value = 100)
        } else if (mode === 103) {
            return (value = 101)
        } else if (mode === 101) {
            return (value = 103)
        }
    }

    const handleEdit = (index, field, value, prev) => {
        const updatedData = [...tableData]
        updatedData[index][field] = handleActivateMode(prev, value)
        setTableData(updatedData)
        setIndex(index)
    }

    const pulseOrigin = () => {
        setPulse(true)
        setTimeout(() => {
            setPulse(false)
            setKeySwitch(Date.now())
        }, 1000)
    }

    const handleChangeNick = (e, i, prev) => {
        tableData[i].name = e
        if (!tableData[i].prevName) {
            tableData[i].prevName = prev
        }
        setAliasEnter(tableData[i].name)
    }

    const isButtonDisabled = (i) => {
        return (
            aliasEnter === tableData?.[i]?.prevName ||
            tableData?.[i]?.name === dataRelay?.[`out${i + 1}_alias`] ||
            tableData[i].name === tableData[i]?.prevName ||
            !tableData[i]?.prevName
        )
    }

    useEffect(() => {
        setTableData(data)
    }, [data])

    return (
        <>
            {!loading ? (
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCellHead align='center'>Alias</StyledTableCellHead>
                                    <StyledTableCellHead align='center'>Salida</StyledTableCellHead>
                                    <StyledTableCellHead align='center'>Modo</StyledTableCellHead>
                                    <StyledTableCellHead align='center'>Apagar/Encender</StyledTableCellHead>
                                    <StyledTableCellHead align='center'>Pulso encendido</StyledTableCellHead>
                                    <StyledTableCellHead align='center'>Pulso apagado</StyledTableCellHead>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData &&
                                    tableData?.map((control, i) => {
                                        return (
                                            <StyledTableRow key={i}>
                                                <StyledTableCell className='flex justify-content-center align-items-center' key={index}>
                                                    <InputStyled
                                                        size='small'
                                                        label='Alias*'
                                                        placeholder='Alias*'
                                                        fullWidth
                                                        type='text'
                                                        defaultValue={control?.name}
                                                        onClick={() => {
                                                            if (!tableData[i].prevName) {
                                                                tableData[i].prevName = control.name
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            handleChangeNick(e, i, control.name)
                                                        }}
                                                        variant='outlined'
                                                        inputProps={{ maxLength: 30 }}
                                                    />
                                                    <ButtonStyledIcon
                                                        className='ml-1'
                                                        disabled={isButtonDisabled(i)}
                                                        onClick={() => {
                                                            setOpen(!open)
                                                            setConfig(tableData[i])
                                                        }}
                                                        key={i}
                                                    >
                                                        <IconSave />
                                                    </ButtonStyledIcon>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <p>Relay {i + 1}</p>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        select
                                                        value={handleModo(control.internal_mode)}
                                                        defaultValue={handleModo(control.internal_mode)}
                                                        onChange={(e) =>
                                                            handleEdit(
                                                                i,
                                                                'internal_mode',
                                                                handleEditModo(e.target.value),
                                                                control?.internal_mode
                                                            )
                                                        }
                                                        variant='outlined'
                                                    >
                                                        {internal_calendar.map((option, i) => (
                                                            <MenuItem key={i} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <span className='flex align-center justify-content-center'>
                                                        <SwitchStyled
                                                            className='switch-small'
                                                            disabled={control?.internal_mode === 101 || control?.internal_mode === 102}
                                                            checked={checkedInternal(control?.internal_mode)}
                                                            onClick={(e) => {
                                                                setConfig(control)
                                                                setState(e.target.checked ?? false)
                                                                setModal(!modal)
                                                                setIndex(i)
                                                            }}
                                                        />
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <span className='flex align-center justify-content-center'>
                                                        <SwitchStyled
                                                            className='switch-small'
                                                            disabled={
                                                                control?.internal_mode === 100 ||
                                                                control?.internal_mode === 102 ||
                                                                control?.internal_mode === 103
                                                            }
                                                            key={keySwitch}
                                                            checked={control?.internal_mode === 101 && pulse && index === i}
                                                            onClick={() => {
                                                                setConfig(control)
                                                                setIndex(i)
                                                                setModal(!modal)
                                                            }}
                                                        />
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    styled={{
                                                        opacity:
                                                            control?.internal_mode === 103 || control.internal_mode === 101 ? '0.6' : '1'
                                                    }}
                                                >
                                                    <span className='flex align-center justify-content-center'>
                                                        <SwitchStyled
                                                            className='switch-small'
                                                            disabled={
                                                                control?.internal_mode === 103 ||
                                                                control?.internal_mode === 101 ||
                                                                control?.internal_mode === 100
                                                            }
                                                            checked={control?.internal_mode === 102 && pulse && index === i}
                                                            key={keySwitch}
                                                            onClick={() => {
                                                                setConfig(control)
                                                                setIndex(i)
                                                                setModal(!modal)
                                                            }}
                                                        />
                                                    </span>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            ) : (
                <Loader />
            )}
            {open && (
                <ModalChangeAliasCalendar
                    setAlias={setAliasEnter}
                    dataRelay={dataRelay}
                    setUpdateCalendar={setUpdateCalendar}
                    centroControl={centroControl}
                    setLoadingAlias={setLoadingAlias}
                    alias={aliasEnter}
                    data={config}
                    idCcMaster={idCcMaster}
                    loading={loading}
                    setLoading={setLoading}
                    setUpdateDevice={setUpdateDevice}
                    setOpen={setOpen}
                    open={open}
                    setUpdateCaledar={setUpdateCaledar}
                />
            )}
            {modal && (
                <ModalOnOffManualControl
                    setKeySwitch={setKeySwitch}
                    dataRelay={dataRelay}
                    centroControl={centroControl}
                    state={state}
                    pulseAction={pulseOrigin}
                    loadingOn={loadingOn}
                    setLoadingOn={setLoadingOn}
                    data={config}
                    table={true}
                    idCcMaster={idCcMaster}
                    loading={loading}
                    setLoading={setLoading}
                    setUpdateDevice={setUpdateDevice}
                    setUpdateCalendar={setUpdateCalendar}
                    modal={modal}
                    setModal={setModal}
                    pulse={pulse}
                />
            )}
        </>
    )
}
