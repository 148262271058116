import React from 'react'
import { BeatLoader } from 'react-spinners'
import Grid from '@mui/material/Grid'
import bluewArrow from 'assets/img/gifs/4-bluewArrow.gif'
import greenArrow from 'assets/img/gifs/4-greenArrow.gif'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { alarmas } from 'utils/constant'
import { IconVtnT, IconAlarmWith, IconOnline, IconOffline } from 'utils/icon-centrocontrol'
import { truncString, showDate, nombreAlarma } from 'utils/Utils'
import { DropDownDevices } from 'views/Devices/MenuDevices/DropDownDevices'
import { calcularPorcentajeColumna, calcularPorcentajeFila, calcularSumaFila, datoNull } from './utils'
import './styleMabt.scss'

export const MabtLineasDatosMovil = ({
    confAlarms,
    loading,
    datosLineas,
    vtn,
    total,
    selectMedidor,
    vtnDevice,
    masterPermission,
    devices,
    alarms,
    activeTabDevice,
    selectTab
}) => {
    return (
        <Grid container className='lineas-mabt mb-3'>
            <Grid item className='mr-5 ml-3' xs={12}>
                {/* filas */}
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((itemFila) => {
                    let dato = datosLineas.find((medidor) => medidor.position === itemFila)
                    let device = devices?.childrenDevices?.find((ele) => Number(ele?.numserie) === Number(dato?.numserie))
                    let numAlarm = alarms?.filter((ele) => Number(ele?.numserie) === Number(device?.numserie))
                    let alarmComunicaction = numAlarm?.find((ele) => ele?.alarm_id === alarmas.comunicacion)
                    let alarmConfigurated = confAlarms?.find((ele) => ele?.alarm_id === alarmas.comunicacion)
                    return (
                        <Grid
                            container
                            columns={22}
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            className='mb-5 position-relative'
                        >
                            <Grid item xs={3} key={itemFila + 'MabtColumnasheader'}>
                                {/* header */}
                                <Grid container className='flex-center justify-center header-line-data-mabt pr-1'>
                                    <Grid item xs={1} md={1} lg={1} xl={1} className='dropDownOptions'>
                                        {dato && <DropDownDevices device={device} masterPermissionNew={masterPermission} />}
                                    </Grid>
                                    <Grid item xs={9} md={9} lg={9} xl={9}>
                                        <div className='header-movil'>
                                            <p className='text-start header-box-texto-movil'>
                                                {dato && (
                                                    <HtmlTooltip title={<span>{showDate(dato?.fecha)}</span>}>
                                                        <a className='p-0' rel='noopener noreferrer' onClick={() => selectMedidor(dato)}>
                                                            {dato?.nick ? truncString(dato?.nick, 25) : 'Sin nombre ' + itemFila}
                                                        </a>
                                                    </HtmlTooltip>
                                                )}
                                            </p>
                                        </div>
                                    </Grid>
                                    {dato && (
                                        <Grid item xs={2} md={2} lg={2} xl={2}>
                                            {!alarmComunicaction && alarmConfigurated && (
                                                <HtmlTooltip title={<span>Comunicando</span>}>
                                                    <IconOnline width={15} height={15} />
                                                </HtmlTooltip>
                                            )}
                                            {alarmComunicaction && alarmConfigurated && (
                                                <HtmlTooltip title={<span>Sin comunicación</span>}>
                                                    <IconOffline width={15} height={15} />
                                                </HtmlTooltip>
                                            )}
                                            {numAlarm?.length > 0 && (
                                                <HtmlTooltip
                                                    title={
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                Alarmas activas: {numAlarm.length}
                                                            </Grid>
                                                            <ul>
                                                                {numAlarm.map((element) => {
                                                                    return (
                                                                        <li>
                                                                            <Grid item xs={12}>
                                                                                <span>{showDate(element?.datetime_active)}</span>
                                                                                <span>{' ' + nombreAlarma(element?.alarm_id)}</span>
                                                                            </Grid>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Grid>
                                                    }
                                                >
                                                    <span className='cursor-pointer' onClick={() => selectTab(1)}>
                                                        <IconAlarmWith color='black' />
                                                    </span>
                                                </HtmlTooltip>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                                {dato ? (
                                    <p className={`position-absolute text-start header-box-numero-movil header-movil-${itemFila}`}>
                                        {itemFila}
                                    </p>
                                ) : (
                                    <p className={`position-absolute text-start header-box-numero-movil header-movil-${itemFila}`}>
                                        {itemFila}
                                    </p>
                                )}
                            </Grid>
                            {itemFila === 1 &&
                                ['R', 'S', 'T'].map((itemColumna, idx) => {
                                    let valor = idx === 0 ? 'dato1' : idx === 1 ? 'dato2' : 'dato3'
                                    return (
                                        <Grid item xs={5} key={itemColumna + 'MabtColumnas'} className='columna-box-movil'>
                                            <Grid
                                                container
                                                justifyContent='flex-end'
                                                alignItems='flex-start'
                                                className='position-relative columna-inicio'
                                            >
                                                {/* columnas */}
                                                {datosLineas.slice(0, 10).map((dato, indexDatoLinea) => {
                                                    return (
                                                        <div
                                                            className={`position-absolute box-linea box-linea-movil-${indexDatoLinea + 1}`}
                                                        >
                                                            <p
                                                                className={`text-center box-texto ${dato[valor] !== null && dato[valor] === 0 ? 'number-cero' : ''} ${dato[valor] !== null && dato[valor] > 0 ? 'number-positivo' : ''} ${dato[valor] !== null && dato[valor] < 0 ? 'numero-negativo' : ''}`}
                                                            >
                                                                {' '}
                                                                {loading ? (
                                                                    <BeatLoader color='#ea5e0b' size={5} />
                                                                ) : (
                                                                    datoNull(dato[valor])
                                                                )}{' '}
                                                            </p>
                                                        </div>
                                                    )
                                                })}
                                                <div className='position-absolute fila-linea-movil'>
                                                    <h3 className='fila-texto-movil'>{itemColumna}</h3>
                                                    <img
                                                        alt='imgmabtlineasdatosmovil1'
                                                        src={
                                                            calcularSumaFila(
                                                                datosLineas,
                                                                itemFila === 'R' ? 'dato1' : itemFila === 'S' ? 'dato2' : 'dato3'
                                                            ) >= 0
                                                                ? bluewArrow
                                                                : greenArrow
                                                        }
                                                        className='fila-gif-movil rotar-imagen'
                                                    />
                                                    {activeTabDevice !== 2 && (
                                                        <div className='position-absolute fila-circulo-movil'>
                                                            <p className='text-center fila-texto-circulo'>
                                                                {loading ? (
                                                                    <BeatLoader color='#ea5e0b' size={5} />
                                                                ) : (
                                                                    calcularPorcentajeFila(datosLineas, valor, total) + '%'
                                                                )}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            <Grid item xs={itemFila === 1 ? 4 : 19} key={itemFila + 'MabtColumnas'} className='columna-box-movil'>
                                <div className={`columna-box-movil flex justify-content-between ${!dato ? 'mabt-line-no-used-movil' : ''}`}>
                                    <div className={`box-circulo-movil position-absolute ${!dato ? 'mabt-line-no-used-circle' : ''}`}>
                                        <p className='text-center box-circulo-texto'>
                                            {datosLineas[itemFila - 1] && activeTabDevice !== 2 && (
                                                <>
                                                    {' '}
                                                    {loading ? (
                                                        <BeatLoader color='#ea5e0b' size={5} />
                                                    ) : (
                                                        calcularPorcentajeColumna(datosLineas[itemFila - 1], total) + '%'
                                                    )}{' '}
                                                </>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid item xs={12} className='mt-4 position-relative flex-center justify-center'>
                    <div className='flex'>
                        <h3 className='pl-6 flex-baseline texto-vtn'>
                            <IconVtnT />
                            {vtnDevice ? (
                                <a className='ml-1 p-0 texto-vtn-enlace' rel='noopener noreferrer' onClick={() => selectMedidor(vtnDevice)}>
                                    VTN:
                                </a>
                            ) : (
                                <>VTN:</>
                            )}
                            <span className='ml-2 text-center box-texto'>
                                {' '}
                                {loading ? (
                                    <BeatLoader color='#ea5e0b' size={5} />
                                ) : (
                                    datoNull(vtn) + (datoNull(vtn) !== '-' ? ' V' : '')
                                )}{' '}
                            </span>
                        </h3>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}
