import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { ModalConfigBatteryEms } from './Modal/ModalConfigBatteryEms'

export const BateryEms = ({ configEms, master, setConfigEms }) => {
    const [bateryEnable, setBateryEnable] = useState(configEms?.battery?.enable)
    const [disabled, setDisabled] = useState(true)
    const [open, setOpen] = useState(false)

    const compareOptions = () => {
        let change = true
        if (configEms?.battery?.enable !== bateryEnable) {
            change = false
        }
        setDisabled(change)
    }

    useEffect(() => {
        compareOptions()
    }, [bateryEnable, configEms])

    return (
        <Grid container className='pl-3 mb-4'>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center'>
                <SwitchStyled
                    id='bateryenable'
                    className='switch-small'
                    checked={bateryEnable}
                    onClick={() => setBateryEnable(!bateryEnable)}
                />
                <label className='ml-2 mb-0' htmlFor='bateryenable'>
                    {' '}
                    Habilitar el gestor de baterías{' '}
                </label>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='button-apply-table flex justify-content-flex-end mt-3'>
                <ButtonStyled size='small' color='#fff' background='#FF8A1F' disabled={disabled} onClick={() => setOpen(true)}>
                    Aplicar
                </ButtonStyled>
            </Grid>
            {open && (
                <ModalConfigBatteryEms
                    master={master}
                    open={open}
                    setOpen={setOpen}
                    configEms={configEms}
                    bateryEnable={bateryEnable}
                    setConfigEms={setConfigEms}
                    setBateryEnable={setBateryEnable}
                />
            )}
        </Grid>
    )
}
