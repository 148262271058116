import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { TextField, Grid, MenuItem } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { dispositivos } from 'utils/constant.jsx'
import { validatorNumber, validatorInput } from 'utils/ValidatorForm'

export const ModalInfoSupervisor = ({ modal, setModal, ...props }) => {
    const [power, setPower] = useState(props.devices?.potencia_contratada)
    const [intensity, setIntensity] = useState(props.devices?.intensidad_magneto)
    const [notes, setNotes] = useState(props.devices?.notes)
    const [loading, setLoading] = useState(false)
    const [alias, setAlias] = useState(props?.devices?.nick)

    const [principal, setPrincipal] = useState(props.devices?.totalizador === 0 ? false : true)
    const [isValidNick, setIsValidNick] = useState(false)
    const [isValidNote, setIsValidNote] = useState(false)
    const [isvalidPower, setIsvalidPower] = useState(false)
    const [isvalidIntensity, setIsvalidIntensity] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const token = sessionStorage?.token
    const dispatch = useDispatch()

    const toggle = () => setModal(!modal)

    const [body, setBody] = useState({
        nick: alias,
        notes: notes,
        potencia_contratada: power,
        intensidad_magneto: intensity
    })

    useEffect(() => {
        if (alias === props.devices?.nick) {
            setBody({
                notes: notes,
                potencia_contratada: power,
                intensidad_magneto: intensity
            })
        } else {
            setBody({
                nick: alias,
                notes: notes,
                potencia_contratada: power,
                intensidad_magneto: intensity
            })
        }
    }, [alias, props.devices?.nick, power, intensity, principal, notes])

    let objForm = {}

    const createObj = () => {
        for (let [key, value] of Object.entries(body)) {
            if (value) {
                objForm[key] = value
            }
        }
    }

    const patchData = () => {
        setLoading(true)
        let status
        if (props.devices.numserie) {
            let url = `updateDeviceInfo/${props.devices?.numserie}`
            if (Number(props?.master?.id_tipo_dispositivo) === dispositivos.ccmaster) {
                url += `?idCCMaster=${props?.master?.numserie}`
            } else if (Number(props?.permissions?.deviceType) === dispositivos.ccmaster) {
                url += `?idCCMaster=${props?.permissions?.numserie}`
            } else if (Number(props?.instalacion?.id_tipo_dispositivo) === dispositivos.ccmabt) {
                url += `?idCCMaster=${props?.instalacion?.numserie}`
            }
            fetch(urls.scada + url, {
                method: 'PATCH',
                headers: headersScada(token),
                body: JSON.stringify(objForm)
            })
                .then((res) => {
                    setLoading(false)
                    status = res.status
                    return res.json()
                })
                .then((data) => {
                    dispatch(createToast({ status: status, message: data.message, reload: true }))
                    toggle()
                })
                .catch((err) => {
                    toggle()
                    console.error('ERROR. API Scada', err)
                })
        }
    }

    const handleCheck = () => {
        setPrincipal(!principal)
        setPower(null)
    }

    /**
     * @description validadores
     */
    const handleChangeNick = (e) => {
        setAlias(e)
        setIsValidNick(validatorInput(e))
    }

    const handleChangeNotes = (e) => {
        setNotes(e)
        setIsValidNote(validatorInput(e))
    }

    const handleChangePower = (e) => {
        setPower(e)
        setIsvalidPower(validatorNumber(e))
    }

    const handleChangeIntensity = (e) => {
        setIntensity(e)
        setIsvalidIntensity(validatorNumber(e))
    }

    useEffect(() => {
        if (isValidNick === true || isValidNote === true || isvalidPower === true || isvalidIntensity === true || alias.length === 0) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [isValidNick, isValidNote, isvalidPower, isvalidIntensity, alias])

    return (
        <>
            <Modal
                onClick={(e) => e.stopPropagation()}
                open={modal}
                setOpen={setModal}
                title={`Información del dispositivo "${props.devices?.nick}"`}
                functSave={() => {
                    createObj()
                    patchData()
                }}
                loading={loading}
                disabled={isValid}
            >
                <Grid container className='container-shared-cede-modal'>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <TextField
                            fullWidth
                            className='style-textfield-text text-center mb-3'
                            variant='outlined'
                            disabled
                            autoFocus
                            margin='dense'
                            label='Número de serie'
                            value={props?.type === 'free' ? props?.devices?.device?.numserie : props.devices?.numserie}
                            type='text'
                            name='numserie'
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <TextField
                            fullWidth
                            className='style-textfield-text text-center mb-3'
                            variant='outlined'
                            disabled
                            autoFocus
                            margin='dense'
                            label='Firmware de CCM'
                            value={props?.type === 'free' ? props?.devices?.device?.FW_version : props.devices?.FW_version}
                            type='text'
                            name='CCM_FW'
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <TextField
                            fullWidth
                            className='style-textfield-text text-center mb-3'
                            variant='outlined'
                            disabled
                            autoFocus
                            margin='dense'
                            label='Fecha de registro'
                            value={props?.type === 'free' ? props?.devices?.device?.fecha_sync : props.devices?.fecha_sync}
                            type='text'
                            name='fecha_sync'
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                        <TextField
                            error={isValidNick}
                            helperText={isValidNick && 'Caracteres no permitidos'}
                            fullWidth
                            className='style-textfield-text text-center mb-3'
                            variant='outlined'
                            placeholder={props?.type === 'free' ? props?.devices?.device?.nick : props?.devices?.nick}
                            value={alias}
                            defaultValue={props?.type === 'free' ? props?.devices?.device?.nick : props?.devices?.nick}
                            autoFocus
                            margin='dense'
                            label='Nombre del dispositivo'
                            multiline
                            id='filled-multiline-static'
                            type='text'
                            name='nick'
                            InputProps={{ inputProps: { maxLength: 65, min: 0 } }}
                            onChange={(e) => handleChangeNick(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} xl={8}>
                        <TextField
                            error={isvalidIntensity}
                            helperText={isvalidIntensity && 'Caracteres no permitidos'}
                            id='typeNumber'
                            fullWidth
                            className='style-textfield-text text-center mb-0'
                            variant='outlined'
                            name='intensity'
                            autoFocus
                            margin='dense'
                            label='Intensidad nominal del magneto-térmico (A)'
                            type='text'
                            defaultValue={
                                props?.type === 'free' ? props?.devices?.device?.intensidad_magneto : props.devices?.intensidad_magneto
                            }
                            value={props?.type === 'free' ? props?.devices?.device?.intensidad_magneto : intensity}
                            onChange={(e) => handleChangeIntensity(e.target.value)}
                            inputProps={{ pattern: '[0-9]+' }}
                        />
                        <small>Verifique en el magneto-térmico la intensidad máxima soportada</small>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} xl={4}>
                        <TextField
                            className='style-textfield-text text-center mt-2'
                            disabled
                            fullWidth
                            id=''
                            select
                            label='Tipo de medida'
                            size='small'
                            variant='outlined'
                        >
                            <MenuItem key='0' selected disabled value='selec'>
                                Selecciona{' '}
                            </MenuItem>
                            <MenuItem key='1' value='Medidor de consumo'>
                                Consumo
                            </MenuItem>
                            <MenuItem key='2' value='Medidor de generacion'>
                                Generación
                            </MenuItem>
                            <MenuItem key='3' value='Bidireccional'>
                                Bidireccional
                            </MenuItem>
                            <MenuItem key='4' value='otros'>
                                Otros
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={8} md={8} lg={8} xl={8}>
                        <TextField
                            error={isValidNote}
                            helperText={isValidNote && 'Caracteres no permitidos'}
                            fullWidth
                            className='style-textfield-text text-center mb-0'
                            variant='outlined'
                            name='notes'
                            autoFocus
                            margin='dense'
                            label='Notas'
                            type='text'
                            value={notes}
                            onChange={(e) => handleChangeNotes(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='mb-0'>
                        <Grid container spacing={1}>
                            <Grid
                                item
                                xs={1}
                                md={1}
                                lg={1}
                                xl={1}
                                className='justify-content-center'
                                style={{ margin: 'auto', textAlign: 'end' }}
                            >
                                <input
                                    className='text-center'
                                    id='customCheckLogin'
                                    type='checkbox'
                                    onChange={handleCheck}
                                    checked={principal}
                                />
                            </Grid>
                            <Grid item xs={11} md={11} lg={11} xl={11}>
                                <span style={{ fontSize: '0.9rem' }} className='text-muted'>
                                    Señale esta casilla SI el dispositivo mide el consumo total o la acometida principal (w)
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={4} md={4} lg={4} xl={4} className='mb-3'>
                                <TextField
                                    error={isvalidPower}
                                    helperText={isvalidPower && 'Caracteres no permitidos'}
                                    id='typeNumber'
                                    fullWidth
                                    size='small'
                                    className='style-textfield-text text-center mb-0'
                                    variant='outlined'
                                    autoFocus
                                    margin='dense'
                                    label='Potencia contratada (w)'
                                    value={props?.type === 'free' ? props?.devices?.device?.power : power}
                                    type='text'
                                    name='power'
                                    onChange={(e) => handleChangePower(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={8} md={8} lg={8} xl={8} className='mb-3'>
                                <small className='text-muted'>
                                    La potencia contratada aparece reflejada siempre en la factura de la luz
                                </small>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
