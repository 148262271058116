import React, { useEffect, useState } from 'react'
import { Grafica } from 'components/Graph/Grafica'

export const colores_graficas = [
    ['rgb(255, 99, 132)', 'rgb(255, 99, 132, 0.3)'],
    ['rgb(54, 162, 235)', 'rgb(54, 162, 235, 0.3)'],
    ['rgb(255, 206, 86)', 'rgb(255, 206, 86, 0.3)'],
    ['rgb(75, 192, 192)', 'rgb(75, 192, 192, 0.3)'],
    ['rgb(153, 102, 255)', 'rgb(153, 102, 255, 0.3)'],
    ['rgb(255, 159, 64)', 'rgb(255, 159, 64, 0.3)'],
    ['rgb(255, 0, 0)', 'rgb(255, 0, 0, 0.3)'],
    ['rgb(0, 128, 0)', 'rgb(0, 128, 0, 0.3)'],
    ['rgb(0, 255, 255)', 'rgb(0, 255, 255, 0.3)'],
    ['rgb(128, 0, 128)', 'rgb(128, 0, 128, 0.3)'],
    ['rgb(128, 128, 0)', 'rgb(128, 128, 0, 0.3)'],
    ['rgb(255, 0, 255)', 'rgb(255, 0, 255, 0.3)'],
    ['rgb(0, 0, 255)', 'rgb(0, 0, 255, 0.3)'],
    ['rgb(0, 255, 0)', 'rgb(0, 255, 0, 0.3)'],
    ['rgb(128, 128, 128)', 'rgb(128, 128, 128, 0.3)'],
    ['rgb(255, 255, 0)', 'rgb(255, 255, 0, 0.3)'],
    ['rgb(0, 0, 128)', 'rgb(0, 0, 128, 0.3)'],
    ['rgb(128, 0, 0)', 'rgb(128, 0, 0, 0.3)'],
    ['rgb(0, 128, 128)', 'rgb(0, 128, 128, 0.3)'],
    ['rgb(128, 0, 255)', 'rgb(128, 0, 255, 0.3)']
]

export const GraficaMabt = ({ datosGrafica, escalaTipo, tipoGrafica, activeTabDeviceMenuGrafica, minValue, maxValue }) => {
    const [graficaDatos, setGraficaDatos] = useState()
    const tipo = ['energia', 'energiaConsumida', 'energiaGenerada', 'vtn'].includes(tipoGrafica) ? 'bar' : 'line'

    function funcionTickY(value) {
        return Number(value)?.toFixed(2) + ' ' + escalaTipo
    }

    function procesarObjeto(objeto, i) {
        let nameMabt = ['R', 'S', 'T']
        let datos = objeto?.data
        let rMax = {
            name: objeto?.name ? objeto?.name + '_MAX' : nameMabt?.[i] + '_MAX',
            data: []
        }
        let rMin = {
            name: objeto?.name ? objeto?.name + '_MIN' : nameMabt?.[i] + '_MIN',
            data: []
        }
        for (let i = 0; i < datos?.length; i++) {
            rMax?.data?.push(datos[i] !== null ? datos[i][0] : null)
            rMin?.data?.push(datos[i] !== null ? datos[i][1] : null)
        }
        return [rMax, rMin]
    }

    useEffect(() => {
        let nameMabt = ['R', 'S', 'T']
        let grafica = []
        let i = 0
        datosGrafica?.data?.forEach((datos, index) => {
            if (Array?.isArray(datos?.data?.[0]) && tipoGrafica !== 'energia') {
                let [max, min] = procesarObjeto(datos, index)
                grafica.push({
                    label: max.name,
                    data: max.data,
                    borderColor: colores_graficas[i][0],
                    backgroundColor: colores_graficas[i][0],
                    pointRadius: 0
                })
                grafica.push({
                    label: min.name,
                    data: min.data,
                    borderColor: colores_graficas[i + 1][0],
                    backgroundColor: colores_graficas[i + 1][0],
                    pointRadius: 0
                })
                i = i + 2
            } else {
                let lab = ''
                if (datos?.nick) {
                    lab += datos?.nick
                    if (datos?.name) {
                        lab += ' - ' + datos?.name
                    }
                } else if (datos?.name) {
                    lab += datos?.name
                } else if (activeTabDeviceMenuGrafica) {
                    lab += 'Sin nombre ' + (i + 1)
                } else {
                    lab += nameMabt?.[i]
                }
                grafica?.push({
                    label: lab,
                    data: datos.data,
                    borderColor: colores_graficas[i][0],
                    backgroundColor: colores_graficas[i][0],
                    pointRadius: 0
                })
                i = i + 1
            }
        })
        if (minValue !== undefined) {
            grafica.push({
                label: 'Mínimo',
                data: Array(datosGrafica?.categories?.length).fill(minValue),
                borderColor: 'rgba(255, 0, 0, 0.5)',
                borderDash: [5, 5],
                pointRadius: 0,
                showInLegend: false
            })
        }
        if (maxValue !== undefined) {
            grafica.push({
                label: 'Máximo',
                data: Array(datosGrafica?.categories?.length).fill(maxValue),
                borderColor: 'rgba(0, 255, 0, 0.5)',
                borderDash: [5, 5],
                pointRadius: 0,
                showInLegend: false
            })
        }
        setGraficaDatos(grafica)
    }, [datosGrafica])

    return (
        <div style={{ height: '30em' }}>
            {graficaDatos && (
                <Grafica
                    tipo={tipo}
                    labels={datosGrafica?.categories}
                    datos={graficaDatos}
                    posicion='bottom'
                    zoom={true}
                    maintainAspectRatio={false}
                    scalesX={true}
                    scalesY={true}
                    funcionTickY={funcionTickY}
                />
            )}
        </div>
    )
}
