import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { GlobalContext } from 'context/GlobalContext'
import { clearFilterAlarm } from 'reduxSlice/filterAlarmSlice'
import { orderAsc } from 'utils/Utils'
import { validatorInput } from 'utils/ValidatorForm'
import { ProjectListFree } from './ProjectFree/ProjectListFree'
import { ProjectsListPremium } from './ProjectPremium/ProjectsListPremium'

export const ProjectsListNew = () => {
    const { projectsCompleto, updateDevice, loadingApp } = useContext(GlobalContext)
    const navigate = useNavigate()
    const [project, setProject] = useState({ name: '', category: '', permissions: 'admin' })
    const [searchResults, setSearchResults] = useState([])
    const [openGroup, setOpenGroup] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [color, setColor] = useState('#000000')
    const [inHover, setHover] = useState(false)
    const [numserie, setNumserie] = useState()
    const [open, setOpen] = useState(false)
    const token = sessionStorage?.token
    const licenApp = JSON.parse(localStorage.getItem(token))
    const dispatch = useDispatch()
    let licence = licenApp?.licence
    let isMobile = window.innerWidth <= 768

    useEffect(() => {
        window.document.title = 'Proyectos - Enerclic'
    }, [])

    const sendAndChange = (project) => {
        handleSelectedProject(project?.id_project, getCategoryID(project?.category))
        window.document.title = project?.nick
    }

    const handleSelectedGeneral = () => navigate(`/admin/users`)

    const handleSelectedProject = (id, category) => {
        const path = category === '1' ? `/admin/inicio/${id}/enerSIM` : `/admin/inicio/${id}/${category}`
        navigate(path)
    }

    const handleSelectedAlarm = (id) => {
        dispatch(clearFilterAlarm())
        navigate(`/admin/inicio/${id}/0}`, { state: { alarms: true } })
    }

    const getCategoryID = (type) => {
        if (type === 'enerSIM') {
            return '1'
        } else if (type === 'IP-Meter') {
            return '3'
        } else if (type === 'energ_community') {
            return '2'
        } else if (type === 'shared') {
            return '6'
        } else {
            return '0'
        }
    }

    const handleChange = (event) => {
        if (!validatorInput(event.target.value)) {
            setSearchTerm(event.target.value)
        }
    }

    useEffect(() => {
        if (projectsCompleto?.mensaje !== 'Project deleted satisfactory' && projectsCompleto?.length > 0) {
            const results = projectsCompleto?.filter((prj) => prj?.nick?.toLowerCase().includes(searchTerm.toLowerCase()))
            if (results.length !== searchResults.length) {
                orderAsc(results, 'nick')
                setSearchResults(results)
            }
        }
    }, [searchTerm, projectsCompleto, updateDevice])

    const openModal = (e) => {
        setOpen(true)
        e.stopPropagation()
    }

    const closeModal = (e) => {
        setOpen(false)
    }

    const openGroupModal = (e) => {
        setOpenGroup(true)
    }

    const closeGroupModal = (e) => {
        closeModal(e)
        setOpenGroup(false)
    }

    const goBack = () => {
        setOpenGroup(false)
    }

    return (
        <Grid container>
            {projectsCompleto?.length > 0 && loadingApp !== true && searchResults?.length > 0 ? (
                <div className='screen-project'>
                    {licence === 'premium' &&
                    projectsCompleto?.message !== 'User without info masters.' &&
                    projectsCompleto?.length !== 0 &&
                    projectsCompleto !== undefined ? (
                        <ProjectsListPremium
                            handleSelectedGeneral={handleSelectedGeneral}
                            openModal={openModal}
                            setOpenGroup={setOpenGroup}
                            color={color}
                            setColor={setColor}
                            project={project}
                            setProject={setProject}
                            isMobile={isMobile}
                            openGroupModal={openGroupModal}
                            closeModal={closeModal}
                            open={open}
                            searchResults={searchResults}
                            searchTerm={searchTerm}
                            handleChange={handleChange}
                            goBack={goBack}
                            handleSelectedAlarm={handleSelectedAlarm}
                            closeGroupModal={closeGroupModal}
                            openGroup={openGroup}
                            sendAndChange={sendAndChange}
                            setOpen={setOpen}
                        />
                    ) : (
                        <div>
                            <ProjectListFree
                                isMobile={isMobile}
                                numserie={numserie}
                                setNumserie={setNumserie}
                                setHover={setHover}
                                inHover={inHover}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <Grid item xs={12} className='flex-center justify-center'>
                    <Loader message={`Cargando los proyectos ...`} />
                </Grid>
            )}
        </Grid>
    )
}
