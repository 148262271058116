import React, { useEffect, useState } from 'react'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { Loader } from 'components/Loader/Loader'
import { apiCentroControl } from 'services/api'
import { IconNewObject, EnerSim } from 'utils/icon-centrocontrol'
import { ModalAddNewSim } from 'views/Modals/ModalSim/ModalAddNewSim'
import { ModalSwitchOffSim } from 'views/Modals/ModalSim/ModalSwitchOffSim'
import { EnerSimCard } from './EnerSimCard'
import './principalSim.scss'
import '../StylePrincipal.scss'

export const PrincipalSim = () => {
    const [enerSimData, setEnerSimData] = useState([])
    const [isLastEnerSim, setIsLastEnerSim] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openSwitchOffModal, setopenSwitchOffModal] = useState(false)
    const [openAddModal, setopenAddModal] = useState(false)

    const fetchEnerSim = async () => {
        setLoading(true)

        try {
            const response = await apiCentroControl.post('siminfo', {
                getAll: true
            })

            if (response?.status === 200) {
                if (response?.data) {
                    setEnerSimData([...response?.data])
                }
            }
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchEnerSim()
    }, [])

    useEffect(() => {
        setIsLastEnerSim(enerSimData.length === 1)
    }, [enerSimData])

    return (
        <>
            {loading ? (
                <Loader message={`Cargando el proyecto ...`} />
            ) : (
                <>
                    <div className='header-projectList mt-4 px-2'>
                        <div className='header-title'>
                            <EnerSim />
                            <h2 className='header-text'>Mis tarjetas enerSim</h2>
                        </div>
                        <div className='header-button-projectList'>
                            <ButtonStyled className='button-with-text' onClick={() => setopenAddModal(!openAddModal)}>
                                <IconNewObject className='icon-hover-with-text' />
                                <p className='ml-2'>Activar nueva Sim</p>
                            </ButtonStyled>
                        </div>
                    </div>
                    <div className='enersim-main-container'>
                        <div className={`enersim-container-${enerSimData.length === 0 ? 'center' : 'start'}`}>
                            {enerSimData.length === 0 && !loading ? (
                                <p className='text-no-cards'>No tiene tarjetas</p>
                            ) : (
                                enerSimData.map((item, index) => (
                                    <EnerSimCard
                                        key={index}
                                        data={item}
                                        cardName={item.installationName}
                                        imei={item.numserie_sim}
                                        connectionStatus={item.status}
                                        datetime={item.datetime}
                                        isLastEnerSim={isLastEnerSim}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </>
            )}
            <ModalAddNewSim open={openAddModal} setOpen={setopenAddModal} />
            <ModalSwitchOffSim open={openSwitchOffModal} setOpen={setopenSwitchOffModal} />
        </>
    )
}
