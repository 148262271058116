import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, FormControl, MenuItem, Table, TableContainer, TableHead, TableBody, TableRow } from '@mui/material'
import { ButtonModal } from 'components/Buttons/ButtonModal'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { Modal } from 'components/Modal/Modal'
import { SelectStyled } from 'components/Select/SelectStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { StyledTableCell, StyledTableRow, StyledTableCellHead } from 'components/Table/TableStyle'
import { createToast } from 'reduxSlice/toastSlice'
import { apiCentroControl } from 'services/api'
import { IconDeleteAlarm } from 'utils/icon-centrocontrol'
import '../EditInstall/EditInstall.scss'

export const MaintenanceModal = ({ open, setOpen, data, setKeySwitch }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [dispositivoSeleccionado, setDispositivoSeleccionado] = useState([])
    const [allSelected, setAllSelected] = useState(true)
    const [checkedItems, setCheckedItems] = useState({})
    const [activeTab, setActiveTab] = useState(0)
    const [versionTo, setVersionTo] = useState(' ')
    const [versions, setVersions] = useState([])

    const fetchOrder = async (serie) => {
        if (serie !== undefined) {
            try {
                const res = await apiCentroControl.post('addUpgrade', serie)
                if (res.status === 200) {
                    dispatch(createToast({ status: res.status, message: 'Petición enviada correctamente', reload: false }))
                    return true
                }
            } catch (err) {
                dispatch(createToast({ status: 400, message: 'Error al enviar la petición', reload: false }))
                console.error('ERROR. API Centro de control', err)
            } finally {
                setOpen(false)
                setKeySwitch(Date.now())
            }
        }
    }

    const arrayChecked = (device, numserie) => {
        const exists = dispositivoSeleccionado?.some((dev) => Number(dev?.numserie) === Number(numserie))
        if (!exists) {
            setDispositivoSeleccionado([
                ...dispositivoSeleccionado,
                {
                    nick: device?.nick,
                    numserie: device?.numserie,
                    actualVersion: device?.version,
                    updateTo: versionTo
                }
            ])
            setCheckedItems((prev) => ({ ...prev, [device?.numserie]: true }))
        } else {
            setDispositivoSeleccionado(dispositivoSeleccionado?.filter((item) => Number(item?.numserie) !== Number(numserie)))
            setCheckedItems((prev) => ({ ...prev, [device?.numserie]: false }))
        }
    }

    const fetchDataFwVersions = async () => {
        setLoading(true)
        try {
            let res = await apiCentroControl.get(`getversionsFW`)
            if (res.status === 200) {
                let json = res?.data
                setVersionTo(json[json?.length - 1])
                setVersions(json)
            }
        } catch (err) {
            console.error('ERROR. API centro de control', err)
        } finally {
            setLoading(false)
        }
    }

    const selectAll = (select) => {
        setDispositivoSeleccionado([])
        data?.forEach((device) => {
            if (select) {
                setAllSelected(true)
                setDispositivoSeleccionado((prev) => [
                    ...prev,
                    {
                        nick: device?.nick,
                        numserie: device?.numserie,
                        actualVersion: device?.version,
                        updateTo: versionTo
                    }
                ])
                setCheckedItems((prev) => ({ ...prev, [device?.numserie]: true }))
            } else if (!select) {
                setAllSelected(false)
                setDispositivoSeleccionado([])
                setCheckedItems((prev) => ({ ...prev, [device?.numserie]: false }))
            }
        })
    }

    const handleChange = (dataDelete) => {
        let filterDelete
        if (dispositivoSeleccionado?.filter((dev) => dev?.numserie === dataDelete?.target?.numserie)) {
            filterDelete = dispositivoSeleccionado?.filter((dev) => dev?.numserie !== dataDelete?.target?.numserie)
        }
        setDispositivoSeleccionado(filterDelete)
    }

    useEffect(() => {
        fetchDataFwVersions()
        selectAll(true)
    }, [])

    useEffect(() => {
        let allTrue = Object?.values(checkedItems)?.every((value) => value === true)
        if (!allTrue) {
            setAllSelected(false)
        } else {
            setAllSelected(true)
        }
    }, [checkedItems])

    return (
        <div>
            <Modal
                fullWidth={true}
                maxWidth={window.innerWidth < 665 ? window.innerWidth : 'sm'}
                open={open}
                setOpen={setOpen}
                title='Actualización de Firmware'
                footer={
                    <Grid container className='pr-5 pl-5'>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <div className='modal-footer-maintance-modal'>
                                {activeTab === 0 && (
                                    <ButtonModal
                                        fullWidth={true}
                                        loading={loading}
                                        messageLoading={false}
                                        className='button-cancel-modal mb-4'
                                        onClick={() => setOpen(!open)}
                                    >
                                        {window.innerWidth < 1200 ? 'Canc' : 'Cancelar'}
                                    </ButtonModal>
                                )}
                                {activeTab !== 0 && (
                                    <ButtonModal
                                        onClick={() => {
                                            setActiveTab(activeTab - 1)
                                            selectAll(true)
                                        }}
                                        fullWidth={true}
                                        loading={loading}
                                        className='button-cancel-modal mb-4'
                                    >
                                        {window.innerWidth < 1200 ? 'Atr' : 'Atrás'}
                                    </ButtonModal>
                                )}
                                <>
                                    <ButtonModal
                                        ButtonModal
                                        fullWidth={true}
                                        loading={loading}
                                        disabled={activeTab === 1 && dispositivoSeleccionado?.length === 0}
                                        className='button-ok-modal mb-4'
                                        onClick={() => {
                                            ;(activeTab === 0 || activeTab === 1) && setActiveTab(activeTab + 1)
                                            activeTab === 2 && fetchOrder(dispositivoSeleccionado)
                                        }}
                                    >
                                        {window.innerWidth < 1200 && (
                                            <>
                                                {(activeTab === 0 || activeTab === 1) && 'Sig'}
                                                {activeTab === 2 && 'Act'}
                                            </>
                                        )}
                                        {window.innerWidth >= 1200 && (
                                            <>
                                                {' '}
                                                {(activeTab === 0 || activeTab === 1) && 'Siguiente'}
                                                {activeTab === 2 && 'Actualizar'}
                                            </>
                                        )}
                                    </ButtonModal>
                                </>
                            </div>
                        </Grid>
                    </Grid>
                }
                onClick={(e) => e.stopPropagation}
            >
                {activeTab === 0 && (
                    <Grid item xs={12} className='modal-maintance-container '>
                        <Grid item xs={12} md={4} lg={4}>
                            <h3>Elige versión de firmware</h3>
                        </Grid>
                        <Grid item xs={12} md={8} lg={8}>
                            <FormControl className='width-100 pb-2' size='small'>
                                <SelectStyled value={versionTo} onChange={(e) => setVersionTo(e.target.value)} autoWidth>
                                    {versions?.length > 0 &&
                                        versions?.map((elm, key) => {
                                            if (elm) {
                                                return <MenuItem value={elm}>v.{elm}</MenuItem>
                                            } else {
                                                return null
                                            }
                                        })}
                                </SelectStyled>
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
                {(activeTab === 1 || activeTab === 2) && (
                    <Grid item xs={12} className='edit-maintance-content-table'>
                        <h3 className=' text-center font-weight-lighter '>
                            {activeTab === 1 ? 'Selecciona' : 'Revisa'} los dispositivos que deseas actualizar a la versión: &nbsp;{' '}
                            <p>{versionTo}</p>
                        </h3>
                        <TableContainer className='table-maintance-modal'>
                            <Table aria-label='customized table '>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCellHead align='center'>Dispositivo</StyledTableCellHead>
                                        <StyledTableCellHead align='center'>Nº serie</StyledTableCellHead>
                                        <StyledTableCellHead align='center'>Fw</StyledTableCellHead>
                                        <StyledTableCellHead align='center'>
                                            {activeTab === 1 && (
                                                <SwitchStyled
                                                    checked={allSelected}
                                                    className='switch-small'
                                                    onClick={() => {
                                                        selectAll(!allSelected)
                                                    }}
                                                />
                                            )}
                                        </StyledTableCellHead>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activeTab === 1 &&
                                        data &&
                                        data?.map((ele, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align='center'>{ele?.nick}</StyledTableCell>
                                                    <StyledTableCell align='center'>{Number(ele?.numserie)}</StyledTableCell>
                                                    <StyledTableCell align='center'>
                                                        {ele?.master_FW ? ele?.master_FW : ele?.version ? ele?.version : '-'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='center'>
                                                        <SwitchStyled
                                                            className='switch-small'
                                                            checked={checkedItems?.[Number(ele?.numserie)]}
                                                            onClick={() => {
                                                                arrayChecked(ele, Number(ele?.numserie))
                                                            }}
                                                        ></SwitchStyled>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    {activeTab === 2 &&
                                        dispositivoSeleccionado &&
                                        dispositivoSeleccionado?.map((ele, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align='left'>{ele?.nick}</StyledTableCell>
                                                    <StyledTableCell align='center'>{Number(ele?.numserie)}</StyledTableCell>
                                                    <StyledTableCell align='center'>
                                                        {ele?.master_FW ? ele.master_FW : ele?.actualVersion ? ele?.actualVersion : '-'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='center'>
                                                        <Grid item md={12}>
                                                            <ButtonStyledIcon
                                                                className='boton-historical-install'
                                                                title='Eliminar'
                                                                onClick={() =>
                                                                    handleChange({ target: { numserie: ele.numserie, checked: false } })
                                                                }
                                                            >
                                                                <IconDeleteAlarm className='boton-historical-hijo' />
                                                            </ButtonStyledIcon>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Modal>
        </div>
    )
}
