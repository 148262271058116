import React from 'react'
import { Grid, Typography } from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { es } from 'date-fns/locale'
import { ArrowLeft, ArrowRight } from 'utils/icon-centrocontrol'
import { CalendarButton } from './CalendarButton'
import './CalendarComponent.scss'

//Props del componente

// isRange, Props que abre el segundo calendario
// onClickArrowLeftFirstRow, Props que pasa la función onClick en la flecha izquierda de la pimera fila
// onClickTitleFirstRow, Props que pasa la función onClick en el título de la pimera fila
// titleFirstRow, Props que establece el nombre del título de la pimera fila
// onClickArrowRightFirstRow, Props que pasa la función onClick en la flecha derecha de la pimera fila
// disabledArrowRightFirstRow, Props para deshabilitar flecha derecha de la pimera fila
// onClickArrowLeftSecondRow,  Props que pasa la función onClick en la flecha izquierda de la segunda fila
// titleSecondRow, Props que establece el nombre del título de la segunda fila
// onClickTitleSecondRow, Props que pasa la función onClick en el título de la segunda fila
// onClickArrowRightSecondRow, Props que pasa la función onClick en la flecha derecha de la segunda fila
// disabledArrowRightSecondRow, Props para deshabilitar flecha derecha de la segunda fila
// value, Props que establece el valor del calendario
// onChange, Props que establece la función onChange del calendario
// minDate, Props que establece la fecha mínima del calendario
// maxDate, Props que establece la fecha máxima del calendario

export const CalendarDatePicker = ({
    isRange,
    onClickArrowLeftFirstRow,
    onClickTitleFirstRow,
    titleFirstRow,
    onClickArrowRightFirstRow,
    disabledArrowRightFirstRow,
    onClickArrowLeftSecondRow,
    titleSecondRow,
    onClickTitleSecondRow,
    onClickArrowRightSecondRow,
    disabledArrowRightSecondRow,
    value,
    onChange,
    minDate,
    maxDate
}) => {
    return (
        <Grid item xs={isRange ? 6 : 12}>
            {/* Primera fila de botones */}
            <div className='grid-calendar pb-2 pb-2'>
                <CalendarButton onClick={onClickArrowLeftFirstRow} className='btn-secondary' size='small' variant='contained'>
                    <ArrowLeft />
                </CalendarButton>
                <CalendarButton className='btn-transparent btn-link mr-4 ml-4' size='small'>
                    <Typography textAlign='center' onClick={onClickTitleFirstRow} className='typography-main-btn '>
                        {titleFirstRow}
                    </Typography>
                </CalendarButton>
                <CalendarButton
                    onClick={onClickArrowRightFirstRow}
                    className='btn-secondary'
                    size='small'
                    variant='contained'
                    disabled={disabledArrowRightFirstRow}
                >
                    <ArrowRight />
                </CalendarButton>
            </div>
            {/* Segunda fila de botones */}
            <div className='grid-calendar'>
                <CalendarButton onClick={onClickArrowLeftSecondRow} className='btn-secondary' size='small' variant='contained'>
                    <ArrowLeft />
                </CalendarButton>
                <CalendarButton>
                    <Typography
                        textAlign='center'
                        onClick={onClickTitleSecondRow}
                        className='btn-transparent btn-link typography-main-btn mr-4 ml-4'
                        size='small'
                    >
                        {titleSecondRow}
                    </Typography>
                </CalendarButton>
                <CalendarButton
                    onClick={onClickArrowRightSecondRow}
                    className='btn-secondary'
                    size='small'
                    variant='contained'
                    disabled={disabledArrowRightSecondRow}
                >
                    <ArrowRight />
                </CalendarButton>
            </div>
            <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
                <Grid container justifyContent='center' className='pt-2'>
                    <DateCalendar
                        value={value}
                        onChange={onChange}
                        minDate={minDate}
                        maxDate={maxDate}
                        className='calendar'
                        slotProps={{
                            calendarHeader: {
                                sx: {
                                    display: 'none'
                                }
                            }
                        }}
                    />
                </Grid>
            </LocalizationProvider>
        </Grid>
    )
}
