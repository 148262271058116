// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPowerContainer .PowerDoughnutPositive {
  position: relative;
  right: 23%;
  top: 29%;
}
.mainPowerContainer .PowerDoughnutPositive canvas {
  width: 80% !important;
  height: 80% !important;
}
.mainPowerContainer .PowerDoughnutNegative {
  position: relative;
  top: -47%;
  left: 19%;
  transform: scaleX(-1);
}
.mainPowerContainer .PowerDoughnutNegative canvas {
  width: 80% !important;
  height: 80% !important;
}
.mainPowerContainer .actualPowerMediciones {
  position: relative;
  top: -8%;
  left: -8%;
}
.mainPowerContainer .powerTitle {
  position: relative;
  top: -65%;
}

.powerGraphdivider .divider {
  position: relative;
  top: 35%;
  background: "black";
  height: 3px;
  width: 40px;
}`, "",{"version":3,"sources":["webpack://./src/views/Scada/Consumption/GraphConsumption/ScadaConsumptionPower/PhasesActivepower.scss"],"names":[],"mappings":"AASI;EACI,kBAAA;EACA,UAAA;EACA,QAAA;AARR;AASQ;EACI,qBAAA;EACA,sBAAA;AAPZ;AAUI;EACI,kBAAA;EACA,SAAA;EACA,SAAA;EACA,qBAAA;AARR;AASQ;EACI,qBAAA;EACA,sBAAA;AAPZ;AAcI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;AAZR;AAeI;EACI,kBAAA;EACA,SAAA;AAbR;;AAiBI;EACI,kBAAA;EACA,QAAA;EAEA,mBAAA;EACA,WAAA;EACA,WAAA;AAfR","sourcesContent":[".mainPowerContainer {\n    // position: relative;\n    // height: 100%;\n    // .actualPowerMediciones{\n    //     position: relative;\n    //     top:-25%;\n    //     left: 35%;\n\n    // }\n    .PowerDoughnutPositive {\n        position: relative;\n        right: 23%;\n        top: 29%;\n        canvas {\n            width: 80% !important;\n            height: 80% !important;\n        }\n    }\n    .PowerDoughnutNegative {\n        position: relative;\n        top: -47%;\n        left: 19%;\n        transform: scaleX(-1);\n        canvas {\n            width: 80% !important;\n            height: 80% !important;\n        }\n        // canvas{\n        //     height: 280px !important;\n        // }\n    }\n\n    .actualPowerMediciones {\n        position: relative;\n        top: -8%;\n        left: -8%;\n    }\n\n    .powerTitle {\n        position: relative;\n        top: -65%;\n    }\n}\n.powerGraphdivider {\n    .divider {\n        position: relative;\n        top: 35%;\n        // background: #ADADAD;\n        background: 'black';\n        height: 3px;\n        width: 40px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
