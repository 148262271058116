import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { GlobalContext } from 'context/GlobalContext'
import { dispositivos, instalaciones } from 'utils/constant'
import { IconWarning } from 'utils/icon-centrocontrol'
import { EmsCC } from 'views/EMS/EmsCC'
import { EmsScada } from 'views/EMS/EmsScada'
import LayOutBoard from 'views/LayOut/LayOutBoard'
import { SensorLineConfig } from 'views/Scada/MABT/SensorLine/SensorLineConfig'
import { ConfiguracionAlarms } from './Alarms/ConfiguracionAlarms'
import { InstallationsConfigScada } from './Installation/EditInstall/InstallationsConfigScada'
import { InstallationConfig } from './Installation/InstallationConfig'
import { ConfiguracionPlantaMabt } from './Mabt/ConfiguracionPlantaMabt'
import { PersonalizationConfig } from './Personalization/PersonalizationConfig'
import './Configuracion.scss'

export const ConfiguracionList = ({
    consumption,
    instalacioneSeleccionadas,
    dispositivo,
    scada,
    masterError,
    scadaSelf,
    activeTabCuadro,
    autoconsumo,
    lighting,
    distribution
}) => {
    const location = useLocation()
    const centroControl = location.pathname.split('/')[1] === 'admin'
    const [activeTab, setActiveTab] = useState(activeTabCuadro ? 4 : 0)
    const [action, setAction] = useState(true)
    const errors = masterError?.filter((master) => master?.error !== 0)
    const valid = dispositivo?.[0]?.id_tipo_dispositivo === dispositivos.ccmaster
    const { actualProject } = useContext(GlobalContext)
    const emsEnable = [instalaciones.consumption, instalaciones.selfConsumption]

    const toggle = (tab) => {
        setAction(!action)
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12} className='menu-configuracion edit-install-content-tabs'>
                <div
                    selected={activeTab === 0}
                    onClick={() => {
                        toggle(0)
                    }}
                >
                    <span className={activeTab === 0 ? 'list-item-icon active-button' : 'list-item-icon'}>Alarmas</span>
                </div>
                {!scada && (
                    <div
                        selected={activeTab === 1}
                        onClick={() => {
                            toggle(1)
                        }}
                    >
                        <span className={activeTab === 1 ? 'list-item-icon active-button' : 'list-item-icon'}>Personalización</span>
                    </div>
                )}
                {scada && dispositivo[0].id_tipo_dispositivo === dispositivos.ccmabt && actualProject?.permissions !== 'user' && (
                    <div
                        selected={activeTab === 2}
                        onClick={() => {
                            toggle(2)
                        }}
                    >
                        <span className={activeTab === 2 ? 'list-item-icon active-button' : 'list-item-icon'}>
                            Instalación
                            {masterError && masterError?.length > 0 && (
                                <div className='separator-left'>
                                    <HtmlTooltip title={<span>Revise su configuración.</span>}>
                                        <IconWarning width={20} height={20} />
                                    </HtmlTooltip>
                                </div>
                            )}
                        </span>
                    </div>
                )}
                {((!scada && actualProject?.permissions !== 'user') ||
                    (dispositivo[0].inst_type !== 3 && actualProject?.permissions !== 'user')) && (
                    <div
                        selected={activeTab === 3}
                        onClick={() => {
                            toggle(3)
                        }}
                    >
                        <span className={activeTab === 3 ? 'list-item-icon active-button' : 'list-item-icon'}>
                            Instalación
                            {errors && errors?.length > 0 && (
                                <div className='separator-left'>
                                    <HtmlTooltip title={<span>Revise su configuración.</span>}>
                                        <IconWarning width={20} height={20} />
                                    </HtmlTooltip>
                                </div>
                            )}
                        </span>
                    </div>
                )}
                {((!scada && emsEnable?.includes(actualProject?.category)) || ((consumption || scadaSelf) && valid)) && (
                    <div
                        selected={activeTab === 5}
                        onClick={() => {
                            toggle(5)
                        }}
                    >
                        <span className={activeTab === 5 ? 'list-item-icon active-button' : 'list-item-icon'}>Ems</span>
                    </div>
                )}
                {(consumption || lighting) && (
                    <div
                        selected={activeTab === 4}
                        onClick={() => {
                            toggle(4)
                        }}
                    >
                        <span className={activeTab === 4 ? 'list-item-icon active-button' : 'list-item-icon'}>Configurar cuadro</span>
                    </div>
                )}
                {distribution && (
                    <div
                        selected={activeTab === 6}
                        onClick={() => {
                            toggle(6)
                        }}
                    >
                        <span className={activeTab === 6 ? 'list-item-icon active-button' : 'list-item-icon'}>Sensores de Línea</span>
                    </div>
                )}
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <div className={`tab-item-wrapper ${activeTab === 0 ? 'active' : ''}`} index={0}>
                    {activeTab === 0 && (
                        <ConfiguracionAlarms instalacioneSeleccionadas={instalacioneSeleccionadas} dispositivos={dispositivo} />
                    )}
                </div>
                <div className={`tab-item-wrapper ${activeTab === 1 ? 'active' : ''}`} index={1}>
                    {activeTab === 1 && <PersonalizationConfig idProject={actualProject?.id_project} />}
                </div>
                <div className={`tab-item-wrapper ${activeTab === 2 ? 'active' : ''}`} index={2}>
                    {activeTab === 2 && <ConfiguracionPlantaMabt dispositivo={dispositivo} />}
                </div>
                <div className={`tab-item-wrapper ${activeTab === 3 ? 'active' : ''}`} index={3}>
                    {activeTab === 3 && !scada && (
                        <InstallationConfig
                            dispositivo={dispositivo}
                            scadaSelf={scadaSelf}
                            activeTab={activeTab}
                            instalacioneSeleccionadas={instalacioneSeleccionadas}
                            masterError={masterError}
                        />
                    )}
                    {activeTab === 3 && scada && <InstallationsConfigScada master={dispositivo} />}
                </div>
                <div className={`tab-item-wrapper ${activeTab === 4 ? 'active' : ''}`} index={4}>
                    {(consumption || lighting) && activeTab === 4 && <LayOutBoard dispositivo={dispositivo} />}
                </div>
                <div className={`tab-item-wrapper2 ${activeTab === 5 ? 'active' : ''}`} index={5}>
                    {(consumption || scadaSelf) && activeTab === 5 && (
                        <EmsScada devices={dispositivo} autoconsumo={autoconsumo} action={action} />
                    )}
                    {(actualProject.category === instalaciones.consumption || actualProject.category === instalaciones.selfConsumption) &&
                        centroControl &&
                        activeTab === 5 && (
                            <EmsCC dispositivo={dispositivo} inst_type={actualProject.category} instSelect={instalacioneSeleccionadas} />
                        )}
                </div>
                <div className={`tab-item-wrapper2 ${activeTab === 6 ? 'active' : ''}`} index={6}>
                    {distribution && activeTab === 6 && <SensorLineConfig dispositivo={dispositivo} />}
                </div>
            </Grid>
        </Grid>
    )
}
