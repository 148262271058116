import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import moment from 'moment'
import CalendarComponentV2 from 'components/Calendar/CalendarComponentV2'
import { Loader } from 'components/Loader/Loader'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { headersScada } from 'services/headers'
import { urls, dispositivos } from 'utils/constant'
import { OjoConsumo, IconDownloadFile } from 'utils/icon-centrocontrol'
import { compararFecha } from 'utils/Utils'
import { ProgressBarGeneral } from './General/GraphsGeneral/GeneralProgressBar'
import { TableResumeGeneral } from './General/GraphsGeneral/TableResumeGeneral'
import { GeneralGraphsSelfConsumption } from './GeneralGraphsSelfConsumption'
import { TableGeneralSelfConsumption } from './TableGeneralSelfConsumption'
import './styleSelfConsumption.scss'

export const ReportScadaSelfConsumption = ({ data, devices, start, setStart, end, setEnd, daily, setDaily }) => {
    const token = sessionStorage?.token
    const location = useLocation()
    const idCcMaster = location.pathname.split('/')[4]
    const [general, setGeneral] = useState()
    const [dataTable, setDataTable] = useState([])
    const [graphVisibility, setGraphVisibility] = useState(true)
    const [activeTabGraph, setActiveTabGraph] = useState(0)
    const [disabledGraphs, setDisabledGraphs] = useState(true)
    const [dataGraphs, setDataGraphs] = useState([])
    const [loadingGraphs, setLoadingGraph] = useState(true)
    const [loading, setLoading] = useState(false)

    const maxDate = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    const devicePlant = devices?.childrenDevices?.filter((dev) => dev.id_tipo_dispositivo === dispositivos.planta)
    const battery = devices?.childrenDevices?.filter((dev) => dev.id_tipo_dispositivo === dispositivos.bateria)

    const variableTableSelfConsumption = [
        { label: 'Fecha', key: 'datetime' },
        { label: 'Consumo (kWh)', key: 'eaconsdia' },
        { label: 'Energía Consumida Red (kWh)', key: 'consumida' },
        { label: 'Producción FV (kWh)', key: 'eagendia' },
        { label: 'Energía Vertida (kWh)', key: 'vertida' },
        { label: 'Ecológico (%)', key: `eco` }
    ]

    const fetchData = async (date) => {
        try {
            const res = await fetch(
                urls.scada +
                    `getActualDataPlant/${idCcMaster}?idCCMaster=${idCcMaster}&iniDate=${date?.start?.format('YYYY-MM-DD')}&endDate=${date?.end?.format('YYYY-MM-DD')}&groupDate=${date?.daily}`,
                { method: 'GET', headers: headersScada(token) }
            )
            if (res.status !== 204) {
                const json = await res.json()
                return setGeneral(json)
            } else {
                return []
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        } finally {
        }
    }

    const fetchDataHistorical = async (date) => {
        try {
            const res = await fetch(
                urls.scada +
                    `getHistoricalMain/?idCCMaster=${idCcMaster}&iniDate=${date?.start?.format('YYYY-MM-DD')}&endDate=${date?.end?.format('YYYY-MM-DD')}&groupDate=${date?.daily}`,
                { method: 'GET', headers: headersScada(token) }
            )
            if (res.status === 200) {
                const json = await res.json()
                return setDataTable(json)
            } else {
                return []
            }
        } catch (err) {
            console.error('ERROR. API Scada', err)
        } finally {
        }
    }

    const arrayBody = async (date) => {
        let bodyNew = {}
        if (battery?.length > 0) {
            bodyNew = {
                iniDate: date.start?.format('YYYY-MM-DD'),
                endDate: date.end?.format('YYYY-MM-DD'),
                iniTime: `00:00`,
                endTime: moment().format('YYYY-MM-DD') === date.end?.format('YYYY-MM-DD') ? moment().format('HH:mm') : `23:59`,
                interval: '5',
                axis: [
                    {
                        idDeviceType: 7,
                        fields: ['pact'],
                        numseries: [`${devicePlant?.[0]?.numserie}`],
                        plantType: 'produccion',
                        unit: 'kWh'
                    },
                    {
                        idDeviceType: 7,
                        fields: ['pact'],
                        numseries: [`${devicePlant?.[0]?.numserie}`],
                        plantType: 'consumo',
                        unit: 'kWh'
                    },
                    {
                        idDeviceType: 9,
                        fields: ['p', 'soc'],
                        numseries: battery.map((dv) => dv.numserie.toString()),
                        plantType: 'bateria',
                        unit: 'kWh'
                    }
                ]
            }
        } else {
            bodyNew = {
                iniDate: date.start?.format('YYYY-MM-DD'),
                endDate: date.end?.format('YYYY-MM-DD'),
                iniTime: `00:00`,
                endTime: moment().format('YYYY-MM-DD') === date.end?.format('YYYY-MM-DD') ? moment().format('HH:mm') : `23:59`,
                interval: '5',
                axis: [
                    {
                        idDeviceType: 7,
                        fields: ['pact'],
                        numseries: [`${devicePlant?.[0]?.numserie}`],
                        plantType: 'produccion',
                        unit: 'kWh'
                    },
                    {
                        idDeviceType: 7,
                        fields: ['pact'],
                        numseries: [`${devicePlant?.[0]?.numserie}`],
                        plantType: 'consumo',
                        unit: 'kWh'
                    }
                ]
            }
        }
        return bodyNew
    }

    const fetchGraphsGeneral = async (numserie, date) => {
        const bodyNew = await arrayBody(date)
        if (devicePlant && bodyNew) {
            try {
                const res = await fetch(urls.scada + `chart/getDataChartsCurves/${numserie}?idCCMaster=${idCcMaster}`, {
                    method: 'POST',
                    redirect: 'follow',
                    headers: headersScada(token),
                    body: JSON.stringify(bodyNew)
                })
                if (res.status === 200) {
                    const json = await res.json()
                    setDataGraphs([json])
                } else {
                    return []
                }
            } catch (err) {
                console.error('ERROR. API Scada', err)
            }
        }
    }

    const handleDate = async (ele) => {
        setLoadingGraph(true)
        setLoading(true)
        setDataGraphs([])
        const objDate = ele ?? { start: start, end: end, daily: daily ?? 'days' }
        const visible = compararFecha(objDate?.start, objDate?.end)
        setDisabledGraphs(visible)

        if (ele) {
            setStart(ele?.start)
            setEnd(ele?.end)
            setDaily(ele?.daily)
        }
        if (!visible) {
            setActiveTabGraph(1)
        }
        await fetchData(objDate)
        await fetchDataHistorical(objDate)
        if (visible && activeTabGraph === 0 && devicePlant) {
            await fetchGraphsGeneral(devicePlant?.[0]?.numserie, objDate)
        }
        setLoadingGraph(false)
        setLoading(false)
    }

    useEffect(() => {
        handleDate()
    }, [activeTabGraph])

    return (
        <Grid>
            {general && (
                <>
                    <ProgressBarGeneral data={data} general={general} />
                    <TableResumeGeneral general={general} loading={loading} />
                </>
            )}
            <div className='button-calendar-graph-general'>
                <div className='selected-graphs'>
                    <button
                        className={activeTabGraph === 0 ? 'button-general-active' : 'button-general'}
                        disabled={!disabledGraphs}
                        selected={activeTabGraph === 0}
                        onClick={() => setActiveTabGraph(0)}
                    >
                        Potencia (kW)
                    </button>
                    <button
                        className={activeTabGraph === 1 ? 'button-general-active' : 'button-general'}
                        selected={activeTabGraph === 1}
                        onClick={() => setActiveTabGraph(1)}
                    >
                        Energía (kWh)
                    </button>
                </div>
                <span className='filter-component-calendar'>
                    <CalendarComponentV2 maxDate={maxDate} handleDate={handleDate} select={true} start={start} end={end} />
                    <div className='ml-3 flex align-items-center'>
                        {dataTable?.length > 0 && (
                            <CSVLink
                                data={dataTable}
                                headers={variableTableSelfConsumption}
                                separator={';'}
                                style={{ color: 'white', marginTop: '3px' }}
                                filename={`${dataTable?.[0]?.nick}._${start ? start?.format('DD-MM-YYYY') : ' '}_${end ? end?.format('DD-MM-YYYY') : ' '}.csv`}
                            >
                                <HtmlTooltip title={<span>Descargar datos</span>}>
                                    <IconDownloadFile />
                                </HtmlTooltip>
                            </CSVLink>
                        )}
                    </div>
                </span>
            </div>
            {(loading || loadingGraphs) && <Loader />}
            {dataTable?.length > 0 && !loadingGraphs && (
                <div className='container-graphs-table-general-self'>
                    <div onClick={() => setGraphVisibility(!graphVisibility)}>
                        <OjoConsumo className='cursor-pointer' />
                    </div>
                    {graphVisibility && (
                        <GeneralGraphsSelfConsumption
                            daily={daily}
                            dataGraphs={dataGraphs}
                            loading={loadingGraphs}
                            data={dataTable}
                            devices={[devices]}
                            activeTab={activeTabGraph}
                            setActiveTab={setActiveTabGraph}
                        />
                    )}
                    <TableGeneralSelfConsumption data={dataTable} headers={variableTableSelfConsumption} start={start} />
                </div>
            )}
        </Grid>
    )
}
