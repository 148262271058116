// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-0 {
  position: relative;
  z-index: 0;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.leaflet-container a {
  padding: 0px !important;
}

.search-bar {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.search-bar input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.suggestions {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
  background: #fff;
  z-index: 1000;
}

.suggestions li {
  padding: 8px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}`, "",{"version":3,"sources":["webpack://./src/components/Map/styleMap.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AACA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;AAEJ;;AACA;EACI,YAAA;EACA,sBAAA;EACA,kBAAA;AAEJ;;AACA;EACI,gBAAA;EACA,SAAA;EACA,UAAA;EACA,sBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;AAEJ;;AACA;EACI,YAAA;EACA,eAAA;AAEJ;;AACA;EACI,yBAAA;AAEJ","sourcesContent":[".index-0 {\n    position: relative;\n    z-index: 0;\n}\n\n.leaflet-control-container .leaflet-routing-container-hide {\n    display: none;\n}\n\n.leaflet-container a {\n    padding: 0px !important;\n}\n.search-bar {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 8px;\n}\n\n.search-bar input {\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.suggestions {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n    border: 1px solid #ccc;\n    border-top: none;\n    max-height: 150px;\n    overflow-y: auto;\n    background: #fff;\n    z-index: 1000;\n}\n\n.suggestions li {\n    padding: 8px;\n    cursor: pointer;\n}\n\n.suggestions li:hover {\n    background-color: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
