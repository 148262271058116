import React from 'react'
import { DateGeneral } from './General/DateGeneral'
import { ResumeGeneral } from './General/ResumeGeneral'
import { ReportScadaSelfConsumption } from './ReportScadaSelfConsumption'

export const ScadaGeneralSelfConsumption = ({ data, devices, start, setStart, end, setEnd, daily, setDaily }) => {
    return (
        <>
            <div className='general-scada'>
                <DateGeneral master={devices} data={data} />
                <span className='resume-general'>
                    <ResumeGeneral data={data} />
                </span>
                <ReportScadaSelfConsumption
                    data={data}
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                    daily={daily}
                    setDaily={setDaily}
                    devices={devices}
                />
            </div>
        </>
    )
}
