import React, { useEffect, useState, useRef, useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { GlobalContext } from 'context/GlobalContext'
import { dispositivos, devicesCentroControlConsumption, instalaciones } from 'utils/constant'
import { TypeProject } from 'utils/MiddleWareProjects'
import { CcMabt } from 'views/Scada/MABT/CcMabt'
import { CcMWifi } from './CcMWifi'
import { TableDevices } from './TableDevices'

export const PrincipalResumeDevicesAllDevices = ({ data, masterPermission }) => {
    const { actualProject } = useContext(GlobalContext)
    const divRef = useRef(null)
    const location = useLocation()
    const [isVisible, setIsVisible] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [devices, setDevices] = useState([])
    const [state, setState] = useState([])
    const selfComp = data?.filter((dev) => [instalaciones.selfConsumption, instalaciones.selfConsumptionColective].includes(dev?.inst_type))
    const isSelfComp = [instalaciones.selfConsumption, instalaciones.selfConsumptionColective].includes(
        TypeProject(actualProject?.category)
    )
    const idProject = location.pathname.split('/')[3]
    const token = sessionStorage?.token

    const timeoutHandler = () => {
        if (isVisible) {
            if (state.length + 15 < devices.length) {
                setState(state.concat(devices.slice(state.length, state.length + 15)))
                setIsVisible(false)
            } else if (state.length <= devices.length) {
                setState(state.concat(devices.slice(state.length, devices.length)))
                setHasMore(false)
                setIsVisible(false)
            }
        }
    }

    const handleScroll = () => {
        const element = divRef?.current?.getBoundingClientRect()?.bottom - divRef?.current?.getBoundingClientRect()?.top - 30
        const currentPosition = document?.documentElement?.scrollTop + window?.innerHeight
        if (currentPosition >= element && hasMore) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        if (data && data?.length > 0) {
            let order = {}
            actualProject?.groups?.forEach((group) => {
                group?.devices?.forEach((dev) => {
                    order[dev?.numserie] = group?.nick + dev?.nick
                })
                group?.subgroups?.forEach((subGroup) => {
                    subGroup?.devices?.forEach((dev) => {
                        order[dev?.numserie] = group?.nick + subGroup?.nick + dev?.nick
                    })
                })
            })
            let dat = data?.sort((a, b) => {
                const nickA = order[a?.numserie]
                const nickB = order[b?.numserie]
                return nickA?.localeCompare(nickB)
            })
            if (data.length <= 25) {
                setState(dat)
                setHasMore(false)
            } else {
                setState(dat.slice(0, 25))
            }
            setDevices(dat)
        }
    }, [data])

    useEffect(() => {
        if (state?.length === 25) {
            handleScroll()
        }
    }, [state])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <div className='flex'>
            {selfComp?.length > 0 && isSelfComp && <TableDevices datos={data} masterPermission={masterPermission} />}
            {state && state?.length > 0 && data?.length > 0 && selfComp?.length <= 0 && !isSelfComp && (
                <InfiniteScroll dataLength={state?.length} next={timeoutHandler()} hasMore={hasMore} loader={!isVisible && <Loader />}>
                    <div className='flex'>
                        <Grid container className='mb-3 grid-table-instalations'>
                            {state !== undefined &&
                                state?.length !== 0 &&
                                state
                                    ?.filter((device) => device?.activo)
                                    ?.map((master) => {
                                        return (
                                            <React.Fragment key={master?.numserie + 'children' + master?.id_padre}>
                                                {master?.id_tipo_dispositivo === dispositivos.ccmabt && (
                                                    <CcMabt
                                                        mabt={master}
                                                        masterPermission={masterPermission}
                                                        idProject={idProject}
                                                        token={token}
                                                    />
                                                )}
                                                {devicesCentroControlConsumption?.includes(master?.id_tipo_dispositivo) && (
                                                    <CcMWifi
                                                        masterPermission={masterPermission}
                                                        master={master}
                                                        setDevices={setDevices}
                                                        idProject={idProject}
                                                    />
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                        </Grid>
                    </div>
                </InfiniteScroll>
            )}
            <div ref={divRef}></div>
        </div>
    )
}
