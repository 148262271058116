import React, { useEffect, useRef, useState } from 'react'
import { Chart } from 'chart.js/auto'
import { getDayOfWeek, getMonth } from 'utils/Utils'

const ScadaBarChart = ({ measuringData, className, style, viewMode }) => {
    const canvas = useRef(null)
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
        if (canvas.current && measuringData) {
            const dataToUse = viewMode === 'semanal' ? measuringData.measuringDays : measuringData.measuringMonths
            const labels = dataToUse.map((day, index) => (viewMode === 'semanal' ? getDayOfWeek(index) : getMonth(index)))
            const uploadData = dataToUse.map((day) =>
                viewMode === 'semanal' ? (day.uploadUsageBytes ? day.uploadUsageBytes / 1024 : 0) : (day.total_upload_usage_mb ?? 0)
            )
            const downloadData = dataToUse.map((day) =>
                viewMode === 'semanal' ? (day.downloadUsageBytes ? day.downloadUsageBytes / 1024 : 0) : (day.total_download_usage_mb ?? 0)
            )

            const chartData = {
                labels: labels.length ? labels : ['No hay datos'],
                datasets: [
                    {
                        label: 'Bajada',
                        backgroundColor: '#f8b133',
                        borderWidth: 1,
                        data: downloadData
                    },
                    {
                        label: 'Subida',
                        backgroundColor: '#ff7425',
                        borderWidth: 1,
                        data: uploadData
                    }
                ]
            }

            if (!chartInstance) {
                const newChartInstance = new Chart(canvas.current, {
                    type: 'bar',
                    data: chartData,
                    options: {
                        scales: {
                            x: { grid: { display: false } },
                            y: {
                                beginAtZero: true,
                                ticks: { callback: (value) => `${value} MB` }
                            }
                        },
                        plugins: {
                            legend: { display: true, position: 'bottom', labels: { padding: 10 } }
                        }
                    }
                })
                setChartInstance(newChartInstance)
            } else {
                chartInstance.data.labels = chartData.labels
                chartInstance.data.datasets[0].data = chartData.datasets[0].data
                chartInstance.data.datasets[1].data = chartData.datasets[1].data
                chartInstance.update()
            }
        }
    }, [measuringData, viewMode, chartInstance])

    return (
        <div className={className} style={style}>
            <canvas ref={canvas} />
        </div>
    )
}

export default ScadaBarChart
