import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import InputStyled from 'components/Input/InputStyled'
import { Loader } from 'components/Loader/Loader'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { headersScada } from 'services/headers'
import { dispositivos, urls } from 'utils/constant'
import { validatorNumber } from 'utils/ValidatorForm'
import { ModalConfigPotMaxEms } from './Modal/ModalConfigPotMaxEms'
import { TableEmsScada } from './TableEmsScada'

export const PotMaxEms = ({ configEms, master, meter, setConfigEms, action }) => {
    const [open, setOpen] = useState(false)
    const [devices, setDevices] = useState([])
    const [dataEdit, setDataEdit] = useState([])
    const [potMaxEnable, setPotMaxEnable] = useState(configEms?.potmax?.enable)
    const [validPotMax, setValidPotMax] = useState(false)
    const [potMax, setPotMax] = useState(configEms?.potmax?.max_power === null ? '' : configEms?.potmax?.max_power)
    const [disabled, setDisabled] = useState(true)
    const [disabledTable, setDisabledTable] = useState(true)
    const [loading, setLoading] = useState(false)
    const [edit, setEdit] = useState(false)
    const token = sessionStorage?.token
    const enableDevices = [dispositivos.ccmaster, dispositivos.enchufe]

    const fethGetEmsDevice = () => {
        setLoading(true)
        fetch(urls.scada + `emsDevices/${master?.numserie}?idCCMaster=${master?.numserie}`, { method: 'GET', headers: headersScada(token) })
            .then((res) => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    return []
                }
            })
            .then((data) => {
                if (data?.length > 0) {
                    setDevices(data?.filter((ele) => enableDevices?.includes(ele?.id_tipo_dispositivo)))
                }
            })
            .catch((error) => {
                console.error('Ha habido un error en API Scada', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handlePotMax = (valor) => {
        setPotMax(valor)
        setValidPotMax(validatorNumber(valor))
    }

    const compareOptions = () => {
        let change = true
        if (edit) {
            change = true
        } else if (
            configEms?.potmax?.enable !== potMaxEnable ||
            (String(configEms?.potmax?.max_power) !== String(potMax) && potMax !== '' && configEms?.potmax?.max_power !== null)
        ) {
            change = false
        } else if (!disabledTable) {
            change = false
        }
        setDisabled(change)
    }

    useEffect(() => {
        fethGetEmsDevice()
    }, [action])

    useEffect(() => {
        compareOptions()
    }, [potMaxEnable, dataEdit, potMax, edit, configEms, disabledTable])

    return (
        <Grid container className='pl-3 mb-4'>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-4 flex-center'>
                <SwitchStyled
                    id='potmaxenable'
                    className='switch-small'
                    checked={potMaxEnable}
                    onClick={() => setPotMaxEnable(!potMaxEnable)}
                />
                <label className='ml-2 mb-0' htmlFor='potmaxenable'>
                    {' '}
                    Potencia Máxima{' '}
                </label>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3 flex-center'>
                <span className='section-modal-edit-mp input-mp-modal'>
                    <h4 className='ml-0'>Potencia Máxima (W)</h4>
                    <InputStyled value={potMax} onChange={handlePotMax} error={validPotMax} />
                </span>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3 flex-center containter-table-drag-scada'>
                {loading && <Loader />}
                {!loading && devices?.length > 0 && (
                    <TableEmsScada
                        newArray={dataEdit}
                        setNewArray={setDataEdit}
                        modeEms={6}
                        devicesEms={devices}
                        edit={edit}
                        setEdit={setEdit}
                        setDisabledApply={setDisabledTable}
                    />
                )}
                {!loading && devices?.length <= 0 && (
                    <h4 align='center' className='mt-4'>
                        Ha habido un error al comunicarse con el máster
                    </h4>
                )}
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='button-apply-table flex justify-content-flex-end mt-3'>
                <ButtonStyled size='small' color='#fff' background='#FF8A1F' disabled={disabled} onClick={() => setOpen(true)}>
                    Aplicar
                </ButtonStyled>
            </Grid>
            {open && (
                <ModalConfigPotMaxEms
                    master={master}
                    open={open}
                    setOpen={setOpen}
                    configEms={configEms}
                    potMax={potMax}
                    potMaxEnable={potMaxEnable}
                    dataEdit={dataEdit}
                    meter={meter}
                    setConfigEms={setConfigEms}
                    setDevices={setDevices}
                    setPotMaxEnable={setPotMaxEnable}
                />
            )}
        </Grid>
    )
}
