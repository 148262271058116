import React, { useState, useEffect, useRef } from 'react'
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet'
import L from 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import 'leaflet/dist/leaflet.css'
import 'leaflet-routing-machine'
import IconMapIluminariaALLOn from 'assets/img/IconMapIluminariaALLOn.svg'
import { apiMapBox } from 'utils/constant'
import './styleMap.scss'

const icons = {
    master: new L.Icon({
        iconUrl: IconMapIluminariaALLOn,
        iconSize: [40, 60],
        iconAnchor: [20, 45],
        popupAnchor: [1, -25]
    }),
    street: new L.Icon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        iconSize: [20, 32],
        iconAnchor: [10, 32],
        popupAnchor: [1, -25]
    })
}

const SearchBar = ({ onSearch, query, setQuery }) => {
    const [suggestions, setSuggestions] = useState([])
    const provider = useRef(new OpenStreetMapProvider()).current

    const handleInputChange = async (e) => {
        const value = e.target.value
        setQuery(value)

        if (value?.trim()?.length > 2) {
            const results = await provider.search({ query: value })
            setSuggestions(results)
        } else {
            setSuggestions([])
        }
    }

    const handleSuggestionClick = (location) => {
        const { x, y, label } = location
        setQuery(label)
        setSuggestions([])
        onSearch(L.latLng(y, x))
    }

    return (
        <div className='search-bar'>
            <input type='text' value={query} onChange={handleInputChange} />
            {suggestions.length > 0 && (
                <ul className='suggestions'>
                    {suggestions.map((result, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(result)}>
                            {result.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

const MapDinamic = () => {
    const [points, setPoints] = useState([])
    const [routeControls, setRouteControls] = useState([])
    const [query, setQuery] = useState('')
    const mapRef = useRef()

    const handleSearch = (location) => {
        setPoints((prevPoints) => {
            const newPoints = [location, ...prevPoints?.slice(1)]
            return newPoints
        })
        const map = mapRef?.current
        if (map) {
            map.setView(location, 13)
        }
    }

    const MapClickHandler = () => {
        const map = useMap()
        useEffect(() => {
            mapRef.current = map
        }, [map])

        useMapEvents({
            click(e) {
                if (points.length === 0) {
                    const provider = new OpenStreetMapProvider()
                    provider.search({ query: `${e.latlng.lat}, ${e.latlng.lng}` }).then((results) => {
                        if (results && results.length > 0) {
                            setQuery(results[0].label)
                        }
                    })
                }
                setPoints((prevPoints) => [...prevPoints, e?.latlng])
            }
        })
        return null
    }

    const updateRoute = (updatedPoints) => {
        const map = mapRef?.current
        routeControls?.forEach((routeControl) => {
            routeControl?.remove()
        })
        const newRouteControls = []
        if (updatedPoints?.length > 1) {
            updatedPoints?.slice(1)?.forEach((point) => {
                const routeControl = L.Routing.control({
                    router: L.Routing.mapbox(apiMapBox, {
                        profile: 'mapbox/walking',
                        directions: {
                            alternatives: false,
                            avoid: []
                        }
                    }),
                    waypoints: [L.latLng(updatedPoints?.[0]?.lat, updatedPoints?.[0]?.lng), L.latLng(point?.lat, point?.lng)],
                    routeWhileDragging: true,
                    createMarker: () => null,
                    geocoder: null,
                    lineOptions: {
                        styles: [{ color: 'blue', weight: 4 }]
                    },
                    show: false
                })
                routeControl.addTo(map)
                newRouteControls.push(routeControl)
            })
        }

        setRouteControls(newRouteControls)
    }

    useEffect(() => {
        if (points?.length > 1) {
            updateRoute(points)
        }
    }, [points])

    const handleDragEnd = (index, newLatLng) => {
        setPoints((prevPoints) => {
            const updatedPoints = [...prevPoints]
            updatedPoints[index] = newLatLng
            if (updatedPoints?.length > 1) {
                routeControls?.forEach((routeControl, i) => {
                    if (i < updatedPoints?.length) {
                        if (routeControl) {
                            routeControl?.setWaypoints([updatedPoints?.[0], updatedPoints?.[i + 1]])
                        }
                    }
                })
            }
            return updatedPoints
        })
    }

    const handleClickMarker = (latlng) => {
        const pointIndex = points?.findIndex((ele) => ele.lat === latlng.lat && ele.lng === latlng.lng)
        if (pointIndex !== -1) {
            const newPoints = points?.filter((_, i) => i !== pointIndex)
            const newRouteControls = routeControls?.filter((_, i) => i !== pointIndex)
            setPoints(newPoints)
            setRouteControls(newRouteControls)
            updateRoute(newPoints)
            if (newPoints.length === 0) {
                setQuery('')
            }
            if (pointIndex === 0 && newPoints.length > 0) {
                const newFirstPoint = newPoints[0]
                const provider = new OpenStreetMapProvider()
                provider.search({ query: `${newFirstPoint.lat}, ${newFirstPoint.lng}` }).then((results) => {
                    if (results && results.length > 0) {
                        setQuery(results[0].label)
                    }
                })
            }
        }
    }

    return (
        <div>
            <SearchBar onSearch={handleSearch} query={query} setQuery={setQuery} />
            <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: '400px', width: '100%' }}>
                <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' attribution='&copy; OpenStreetMap contributors' />
                <MapClickHandler />
                {points.map((point, index) => (
                    <Marker
                        key={index}
                        position={point}
                        icon={index === 0 ? icons.master : icons.street}
                        draggable={true}
                        eventHandlers={{
                            dragend: (e) => {
                                const newLatLng = e.target.getLatLng()
                                handleDragEnd(index, newLatLng)
                            },
                            click: (e) => handleClickMarker(e.target.getLatLng())
                        }}
                    />
                ))}
            </MapContainer>
        </div>
    )
}

export default MapDinamic
