import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { ProjectsListNew } from 'views/Project/ProjectsListNew'

const Projects = () => {
    const token = sessionStorage?.token

    return (
        <div className='main-content-project flex flex-column'>
            <Routes>
                <Route path='inicio' element={!localStorage.getItem(token) ? <Navigate to='/auth/login' replace /> : <ProjectsListNew />} />
                <Route path='*' element={<Navigate to='/projects' replace />} />
            </Routes>
        </div>
    )
}

export default Projects
