import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { createToast } from 'reduxSlice/toastSlice'
import { apiCentroControl } from 'services/api'
import { EnerSim } from 'utils/icon-centrocontrol'
import { getStatus } from 'utils/Utils'
import { validatorInput } from 'utils/ValidatorForm'
import './modalSim.scss'

export const ModalEditInfoSim = ({ open, setOpen, simData }) => {
    const dispatch = useDispatch()
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [imei, setImei] = useState()
    const [name, setName] = useState()
    const [statusConnection, setStatusConnection] = useState()
    const [localPort1, setLocalPort1] = useState()
    const [localPort2, setLocalPort2] = useState()
    const [publicPort1, setPublicPort1] = useState()
    const [publicPort2, setPublicPort2] = useState()
    const [validName, setValidName] = useState(false)
    const [loading, setLoading] = useState(false)

    const updateSimData = async ({ serialNumber, name }) => {
        setLoading(true)
        try {
            const response = await apiCentroControl.patch('siminfo', {
                numserie_sim: serialNumber,
                installationName: name
            })

            if (response?.status === 200) {
                dispatch(createToast({ status: response?.status, message: 'Datos actualizados correctamente', reload: true }))
            }
        } catch (err) {
            console.error('ERROR. API Centro Control', err)
            dispatch(createToast({ status: 400, message: 'Error al actualizar los datos', reload: false }))
        } finally {
            setLoading(false)
            setOpen(false)
        }
    }

    const handleUpdateSimData = () => {
        updateSimData({
            serialNumber: simData?.numserie_sim,
            name: name
        })
    }

    const handleNameChange = (e) => {
        setName(e)
        setValidName(validatorInput(e))
    }

    const { colorClass, status, icon } = getStatus(statusConnection)

    useEffect(() => {
        if (simData) {
            setValidName(false)
            setName(simData?.installationName)
            setImei(simData?.numserie_sim ? simData?.numserie_sim : '-')
            setLocalPort1(simData?.localPort1 ? simData?.localPort1 : '-')
            setLocalPort2(simData?.localPort2 ? simData?.localPort2 : '-')
            setPublicPort1(simData?.publicPort1 ? simData?.publicPort1 : '-')
            setPublicPort2(simData?.publicPort2 ? simData?.publicPort2 : '-')
            setStatusConnection(simData?.status)
        }
    }, [simData, open])

    useEffect(() => {
        if (name && !validName && name !== simData?.installationName) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }
    }, [name])

    return (
        <>
            <Modal
                onClick={(e) => e.stopPropagation()}
                open={open}
                setOpen={setOpen}
                title={`Editar información`}
                loading={loading}
                iconTitle={<EnerSim />}
                functSave={handleUpdateSimData}
                disabled={isButtonDisabled}
            >
                <Grid container className='pl-5 pr-5 mt-2'>
                    <Grid className='pb-3' item xs={12}>
                        <small className='color-black'>Nombre:</small>
                        <InputStyled value={name} maxLength={100} onChange={handleNameChange} fullWidth={true} error={validName} />
                    </Grid>
                    <Grid className='pb-3' item xs={12}>
                        <small className='color-black'>IMEI:</small>
                        <InputStyled value={imei} fullWidth={true} disabled />
                    </Grid>
                    <Grid className='pb-3' item xs={12}>
                        <small className='color-black'>Estado:</small>
                        <div className='flex-center pt-2'>
                            {icon}
                            <p className={`${colorClass}  ml-1 status-text`}>{status}</p>
                        </div>
                    </Grid>
                    <Grid className='pb-3 pr-2' item xs={12} md={6}>
                        <small className='color-black'>Puerto local 1:</small>
                        <InputStyled value={localPort1} fullWidth={true} disabled />
                    </Grid>
                    <Grid className='pb-3' item xs={12} md={6}>
                        <small className='color-black'>Puerto local 2:</small>
                        <InputStyled value={localPort2} fullWidth={true} disabled />
                    </Grid>
                    <Grid className='pb-3 pr-2' item xs={12} md={6}>
                        <small className='color-black'>Puerto público 1:</small>
                        <InputStyled value={publicPort1} fullWidth={true} disabled />
                    </Grid>
                    <Grid className='pb-3' item xs={12} md={6}>
                        <small className='color-black'>Puerto público 2:</small>
                        <InputStyled value={publicPort2} fullWidth={true} disabled />
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
