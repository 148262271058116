// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#selfConsumptionColective .cards-info-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
#selfConsumptionColective .cards-info-container .card-info {
  background-color: #dfdfdf;
  border-radius: 10px;
  width: 100%;
  max-width: 240px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: -1px 8px 8px 1px rgba(199, 199, 199, 0.34);
}
#selfConsumptionColective .cards-info-container .card-info p {
  margin: 0;
  font-weight: 600;
}
#selfConsumptionColective .cards-info-container .card-info > div {
  width: 100%;
  text-align: center;
  padding: 4px 0;
}
#selfConsumptionColective .cards-info-container .card-info-header > p {
  font-size: 13px;
}
#selfConsumptionColective .cards-info-container .card-info-body {
  background-color: #787e87;
  color: #d4d6d9;
}
#selfConsumptionColective .cards-info-container .card-info-footer > p {
  font-size: 11px;
}
#selfConsumptionColective .cards-info-container .card-info-body, #selfConsumptionColective .cards-info-container .card-info-footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}`, "",{"version":3,"sources":["webpack://./src/views/Scada/SelfConsumptionColective/styleSelfConsumptionColective.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AAAR;AAEQ;EACI,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EAGA,sDAAA;AAAZ;AAEY;EACI,SAAA;EACA,gBAAA;AAAhB;AAGY;EACI,WAAA;EACA,kBAAA;EACA,cAAA;AADhB;AAIY;EACI,eAAA;AAFhB;AAKY;EACI,yBAAA;EACA,cAAA;AAHhB;AAMY;EACI,eAAA;AAJhB;AAOY;EAEI,aAAA;EACA,qCAAA;AANhB","sourcesContent":["#selfConsumptionColective {\n    .cards-info-container {\n        display: flex;\n        flex-direction: row;\n        gap: 1rem;\n\n        .card-info {\n            background-color: #dfdfdf;\n            border-radius: 10px;\n            width: 100%;\n            max-width: 240px;\n            display: flex;\n            flex-direction: column;\n            align-items: flex-start;\n            -webkit-box-shadow: -1px 8px 8px 1px rgba(199, 199, 199, 0.34);\n            -moz-box-shadow: -1px 8px 8px 1px rgba(199, 199, 199, 0.34);\n            box-shadow: -1px 8px 8px 1px rgba(199, 199, 199, 0.34);\n\n            p {\n                margin: 0;\n                font-weight: 600;\n            }\n\n            > div {\n                width: 100%;\n                text-align: center;\n                padding: 4px 0;\n            }\n\n            &-header > p {\n                font-size: 13px;\n            }\n\n            &-body {\n                background-color: #787e87;\n                color: #d4d6d9;\n            }\n\n            &-footer > p {\n                font-size: 11px;\n            }\n\n            &-body,\n            &-footer {\n                display: grid;\n                grid-template-columns: repeat(2, 1fr);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
